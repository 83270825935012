import { genericCallWithBody, genericGetWithParameters, genericGetWithParametersNoEncrypted } from './Networking';

export async function sendForgotPasswordEmailOnline(applicationName: string, userEmail: string){
    try {
        let params = {
            applicationName: applicationName,
            userEmail: userEmail,
        }

        let response = await genericGetWithParametersNoEncrypted('User/SendForgotPasswordEmailOnline',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

interface SavePasswordRequest {
    ContactID: number;
    OldPassword: string;
    NewPassword: string;
    InstallationID: string;
    UserName: string;
    ContactName: string;
}

export async function savePassword(model: SavePasswordRequest) {
    try {
        let method = 'POST';
        let apiName = 'User/SavePassword';
        let response = await genericCallWithBody(method, apiName, model);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function saveBeneficiaryUserPassword(savePasswordRequest: any) {
    try {
        let method = 'POST';
        let apiName = 'User/SaveBeneficiaryUserPassword';
        let response = await genericCallWithBody(method, apiName, savePasswordRequest);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getListOfChallengeQuestions(){
    try {
        let params = {
        }
        let response = await genericGetWithParameters('User/GetListOfChallengeQuestions',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getForgotPasswordQuestions(forgotPasswordKey:string){
    try {
        let params = {
            forgotPasswordKey: forgotPasswordKey,
        }

        let response = await genericGetWithParameters('User/GetForgotPasswordQuestions',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function resetPassword(model: any){
    var method = 'POST';
    var apiName = 'User/ResetPassword'

    let response = await genericCallWithBody(method, apiName, model);
    return response;
}


export async function saveChallengeAnswers(model: any){
    let response = await genericCallWithBody('POST','User/SaveChallengeAnswers',model);
    return response;
}

export async function getListOfActionsByUser(customerID: number | string) {
    try {
        let userID = await localStorage.getItem('UserID');
        let params = {
            'UserID': userID,
            'CustomerID': customerID,
        };
        let response = await genericGetWithParameters('UserActions/GetListOfActionsByUser',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const validateChallengeQuestionAnswers = async (model: any) =>{
    try {
        const response = await genericCallWithBody('POST', 'User/ValidateChallengeQuestionAnswers', model);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const getRandomChallengeQuestions = async () => {
    try {
        const userID = localStorage.getItem('UserID');
        const params = {
            'userID': userID,
        };
        const response = await genericGetWithParameters('User/GetRandomChallengeQuestions', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}
export async function saveEntityProperty(model: any){
    var method = 'POST';
    var apiName = 'EntityProperty/SaveByEntityId';
    let response = await genericCallWithBody(method,apiName,model);
    return response;
}

export async function createUser(model: any){
    try {
        console.log(model);
        let response = await genericCallWithBody('POST','User/CreateOnlineUserWithoutContact',model)
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getEmailUserSettings(userID: any) {
    try {
        let params = {
            UserID: userID
        };
        let response = await genericGetWithParameters('EmailTemplates/GetEmailUserSettings', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function saveEmailUserSettings(model: any) {
    try {
        console.log(model);
        debugger;
        let response = await genericCallWithBody('POST', 'EmailTemplates/SaveEmailUserSettings', model)
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const validateForgotPasswordAnswers = async (model: any) => {
    try {
        const response = await genericCallWithBody('POST', 'User/ValidateForgotPasswordAnswers', model);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function generateTokenCodeSecurity(model: any) {
    try {
        console.log(model);
        let response = await genericCallWithBody('POST', 'Customer/GenerateTokenCode', model)
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function validateTokenCodeSecurity(model: any) {
    try {
        console.log(model);
        let response = await genericCallWithBody('POST', 'Customer/ValidateTokenCode', model)
        return response;
    } catch (error) {
        console.error(error);
    }
}