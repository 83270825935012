import {
    genericCallWithBody, genericCallWithBodyAndResponseDecoded, genericDeleteWithParameters, genericGetWithParameters
} from './Networking';

export async function last10Transactions(customerID: any) {
    try {
        let params = {
            CustomerID: customerID,
            ByPass: true
        };
        let response = await genericGetWithParameters('Widget/Last10Transactions', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function topBeneficiaries(customerID: any) {
    try {
        let params = {
            CustomerID: customerID,
            ByPass: true
        };
        let response = await genericGetWithParameters('Widget/TopBeneficiaries', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getDashboard(personID: string) {
    try {
        let params = {
            PersonID: personID,
        }
        let response = await genericGetWithParameters('Widget/GetDashboard', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function saveDashboard(personID: string, map: any) {
    try {
        let request = {
            PersonID: personID,
            Dashboard: map,
        }
        let response = await genericCallWithBody('POST', 'Widget/SaveDashboard', request);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function last12MonthsTurnover(customerID: any) {
    try {
        let params = {
            CustomerID: customerID,
            ByPass: true
        };
        let response = await genericGetWithParameters('Widget/Last12MonthsTurnover', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function rateBoard(personID: any) {
    try {
        let params = {
            PersonID: personID,
            ByPass: true
        };
        let response = await genericGetWithParameters('Widget/RateBoard', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function saveRateBoard(buyCurrency: string, sellCurrency: string, personID: string) {
    try {
        let request = {
            BuyCurrency: buyCurrency,
            SellCurrency: sellCurrency,
            PersonID: personID
        }
        let response = await genericCallWithBodyAndResponseDecoded('POST', 'Widget/SaveRateBoard', request);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function deleteARateOnBoard(buyCurrency: string, sellCurrency: string, personID: string) {
    //DELETE api/Widget/DeleteRateBoard?BuyCurrency={BuyCurrency}&SellCurrency={SellCurrency}&PersonID={PersonID}
    try {
        let params = {
            BuyCurrency: buyCurrency,
            SellCurrency: sellCurrency,
            PersonID: personID
        }
        let response = await genericDeleteWithParameters('Widget/DeleteRateBoard', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getAvailableWidgets(personID: string) {
    try {
        let params = {
            PersonID: personID,
            Channel: 'Online'
        };
        let response = await genericGetWithParameters('Widget/GetAvailableWidgets', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}