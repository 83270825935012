import Modal from "antd/lib/modal/Modal";
import React, { FunctionComponent, useContext, useEffect, useState } from "react";
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import { validateSession } from '../../api/Networking';
import { getAll } from "../../api/NetworkingCurrencyPairs";
import { getParametersByName } from "../../api/NetworkingGlobalSystemParameters";
import { onlineLogin } from "../../api/NetworkingSecurity";
import { getListOfActionsByUser } from "../../api/NetworkingUsers";
import AppContext from "../../App.context";
import { formatValidInputClass, validInputClass } from "../../helpers/FormatHelper";
import { getCompanyLogo, getPreRegURL } from "../../helpers/PackageJsonHelper";
import { getResource } from "../../helpers/ResourcesHelper";
// import logo from '../../images/logo_generic.png';
//import logo from '../../images/Logo_Premier.png';
import Button from "../inputs/Button";
import ValidateQuestions from '../myAccount/myCredentials/ValidateQuestions';
import Footer from "../workspace/footer/Footer";
import AdditionalValidation from "./AdditionalValidation";
import SetQuestions from "./SetQuestions";

const logo = getCompanyLogo();

interface LoginScreenProps {
  onFinishLogin: Function;
}

const LoginScreen: FunctionComponent<LoginScreenProps> = ({ onFinishLogin }) => {
  const { showModal, showAfexModal, showForgotPasswordModal } = useContext(AppContext);
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [inputType, setInputType] = useState('password');
  const [loading, setLoading] = useState(false);
  const [showChallengeQuestions, setShowChallengeQuestions] = useState(false);
  const [showSetQuestions, setShowSetQuestions] = useState(false);
  const [showAdditionalSecurity, setShowAdditionalSecurity] = useState(false);
  const [showAFEX, setShowAFEX] = useState(false);
  const [validations, setValidations] = useState({
    userName: true,
    password: true
  });

  //validate if exists session
  useEffect(() => {
    const checkSession = async () => {
      if (validateFields(null)) {
        if (localStorage.getItem('SessionID')) {
          setLoading(true);
          const json = await validateSession();
          if (json) {
            if (json.httpStatusCode === 200) {
              onFinishLogin();
            } else {
              localStorage.clear();
              setLoading(false);
            }
          } else {
            localStorage.clear();
            setLoading(false);
          }
        }
      }
    }
    checkSession();
  }, [onFinishLogin]);

  const validateFields = (sessionFail: any) => {
    let value = (userName !== '' && password !== '');
    if (sessionFail != null) {
      if (sessionFail) {
        setValidations({
          userName: false,
          password: false,
        });
      } else {
        setValidations({
          userName: validInputClass(userName),
          password: validInputClass(password),
        });
      }
    }
    return value;
  }
  const onLoginClick = () => {
    if (validateFields(false)) {
      onlineLogin({ UserName: userName, Password: password, InstallationID: 'Nexolink' }).then((loginResponse) => {
        if (loginResponse) {
          if (loginResponse.httpStatusCode === 200) {
            if (loginResponse.ErrorMessage === '') {
              if (loginResponse.SessionID) {
                //localStorage.clear();
                localStorage.clear();
                localStorage.setItem('SessionID', loginResponse.SessionID);
                localStorage.setItem('UserID', loginResponse.UserId);

                var rights = {};
                getListOfActionsByUser(loginResponse.OnlineUserSession.CustomerID).then((actionsResponse) => {


                  getParametersByName('AllowSameCurrencyForwards,SystemDate,CutoffTime,DefaultBuyCurrencyID,DefaultSellCurrencyID,WaitForManualRate,MaximumOpenForwardWindow').then((parametersResponse) => {

                    localStorage.clear();
                    localStorage.setItem('SessionID', loginResponse.SessionID);
                    getAll().then(
                      (json: any) => {
                        localStorage.setItem('CurrencyPairs', JSON.stringify(json.currencyPairs));
                      }
                    );
                    localStorage.setItem('ContactID', loginResponse.OnlineUserSession.ContactID);
                    localStorage.setItem('CustomerPersonID', loginResponse.OnlineUserSession.CustomerPersonID);
                    localStorage.setItem('OnlineLanguage', loginResponse.OnlineUserSession.OnlineLanguage);
                    localStorage.setItem('UserID', loginResponse.UserId);
                    localStorage.setItem('UserSession', JSON.stringify(loginResponse.OnlineUserSession));
                    localStorage.setItem('AFEXSession', JSON.stringify(loginResponse.Afex));
                    //showModal('afex', 'afextest');
                    if (actionsResponse && actionsResponse.rights) {
                      rights = actionsResponse.rights;
                    }

                    /* if (
                      loginResponse.Afex.AfexApiKey &&
                      loginResponse.Afex.AfexApiKey !== '' &&
                      (loginResponse.OnlineUserSession.BeneficiaryID === undefined || loginResponse.OnlineUserSession.BeneficiaryID === 0)
                    ) { */
                    if (loginResponse.OnlineUserSession.OnlineInterface != null && loginResponse.OnlineUserSession.OnlineInterface.includes('AFEX - Pass Thru')) {
                      localStorage.setItem('OnlineRights', JSON.stringify(rights));
                      if (parametersResponse && parametersResponse.parameters) {
                        for (var i = 0; i < parametersResponse.parameters.length; i++) {
                          localStorage.setItem(parametersResponse.parameters[i].Name, parametersResponse.parameters[i].Value);
                        }
                      }
                      if (loginResponse.OnlineUserSession.RedirectToRegistration) {
                        openRegistrationForm(loginResponse.OnlineUserSession)
                      } else if (loginResponse.OnlineUserSession.ShowChallengeQuestions) {
                        //----------OPEN QUESTIONS---------------
                        setShowChallengeQuestions(true);
                        setShowAFEX(true);
                      } else {
                        showAfexModal();
                      }
                    } else {
                      localStorage.setItem('OnlineRights', JSON.stringify(rights));
                      if (parametersResponse && parametersResponse.parameters) {
                        for (var j = 0; j < parametersResponse.parameters.length; j++) {
                          localStorage.setItem(parametersResponse.parameters[j].Name, parametersResponse.parameters[j].Value);
                        }
                      }
                      if (loginResponse.OnlineUserSession.NeedAnswers) {
                        setShowSetQuestions(true);
                      } else
                        if (loginResponse.OnlineUserSession.AdditionalValidation) {
                          setShowAdditionalSecurity(true)
                        }
                        else {
                          if (loginResponse.OnlineUserSession.ShowChallengeQuestions) {
                        //----------OPEN QUESTIONS---------------
                        setShowChallengeQuestions(true);
                      } else if (loginResponse.OnlineUserSession.RedirectToRegistration) {
                        openRegistrationForm(loginResponse.OnlineUserSession)
                      } else {
                        onFinishLogin();
                      }
                    }
                    }
                  });
                });
              }
            } else {
              showModal(getResource('Js_MessageError_TitleError'), loginResponse.ErrorMessage);
            }
          } else {
            if (loginResponse.Message.includes('Incorrect')) {
              validateFields(true)
            }
            showModal(getResource('Js_MessageError_TitleError'), loginResponse.Message);
          }
        }
      })
    } else {
      showModal(getResource('Js_MessageError_TitleError'), getResource('Incorrect user or password'));
    }
  }
  const openRegistrationForm = (userSession: any) => {
    window.open(getPreRegURL() + '?customerId=' + userSession.CustomerID + "&origin=online", "_self")
  }
  const handleKeyPress = (e: any) => {
    if (e.key === 'Enter') {
      onLoginClick();
    }
  }
  const handleCancelQuestions = (e: any) => {
    setShowChallengeQuestions(false);
  }

  const handleCancelSetQuestions = () => {
    setShowSetQuestions(false);
  }

  const handleCancelAdditionalSecurity = () => {
    setShowAdditionalSecurity(false);
  }

  const answersValidated = () => {
    setShowChallengeQuestions(false);
    if (showAFEX) {
      showAfexModal();
    } else {
      onFinishLogin();
    }
  }

  const onForgotPasswordClick = () => {
    showForgotPasswordModal();
  }

  const continueToLogin = () => {
    onFinishLogin();
  }

  const onTokenValidated = () => {
    let userSession = JSON.parse(localStorage.getItem('UserSession')!);
    if (userSession.ShowChallengeQuestions) {
      //----------OPEN QUESTIONS---------------
      setShowChallengeQuestions(true);
    } else if (userSession.RedirectToRegistration) {
      openRegistrationForm(userSession)
    } else {
      onFinishLogin();
    }
  }

  return (
    <React.Fragment>
      {!loading && (
        <div className="col prime-container login-container">
          <div className="row prime-container">
            <div className="col-md-4 col-xs-12"></div>
            <div className="row col-md-4 col-xs-12 middle-xs">
              <div className="row col-xs-12 center-xs">
                <div className="col-xs-8">
                  <div className="row">
                    <img className="col-xs-12 login-image" src={logo} alt="" />
                  </div>
                  <div className="row">
                    <input
                      className={formatValidInputClass(validations.userName)}
                      id="login-email"
                      type="email"
                      onChange={(event) => {
                        setUserName(event.target.value);
                        setValidations({ ...validations, userName: true });
                      }}
                      value={userName}
                      placeholder={getResource('Login.Email')}
                      onKeyPress={handleKeyPress}
                    />
                  </div>

                  <div className="row">
                    <i className="label-eye-wrapper">
                      {inputType === "password"
                        ?
                        <AiFillEyeInvisible onClick={() => setInputType('text')} />
                        :
                        <AiFillEye onClick={() => setInputType('password')} />
                      }
                    </i>
                    <input
                      className={formatValidInputClass(validations.password)}
                      id="login-password"
                      type={inputType}
                      onChange={(event) => {
                        setPassword(event.target.value);
                        setValidations({ ...validations, password: true });
                      }}
                      value={password}
                      placeholder={getResource('Login.Password')}
                      onKeyPress={handleKeyPress}
                    />
                  </div>
                  <div>
                    <a id="login-forgot" className="link" onClick={onForgotPasswordClick}>
                      {getResource('Button.ForgotPassword')}
                    </a>
                  </div>
                  <div className="row center-xs">
                    <Button id="login-button" type="active" onClick={onLoginClick}>
                      {getResource('Button.Login')}
                    </Button>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <Footer from={'login'} />
        </div>
      )}
      <Modal
        title={<h3 className="component-title">{'Validate Challenge Questions'}</h3>}
        centered
        visible={showChallengeQuestions}
        footer={null}
        onCancel={handleCancelQuestions}
        maskClosable={false}
        width={'50%'}
        zIndex={1000}
        closable={false}
      >
        <div className="row">
          <ValidateQuestions userSession={JSON.parse(localStorage.getItem('UserSession')!)} answersValidated={answersValidated} />
        </div>
      </Modal>
      <Modal
        title={<h3 className="component-title">{'Validate Challenge Questions'}</h3>}
        centered
        visible={showSetQuestions}
        footer={null}
        onCancel={handleCancelSetQuestions}
        maskClosable={false}
        width={'50%'}
        zIndex={1000}
        closable={false}
      >
        <div className="row">
          <SetQuestions questionsSaved={handleCancelSetQuestions} continueToLogin={continueToLogin} />
        </div>
      </Modal>

      <Modal
        title={<h3 className="component-title">{getResource('AdditionalSecurityValidation')}</h3>}
        centered
        visible={showAdditionalSecurity}
        footer={null}
        onCancel={handleCancelAdditionalSecurity}
        maskClosable={false}
        width={'50%'}
        zIndex={1000}
        closable={true}
      >
        <div className="row">
          <AdditionalValidation userSession={JSON.parse(localStorage.getItem('UserSession')!)} onClose={handleCancelAdditionalSecurity} tokenValidated={onTokenValidated} />
        </div>
      </Modal>

    </React.Fragment>
  );
}

export default LoginScreen;