import { ResponsivePie } from '@nivo/pie';
import { Breadcrumb, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { getAllCountries } from '../../../api/NetworkingCountries';
import { topBeneficiaries } from '../../../api/NetworkingWidget';
import BeneficiaryWizard from '../../beneficiaries/BeneficiaryWizard';

const TopBeneficiaries = (props: any) => {
    const [listBeneficiaries, setListBeneficiaries] = useState([] as any);
    const [beneficiarySelected, setBeneficiarySelected] = useState({} as any);
    const [showBeneficiaryDetails, setShowBeneficiaryDetails] = useState(false);
    const [countriesList, setCountriesList] = useState([]);

    useEffect(() => {
        const customerID = JSON.parse(localStorage.getItem('UserSession')!)['CustomerID'];
        topBeneficiaries(customerID).then(
            (response: any) => {
                if (response !== undefined) {
                    if (response.TopBeneficiaries !== undefined) {
                        console.log(response);
                        console.log("topBeneficiaries: " + JSON.stringify(response));
                        response.TopBeneficiaries.forEach((element: any) => {
                            element.id = element['Beneficiary Name'] + ' ' + element['Sell Currency'];
                            element.label = element['Beneficiary Name'] + ' ' + element['Sell Currency'];
                            element.value = element['Total Beneficiary'];
                        });
                        setListBeneficiaries(response.TopBeneficiaries);
                    }
                }
            }
        );
    }, [])

    const onClickABeneficiary = (beneficiary: any) => {
        //console.log("onclickADeal: " + JSON.stringify(deal));
        if (beneficiary !== undefined) {
            let authorizedCountries = [];
            getAllCountries().then((response: any) => {
                if (typeof response !== undefined && response.httpStatusCode === 200) {
                    let countries = response.countries;
                    countries.forEach((x: any) => {
                        x.value = x.Country;
                        x.name = x['Country Name'];
                    })
                    authorizedCountries = countries.filter((x: any) => x.Status === 'Authorized to deal');
                    setShowBeneficiaryDetails(true);
                    setBeneficiarySelected(beneficiary.data);
                    setCountriesList(authorizedCountries);
                }
            });
        }
    }

    const closeDialogBeneficiaryDetail = () => {
        setShowBeneficiaryDetails(false);
        setBeneficiarySelected({} as any);
    }

    return (
        <div
            style={{
                width: props.width,
                height: props.height,
                overflow: 'auto',
                //backgroundColor: 'yellow',
                flexDirection: 'column',
                padding: '10px 5px',
                //
                display: 'flex',
                alignItems: 'stretch',
                margin: '0 auto',
                //borderRadius: '10px',
                //border: '2px solid #E7E7E7',
            }}
        >
            <div className="breadcrumb-container">
                <Breadcrumb separator="">
                    <Breadcrumb.Item >{'Top Beneficiaries'}</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            {/*<ul 
                style={{
                    //backgroundColor : 'red', 
                    listStyleType   : 'none',
                    margin          : 0,
                    padding         : 2
                }}
            >
              {listBeneficiaries.map(
                (item,index) =>
                    <li 
                        className   = "li-widget"
                        style       = {{
                            //backgroundColor: 'ButtonShadow',
                            display: 'flex',
                            flexDirection: 'row',
                            border: '1px solid #E7E7E7',
                            borderRadius: '10px',
                        }}
                        onClick = {onClickABeneficiary.bind(this, item)}
                    >
                        <div 
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                //backgroundColor: 'thistle',
                                width: '50%',
                                //textAlign: 'center',
                                //margin: 'auto'
                            }}
                        >
                            <span 
                                style={{
                                    paddingLeft: 5, 
                                    paddingTop: 5, 
                                    paddingBottom:5,
                                    fontWeight:'bold',
                                    whiteSpace:'nowrap',
                                    textOverflow: 'ellipsis',
                                    overflow:'hidden',
                                }}
                            >{item['Beneficiary Name']}</span>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '40%',
                                margin: 'auto',
                            }}
                        >
                            <span
                                style={{
                                    whiteSpace:'nowrap',
                                    textOverflow: 'ellipsis',
                                    overflow:'hidden',
                                }}
                            >
                                Total: {formatToCurrency(item['Total Beneficiary'])} {item['Sell Currency']}
                            </span>
                            
                        </div>
                        <div style={{
                            fontSize:15,
                            fontWeight: 'bold',
                            textAlign:'center',
                            width: '10%',
                            //backgroundColor: 'wheat',
                            margin: 'auto',
                        }}>
                            <CurrencyFlag 
                                currency={item['Sell Currency']} 
                                width={18} 
                                style={{margin: 'auto'}} 
                            />
                        </div>
                    </li>
                )
              }
            </ul>*/}

            <div
                style={{
                    width: '100%',
                    height: 234,
                    margin: 0,
                    overflow: 'hidden',
                    //backgroundColor : 'yellow'
                }}
            >
                <ResponsivePie
                    data={listBeneficiaries}
                    onClick={(item: any) => { onClickABeneficiary(item) }}
                    margin={{ top: 40, right: 20, bottom: 20, left: 20 }}
                    startAngle={360}
                    endAngle={-360}
                    sortByValue={true}
                    innerRadius={0.45}
                    padAngle={2}
                    colors={{ scheme: 'nivo' }}
                    borderWidth={1}
                    borderColor={{ from: 'color', modifiers: [['darker', 0]] }}
                    enableArcLinkLabels={true}
                    //enableRadialLabels={true}
                    tooltip={(element: any) => {
                        console.log(element)
                        return (
                            <div
                                style={{
                                    color: 'white',
                                    fontSize: 12,
                                }}
                            >
                                {element.id} {element.value}
                            </div>
                        )
                    }}
                    theme={{
                        tooltip: {
                            container: {
                                background: '#333',
                            },
                        },
                    }}
                    defs={[
                        {
                            id: 'dots',
                            type: 'patternDots',
                            background: 'inherit',
                            color: 'rgba(255, 255, 255, 0.3)',
                            size: 4,
                            padding: 1,
                            stagger: true
                        },
                        {
                            id: 'lines',
                            type: 'patternLines',
                            background: 'inherit',
                            color: 'rgba(255, 255, 255, 0.3)',
                            rotation: -45,
                            lineWidth: 6,
                            spacing: 10
                        }
                    ]}
                    fill={[
                        {
                            match: {
                                id: 'ruby'
                            },
                            id: 'dots'
                        },
                        {
                            match: {
                                id: 'c'
                            },
                            id: 'dots'
                        },
                        {
                            match: {
                                id: 'go'
                            },
                            id: 'dots'
                        },
                        {
                            match: {
                                id: 'python'
                            },
                            id: 'dots'
                        },
                        {
                            match: {
                                id: 'scala'
                            },
                            id: 'lines'
                        },
                        {
                            match: {
                                id: 'lisp'
                            },
                            id: 'lines'
                        },
                        {
                            match: {
                                id: 'elixir'
                            },
                            id: 'lines'
                        },
                        {
                            match: {
                                id: 'javascript'
                            },
                            id: 'lines'
                        }
                    ]}
                    legends={[]}
                />
            </div>

            {/*<Dialog 
                open        = {showBeneficiaryDetails} 
                onClose     = {closeDialogBeneficiaryDetail}
                fullWidth   = {true}
                maxWidth    = {"md"}
            >
                <DialogContent dividers style={{ overflowY: 'visible' }}>

                </DialogContent>
            </Dialog>*/}

            {showBeneficiaryDetails ? <Modal
                title={
                    <div className="breadcrumb-container">
                        <Breadcrumb separator="">
                            <Breadcrumb.Item >{'Deal Details'}</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                }
                centered
                visible={showBeneficiaryDetails}
                footer={null}
                onCancel={closeDialogBeneficiaryDetail}
                maskClosable={false}
                width={'50%'}
                zIndex={1020}
                closable={false}
            >
                <BeneficiaryWizard
                    beneficiaryID={beneficiarySelected['Beneficiary ID']}
                    customerID={JSON.parse(localStorage.getItem('UserSession')!).CustomerID}
                    onCancelClick={closeDialogBeneficiaryDetail}
                    readOnly={true} />
            </Modal> : null}

        </div>
    );
}
export default TopBeneficiaries;