import * as mime from 'mime-types';
import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import { getConfirmation, getDealByID, getDrawdownsByForwardID, getFullDealHeader } from '../../../api/NetworkingDeals';
import AppContext from '../../../App.context';
import { formatToCurrency } from '../../../helpers/FormatHelper';
import { getResource } from '../../../helpers/ResourcesHelper';
import Button from '../../inputs/Button';
import AddEditOrderTemplate from '../../orders/ordersTemplate/AddEditOrderTemplate';

interface SummaryProps {
  dealHeaderID: number;
  useNewTradeButton?: boolean;
  useConfirmationButton?: boolean;
  onClickNewTrade?: Function;
  useDepositRequired?: boolean;
  useSaveTemplateButton?: boolean;
  saveTemplateModalProps?: SaveTemplateModalProps;
  onClickExit?: Function;
  showExitButton?: boolean
}

interface SaveTemplateModalProps {
  ButtonLabel?: string;
  Modaltitle?: string;
}

const Summary: FunctionComponent<SummaryProps> = (props) => {
  const { showModal, showLoadingModal, hideLoadingModal } = useContext(AppContext);
  const [deal, setDeal] = useState({} as any);
  const [anotherDeal, setAnotherDeal] = useState({} as any);
  const [dealIncomingFunds, setDealIncomingFunds] = useState([] as any);
  const [dealDrawdowns, setDealDrawdowns] = useState<any[]>([]);
  // ---  Modal Add Template -------------------------------------------------------------------r2d2--
  const [showAddLikeTemplateModal, setShowAddLikeTemplateModal] = useState(false);
  //--------------------------------------------------------------------------------------------------

  useEffect(() => {
    if (props.dealHeaderID !== undefined && props.dealHeaderID > 0) {
      getDealByID(props.dealHeaderID).then((dealResponse: any) => {
        console.log(dealResponse);
        if (dealResponse !== undefined && dealResponse.deals != null && dealResponse.deals.length > 0) {
          setDeal(dealResponse.deals[0]);
          if (dealResponse.deals[0]['Deal Type'] === 4) {
            getDrawdownsByForwardID(props.dealHeaderID).then((drawdownsResponse: any) => {
              console.log(drawdownsResponse);
              if (drawdownsResponse.totalCount > 0) {
                setDealDrawdowns(drawdownsResponse.deals);
              }
            });
          }
        }
      });
      getFullDealHeader(props.dealHeaderID).then((dealResponse: any) => {
        setAnotherDeal(dealResponse);
      });
    }
  }, [props.dealHeaderID]);

  let outgoingFunds: any[] = [];

  if (deal['Deal Type'] === 1) {
    if (deal.BeneficiaryPayments) {
      outgoingFunds = JSON.parse(deal.BeneficiaryPayments).map((outgoingFund: any) => {
        return (
          <React.Fragment>
            <div className="row slide-r-l">
              <div className="col-xs-2">{outgoingFund.BeneficiaryName}</div>
              <div className="col-xs-1">{outgoingFund.CountryName}</div>
              <div className="col-xs-2">
                {formatToCurrency(outgoingFund.Amount, outgoingFund.CurrencyID)} {outgoingFund.CurrencyID}
              </div>
              <div className="col-xs-2">{outgoingFund.Instrument}</div>
              <div className="col-xs-4">{outgoingFund.BankName} - {outgoingFund.AccountNo}</div>
              <div className="col-xs-1">{outgoingFund.Status}</div>
            </div>
          </React.Fragment>
        );
      });
    } else {
      if (anotherDeal.OutgoingFunds) {
        outgoingFunds = anotherDeal.OutgoingFunds.map((outgoingFund: any) => {
          return (
            <React.Fragment>
              <div className="row slide-r-l">
                <div className="col-xs-2">{outgoingFund.Name}</div>
                <div className="col-xs-1">{outgoingFund.CountryName}</div>
                <div className="col-xs-2">
                  {formatToCurrency(outgoingFund.PaymentAmount, outgoingFund.SellCurrency)} {anotherDeal.SellCurrency}
                </div>
                <div className="col-xs-2">{outgoingFund.PaymentInstrument}</div>
                <div className="col-xs-4"></div>
                <div className="col-xs-1">{outgoingFund.Status}</div>
              </div>
            </React.Fragment>
          );
        });
      }
    }
  } else if (deal['Deal Type'] === 4) {
    if (dealDrawdowns.length > 0) {
      outgoingFunds = dealDrawdowns.map((outgoingFund: any) => {
        return (
          <React.Fragment>
            <div className="row slide-r-l middle-xs">
              <div className="col-xs-1°5">{outgoingFund['Deal Header ID']}</div>
              <div className="col-xs-1°5">
                {formatToCurrency(outgoingFund['Sell Amount'], outgoingFund['Sell Currency'])} {outgoingFund['Sell Currency']}
              </div>
              <div className="col-xs-1°5">
                {formatToCurrency(outgoingFund['Buy Amount'], outgoingFund['Buy Currency'])} {outgoingFund['Buy Currency']}
              </div>
              <div className="col-xs-1°5">{outgoingFund['Fee']} {outgoingFund['Buy Currency']}</div>
              <div className="col-xs-1°5">{outgoingFund['Value Date']}</div>
              <div className="col-xs-1°5">{outgoingFund['Status']}</div>
              <div className="col-xs-3 row end-xs" style={{ margin: 0, padding: 0 }}>
                {props.useConfirmationButton && (
                  <div className="col-md-12 col-xs-12">
                    <Button type="secondary" disabled={deal.Status !== 'Accepted'} onClick={() => onDownloadConfirmation(outgoingFund['Deal Header ID'])}>
                      {getResource('DownloadConfirmation')}
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </React.Fragment>
        );
      });
    } else if (deal.BeneficiaryPayments) {
      outgoingFunds = JSON.parse(deal.BeneficiaryPayments).map((outgoingFund: any) => {
        return (
          <React.Fragment>
            <div className="row slide-r-l">
              <div className="col-xs-2">{outgoingFund.BeneficiaryName}</div>
              <div className="col-xs-1">{outgoingFund.CountryName}</div>
              <div className="col-xs-2">
                {formatToCurrency(outgoingFund.Amount, outgoingFund.CurrencyID)} {outgoingFund.CurrencyID}
              </div>
              <div className="col-xs-2">{outgoingFund.Instrument}</div>
              <div className="col-xs-4">{outgoingFund.BankName} - {outgoingFund.AccountNo}</div>
              <div className="col-xs-1">{outgoingFund.Status}</div>
            </div>
          </React.Fragment>
        );
      });
    } else {
      if (anotherDeal.OutgoingFunds) {
        outgoingFunds = anotherDeal.OutgoingFunds.map((outgoingFund: any) => {
          return (
            <React.Fragment>
              <div className="row slide-r-l">
                <div className="col-xs-2">{outgoingFund.Name}</div>
                <div className="col-xs-1">{outgoingFund.CountryName}</div>
                <div className="col-xs-2">
                  {formatToCurrency(outgoingFund.PaymentAmount, outgoingFund.SellCurrency)} {anotherDeal.SellCurrency}
                </div>
                <div className="col-xs-2">{outgoingFund.PaymentInstrument}</div>
                <div className="col-xs-4"></div>
                <div className="col-xs-1">{outgoingFund.Status}</div>
              </div>
            </React.Fragment>
          );
        });
      }
    }
  }

  let incomingFunds: any[] = [];
  if (anotherDeal.IncomingFunds) {
    incomingFunds = anotherDeal.IncomingFunds.map((incomingFund: any) => {
      return (
        <React.Fragment>
          <div className="row slide-r-l">
            <div className="col-xs-3">
              {formatToCurrency(incomingFund.Amount, anotherDeal.BuyCurrency)} {anotherDeal.BuyCurrency}
            </div>
            <div className="col-xs-2">{incomingFund.SettlementDeliveryMethod}</div>
            <div className="col-xs-4">{incomingFund.SettlementInstrument}</div>
            <div className="col-xs-3">{incomingFund.Status}</div>
          </div>
        </React.Fragment>
      );
    });
  }

  const getDealTypeName = (dealType: number) => {
    switch (dealType) {
      case 1:
        return 'Spot';
      case 4:
        return 'Forward';
      case 2:
        return 'Drawdown';
      default:
        return 'Spot';
    }
  };

  const onNewTradeClick = () => {
    if (props.onClickNewTrade) {
      props.onClickNewTrade();
    }
  };

  const onClickExit = () => {
    if (props.onClickExit) {
      props.onClickExit();
    }
  };

  const onDownloadConfirmation = async (dealID: number) => {
    showLoadingModal();
    await getConfirmation(dealID).then((value) => {
      /*
        ErrorMessage: null
        Message: "An error occurred during local report processing."
        httpErrorMessage: ""
        httpStatusCode: 500
       */
      const httpStatusCode = value.httpStatusCode;
      switch (httpStatusCode) {
        case 200:
          if (value != null && value.data != null) {
            let fileName = 'Deal Confirmation ' + dealID + '.pdf';
            var byteCharacters = atob(value.data);
            var byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            var bytes = new Uint8Array(byteNumbers);
            var blob = new Blob([bytes], {
              type: mime.lookup(fileName) as string
            });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = fileName;
            link.click();
          }
          break;

        case 500:
        case 503:
          showModal(getResource('Js_MessageError_TitleSubmit'), getResource('InternalServerError') + value.Message);
          break;
        default:
          showModal(getResource('Js_MessageError_TitleFormValidation'), getResource('SomethingWentWrong'));
          break;
      }
      hideLoadingModal();
    });
  };

  const onAddLikeTemplate = () => { };

  return (
    <React.Fragment>
      {' '}
      {deal['Deal Header ID'] && (
        <div className="screen-container">
          {/*<div className="row slide-r-l start-xs">
                            <div className="col-xs-4 subtitle-text-primary">Customer Information</div>
                          </div>
                          <div className="row slide-r-l start-xs">
                            <div className="col-xs-2 bold-text">Name</div>
                            <div className="col-xs-4">{deal.Customer['Customer Name']}</div>
                          </div>
                          <div className="row slide-r-l start-xs">
                            <div className="col-xs-2 bold-text">Contact</div>
                            <div className="col-xs-4">{deal.Contact['Name']}</div>
                          </div>
                          <Divider/>*/}

          <div className="row slide-r-l start-xs middle-xs">
            <div className="col-xs-4 subtitle-text-primary">{getResource('Title.DealInformation')}</div>
            <div className="col-xs-8 row end-xs middle-xs" style={{ margin: 0, padding: 0 }}>
              {props.useNewTradeButton && (
                <div className="col-md-4 col-xs-12">
                  <Button type="secondary" onClick={onNewTradeClick}>
                    {getResource('NewTrade')}
                  </Button>
                </div>
              )}
              {props.useConfirmationButton && (
                <div className="col-md-5 col-xs-12">
                  <Button type="secondary" disabled={deal.Status !== 'Accepted'} onClick={() => onDownloadConfirmation(props.dealHeaderID)}>
                    {getResource('DownloadConfirmation')}
                  </Button>
                </div>
              )}
              {props.showExitButton && (
                <div className="col-md-2 col-xs-12">
                  <Button type="secondary" onClick={onClickExit}>
                    {getResource('Btn_Exit')}
                  </Button>
                </div>
              )}
            </div>
          </div>
          <div className="row slide-r-l start-xs">
            <div className="col-xs-3 bold-text">{getResource('Table_TH_DealNumber')}</div>
            <div className="col-xs-2 bold-text-big">{deal['Deal Header ID']}</div>
          </div>
          <div className="row slide-r-l start-xs">
            <div className="col-xs-3 bold-text">{getResource('Table_TH_DealType')}</div>
            <div className="col-xs-2">{getDealTypeName(deal['Deal Type'])}</div>
          </div>
          <div className="row slide-r-l start-xs">
            <div className="col-xs-3 bold-text">{getResource('Table_TH_BuyAmount')}</div>
            <div className="col-xs-2">
              {formatToCurrency(deal['Buy Amount'], deal['Buy Currency'])} {deal['Buy Currency']}
            </div>
          </div>
          <div className="row slide-r-l start-xs">
            <div className="col-xs-3 bold-text">{getResource('Table_TH_SellAmount')}</div>
            <div className="col-xs-2">
              {formatToCurrency(deal['Sell Amount'], deal['Sell Currency'])} {deal['Sell Currency']}
            </div>
          </div>
          {(deal['Deal Type'] === 1 || (deal['Deal Type'] === 4 && deal['Forward Type'] === 'Closed')) && (
            <div className="row slide-r-l start-xs">
              <div className="col-xs-3 bold-text">{getResource('Table_TH_FeeAmount')}</div>
              <div className="col-xs-2">
                {anotherDeal.DealFees != null ?
                  formatToCurrency(anotherDeal.DealFees.reduce((accumulator: number, currentValue: any) => accumulator + currentValue.FeeAmount, 0), deal['Buy Currency']) : '0.00'} {deal['Buy Currency']}
              </div>
            </div>
          )}
          {deal['Deal Type'] === 4 && anotherDeal.Deposits != null && (
            <div className="row slide-r-l start-xs">
              <div className="col-xs-3 bold-text">{getResource('Form.Label.DepositRequired')} ({deal['Deposit Percent']}%)</div>
              <div className="col-xs-2">
                {formatToCurrency(anotherDeal.Deposits[0].Amount, deal['Buy Currency'])} {deal['Buy Currency']}
              </div>
            </div>
          )}
          <div className="row slide-r-l start-xs">
            <div className="col-xs-3 bold-text">{getResource('Table_TH_Rate')}</div>
            <div className="col-xs-2">{deal['Customer Rate']}</div>
          </div>
          {deal['Deal Type'] === 1 && (
            <div className="row slide-r-l start-xs">
              <div className="col-xs-3 bold-text">{getResource('Table_TH_ValueDate')}</div>
              <div className="col-xs-2">{deal['Value Date']}</div>
            </div>
          )}
          {deal['Deal Type'] === 4 && (
            <div className="row slide-r-l start-xs">
              <div className="col-xs-3 bold-text">{getResource('Table_TH_NearDate')}</div>
              <div className="col-xs-2">{deal['Value Date']}</div>
            </div>
          )}
          {deal['Deal Type'] === 4 && (
            <div className="row slide-r-l start-xs">
              <div className="col-xs-3 bold-text">{getResource('Table_TH_FarDate')}</div>
              <div className="col-xs-2">{deal['Maturity Date']}</div>
            </div>
          )}
          <div className="row slide-r-l start-xs">
            <div className="col-xs-3 bold-text">{getResource('Table_TH_Status')}</div>
            <div className="col-xs-2">{deal['Status']}</div>
          </div>
          {deal['Deal Type'] === 4 && (
            <div className="row slide-r-l start-xs">
              <div className="col-xs-3 bold-text">{getResource('Form.Label.ForwardType')}</div>
              <div className="col-xs-2">{deal['Forward Type']}</div>
            </div>
          )}
          <div className="row slide-r-l start-xs">
            <div className="col-xs-3 bold-text">{getResource('PaidStatus')}</div>
            <div className="col-xs-2">{deal['Deal Paid Status']}</div>
          </div>
          {deal.Status === 'Rejected' && <div className="row slide-r-l start-xs">
            <div className="col-xs-3 bold-text">{getResource('Label.RejectReason')}</div>
            <div className="col-xs-2">{deal.RejectReason}</div>
          </div>}
          {/*<Divider />*/}
          {incomingFunds !== undefined && incomingFunds.length > 0 && (
            <div className="row slide-r-l start-xs">
              <div className="col-xs-4 subtitle-text-primary">{getResource('Title.SettlementInformation')}</div>
            </div>
          )}
          {incomingFunds !== undefined && incomingFunds.length > 0 && (
            <div className="row slide-r-l">
              <div className="col-xs-3 bold-text">{getResource('Amount')}</div>
              <div className="col-xs-2 bold-text">{getResource('Label.DeliveryMethod')}</div>
              <div className="col-xs-4 bold-text">{getResource('Label.Instrument')}</div>
              <div className="col-xs-3 bold-text">{getResource('Label.Status')}</div>
            </div>
          )}
          {incomingFunds}
          {/*<Divider />*/}
          {outgoingFunds.length > 0 && (
            <>
              <div className="row slide-r-l start-xs">
                {(deal['Deal Type'] === 1 || (deal['Deal Type'] === 4 && anotherDeal.OutgoingFunds !== undefined && dealDrawdowns.length === 0)) && (
                  <div className="col-xs-4 subtitle-text-primary">{getResource('DisbursementsInformation')}</div>
                )}
                {deal['Deal Type'] === 4 && dealDrawdowns.length > 0 && <div className="col-xs-4 subtitle-text-primary">{getResource('DrawdownsInformation')}</div>}
              </div>
              {(deal['Deal Type'] === 1 || (deal['Deal Type'] === 4 && anotherDeal.OutgoingFunds)) && (
                <div className="row slide-r-l">
                  <div className="col-xs-2 bold-text">{getResource('Js_TH_Beneficiary')}</div>
                  <div className="col-xs-1 bold-text">{getResource('Label.Country')}</div>
                  <div className="col-xs-2 bold-text">{getResource('SendAmount')}</div>
                  <div className="col-xs-2 bold-text">{getResource('Label.Instrument')}</div>
                  <div className="col-xs-4 bold-text">{getResource('BankAccountInformation')}</div>
                  <div className="col-xs-1 bold-text">{getResource('Label.Status')}</div>
                </div>
              )}
              {deal['Deal Type'] === 4 && dealDrawdowns.length > 0 && (
                <div className="row slide-r-l ">
                  <div className="col-xs-1°5 bold-text">{getResource('Table_TH_DealNumber')}</div>
                  <div className="col-xs-1°5 bold-text">{getResource('Table_TH_SellAmount')}</div>
                  <div className="col-xs-1°5 bold-text">{getResource('Table_TH_BuyAmount')}</div>
                  <div className="col-xs-1°5 bold-text">{getResource('Label.Fee')}</div>
                  <div className="col-xs-1°5 bold-text">{getResource('Table_TH_ValueDate')}</div>
                  <div className="col-xs-1°5 bold-text">{getResource('Label.Status')}</div>
                </div>
              )}
              {outgoingFunds}
            </>
          )}
          {props.useSaveTemplateButton && (
            <div className="row end-xs" style={{ paddingTop: 30 }}>
              <div className="col-md-3 row end-xs" style={{ margin: 0, padding: 0 }}>
                <Button
                  type="secondary"
                  onClick={() => {
                    setShowAddLikeTemplateModal(true);
                  }}
                >
                  {props.saveTemplateModalProps ? props.saveTemplateModalProps.ButtonLabel : getResource('AddDealTemplate')}
                </Button>
              </div>
            </div>
          )}
        </div>
      )}
      <AddEditOrderTemplate
        type={'add'}
        deal={deal}
        anotherDeal={anotherDeal}
        //outgoingFunds={outgoingFunds}
        show={showAddLikeTemplateModal}
        saveTemplateModalProps={{
          ButtonLabel: getResource('Button.AddOrderTemplate'),
          Modaltitle: getResource('Button.NewOrder')
        }}
        onCancel={() => setShowAddLikeTemplateModal(false)}
      />
    </React.Fragment>
  );
};

export default Summary;