import { genericCallWithBodyAndResponseDecoded, genericGetWithParameters } from './Networking';

export async function getListOfRelatedCustomers(customerID: number){
    try {
        let params = {
            CustomerID: customerID,
        }

        let response = await genericGetWithParameters('Customer/GetListOfRelatedCustomers',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getCustomerById(customerID: number){
    try {
        let params = {
            customerID: customerID,
        }

        let response = await genericGetWithParameters('Customer/GetCustomerById',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getCustomerInformation(customerID: number){
    try {
        let params = {
            customerID: customerID,
        }

        let response = await genericGetWithParameters('Customer/GetCustomerInformation',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function updateCustomerInformation(model: any) {
    try {
        let method = 'PUT';
        let apiName = 'Customer/UpdateCustomerInformation';
        let response = await genericCallWithBodyAndResponseDecoded(method, apiName, model);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export interface Contact {
  Address2: string;
  AddressValue: string;
  City: string;
  ContactID: number;
  CountryId: string;
    CountryName: string;
  CustomerStatus: string;
  DailyTradeLimit: number;
  DailyTradeLimitWarningOrEnforce: string;
  DateOfBirth: string;
  Email: string;
  EntityProperties: string;
  FirstName: string;
  LastName: string;
  MainContact: boolean | number;
  OnlineAccess: boolean;
  OtherState: string;
  PhoneNumber: string;
  MobileNumber: string;
  StateId: number;
    StateName: string;
  Status: string;
  TradeLimit: number;
  TradeLimitWarningOrEnforce: string;
  UpdateToken: string;
  ZipCode: string;
  EntityPropertiesList: EntityProperty[]
}
export interface EntityProperty {
    EntityId            : number,
    EntityType          : string,
    PropertyDescription : string,
    PropertyValue       : string | number,
    CreatedBy           : string,
    UpdateToken         : string
  }
export interface ContactResponse {
  Contacts: Contact[];
}

export const getListOfCustomerContacts = async (customerID: number): Promise<ContactResponse | undefined> => {
  try {
    const params = {
      customerID: customerID
    };
    const response = await genericGetWithParameters('Customer/GetListOfCustomerContacts', params);
    return response;
  } catch (error) {
    console.error(error);
  }
};

export async function getOnlineDefaults(customerId: number, contactId: number){
    try {
        let params = {
            customerID: customerId,
            contactID: contactId
        }

        let response = await genericGetWithParameters('Customer/GetOnlineDefaults',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function saveCustomerOnlineAccountDefaults(customerId: number, contactId: number, values: any) {
    try {
        let params = {
            CustomerID: customerId,
            ContactID: contactId,
            Values: values,
            CreatedBy: sessionStorage.getItem('UserID')
        }

        let response = await genericCallWithBodyAndResponseDecoded('POST', 'Customer/SaveCustomerOnlineAccountDefaults', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getInstrumentsByCustomer(customerID: number) {
  try {
    let params = { customerID: customerID };
    let response = await genericGetWithParameters('Instrument/GetInstrumentsByCustomer', params);
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function getCustomerMSB() {
  try {
    let params = {
    };

    let responseJWT = await genericGetWithParameters('Customer/GetMSBCustomers', params);
    return responseJWT
  } catch (error) {
    console.error(error);
  }
}

export async function searchCustomerMSB(textToSearch: any) {
  try {
    let params = {
      'TextToSearch': textToSearch
    };

    let responseJWT = await genericGetWithParameters('CustomerSearch/SearchCustomerMSB', params);
    return responseJWT
  } catch (error) {
    console.error(error);
  }
}

export const searchCustomerByIdNameMin = async (customer: any) => {
  try {
    const params = {
      'customer': customer
    };
    const response = await genericGetWithParameters('Customer/SearchCustomerByIdNameMin', params);
    return response;
  } catch (error) {
    console.error(error);
  }
}