import { genericGetWithParametersNoEncrypted } from './Networking';

export const getRolesByCustomerAndContact = async (customerID: number, contactID: number, applicationName: string) => {
  try {
    const params = {
      applicationName: applicationName,
      customerId: customerID,
      contactId: contactID
    };
    const response = await genericGetWithParametersNoEncrypted('Role/GetRolesByCustomerAndContact', params);
    return response;
  } catch (error) {
    console.error(error);
  }
};
