import React, { FunctionComponent, useEffect, useState } from 'react';
import CurrencyFlag from 'react-currency-flags';
import { formatToCurrency, getYear, toDDMMMFormat } from '../../../helpers/FormatHelper';
import { getResource } from '../../../helpers/ResourcesHelper';

interface TransactionProps {
    deal: any,
    key?: any,
    onClickTransaction: Function,
}

const Transaction: FunctionComponent<TransactionProps> = (props: any) => {

    const [deal, setDeal] = useState({} as any);
    const [date, setDate] = useState('');

    useEffect(() => {
        setDeal(props.deal);
        setDate(props.deal['ValueDate']);
    }, [props.deal])

    const onclickADeal = (deal: any) => {
        props.onClickTransaction(deal);
    }
    return (
        <li
            className="li-widget"
            style={{
                //backgroundColor: 'ButtonShadow',
                display: 'flex',
                flexDirection: 'row',
                border: '1px solid #E7E7E7',
                borderRadius: '10px',
                cursor: 'pointer'
            }}
            onClick={onclickADeal.bind(this, deal)}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    //backgroundColor: 'thistle',
                    width: '15%',
                    textAlign: 'center',
                    margin: 'auto'
                }}
            >
                <span style={{ fontSize: 14 }}>{toDDMMMFormat(date)}</span>
                <span style={{ fontSize: 11 }}>{getYear(date)}</span>
            </div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '55%',
                    margin: 'auto'
                }}
            >
                <span
                    style={{
                        fontWeight: 'bold',
                        //backgroundColor: 'turquoise',
                        height: '40%',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden'
                    }}
                >
                    {props.deal['DealID']}</span>
                <span
                    style={{
                        //backgroundColor: 'violet',
                        height: '30%',
                        paddingLeft: 2,
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        fontSize: '14px'
                    }}
                >
                    {getResource('Label.Buy')}: {formatToCurrency(props.deal["BuyAmount"], props.deal['BuyCurrency'])} {props.deal['BuyCurrency']} <CurrencyFlag currency={props.deal['BuyCurrency']} width={15} />
                </span>
                <span
                    style={{
                        //backgroundColor: 'yellowgreen',
                        height: '30%',
                        paddingLeft: 2,
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        fontSize: '14px'
                    }}
                >
                    {getResource('Label.Sell')}: {formatToCurrency(props.deal['SellAmount'], props.deal['SellCurrency'])} {props.deal['SellCurrency']} <CurrencyFlag currency={props.deal['SellCurrency']} width={15} />
                </span>
            </div>
            <div style={{
                fontSize: 15,
                fontWeight: 'bold',
                textAlign: 'center',
                width: '30%',
                //backgroundColor: 'wheat',
                margin: 'auto',
            }}>
                <label style={{ lineClamp: 2 }}>{props.deal['Status']}</label>
            </div>
        </li>
    );
}
export default Transaction;