import { Table } from 'ant-table-extensions';
import { Modal } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import { getInvoicesIssuedForImport, importInvoicesIssued, saveInvoices } from '../../api/NetworkingInvoice';
import AppContext from '../../App.context';
import { downloadTemplateCSV, formatDateString } from '../../helpers/FormatHelper';
import { getResource } from '../../helpers/ResourcesHelper';
import UploadModal from '../beneficiaries/UploadModal';
import Button from '../inputs/Button';


export class ResponseGetInvoicing {
    InvoiceNumber: string;
    InvoiceDate: string;
    ExternalCustomerName: string;
    InvoiceDescription: string;
    Total: number;
    ExternalCustomerEmail: string;
    Terms: number;
    BeneficiaryID: number;
    BeneficiaryName: string;
    Amount: number;
    CurrencyID: string;
    Taxes: number;
    InvoiceLink: string;

    constructor(amount: number, currencyID: string, taxes: number, invoiceLink: string, invoiceNumber: string, invoiceDate: string, externalCustName: string, invoiceDescription: string, totalInvoice: number, externalCustEmail: string, terms: number, beneficiaryID: number, beneficiary: string) {
        this.InvoiceNumber = invoiceNumber;
        this.InvoiceDate = invoiceDate;
        this.ExternalCustomerName = externalCustName;
        this.InvoiceDescription = invoiceDescription;
        this.Total = totalInvoice;
        this.ExternalCustomerEmail = externalCustEmail;
        this.Terms = terms;
        this.BeneficiaryID = beneficiaryID;
        this.BeneficiaryName = beneficiary;
        this.Amount = amount;
        this.CurrencyID = currencyID;
        this.Taxes = taxes;
        this.InvoiceLink = invoiceLink;
    }
}
interface InvoiceUploadProps {
    InvoiceType: string;
    onClose: Function;
}

const InvoiceUpload: FunctionComponent<InvoiceUploadProps> = (props) => {
    const { showModal, showLoadingModal, hideLoadingModal } = useContext(AppContext);
    const [invoiceImportTemplate, setInvoiceImportTemplate] = useState([]);
    const [showUploadTemplate, setShowUploadTemplate] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [invoiceList, setInvoiceList] = useState([],);
    const [fileUploaded, setFileUploaded] = useState<any>();

    useEffect(() => {
        const userSession = JSON.parse(localStorage.getItem('UserSession')!);
        if (props.InvoiceType !== 'Payable') {
            //template
            var invoicesTemplate = [] as any;
            var importColumns = 'Invoice Number,Invoice Date,External Customer Name,Invoice Description,Amount,Currency,Taxes,Total,Externak Customer Email,Invoice link,Terms';
            var sampleData = '10020,23/03/2021,Nexolink,Test 1,1000,USD,10,1010,aa@aa.com,www.invoice.com,10';
            let columns = importColumns.split(',') || [] as any;
            let sample1Data = sampleData.split(',') || [] as any;

            let sample1 = {} as any;
            sample1Data.forEach((x: any, i: number) => {
                sample1[columns[i]] = x
            })
            invoicesTemplate.push(sample1);
            setInvoiceImportTemplate(invoicesTemplate);
        } else {
            getInvoicesIssuedForImport(userSession.CustomerID).then((json) => {
                if (json != null && json.PayableInvoicesImportRecords != null) {
                    setInvoiceImportTemplate(json.PayableInvoicesImportRecords);
                }
            });
        }
    }, [props.InvoiceType])

    const handleUploadClick = () => {
        setShowUploadTemplate(true);
    }

    const handleSaveClick = () => {
        setShowConfirmation(true);
    }
    const onFileChangeHandler = (event: any) => {
        setFileUploaded(event.target.files[0])
    }

    const onUploadFile = () => {
        showLoadingModal();
        const userSession = JSON.parse(localStorage.getItem('UserSession')!)
        readFileAsBase64(fileUploaded).then(
            (data: any) => {
                let fileContent = data.replace(/^data:.+;base64,/, '');
                var model = {
                    'FileContent': fileContent,
                    'UserID': localStorage.getItem('UserID'),
                    'FileName': fileUploaded.name,
                    'CustomerID': userSession.CustomerID,
                    'InvoiceType': props.InvoiceType
                }

                importInvoicesIssued(model).then(
                    (json) => {
                        if (json != null && json.BeneficiaryPayments != null && json.BeneficiaryPayments.BeneficiaryPayment != null) {
                            var invoiceList = json.BeneficiaryPayments.BeneficiaryPayment;
                            if (invoiceList.length > 0) {
                                setInvoiceList(invoiceList);
                                hideLoadingModal();
                                setShowUploadTemplate(false);
                            }
                            else {
                                setInvoiceList([]);
                                hideLoadingModal();
                                setShowUploadTemplate(false);
                            }
                        }
                        else {
                            var message = getResource('Something went wrong. Please try again later.');
                            if (json.Message !== '' && json.Message != null) {
                                message = json.Message;
                            }
                            hideLoadingModal();
                            setInvoiceList([]);
                            setShowUploadTemplate(false);
                            showModal(getResource('Js_MessageError_TitleError'), message);
                        }
                    }
                )
            });
    }


    const readFileAsBase64 = (file: any) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }
    const closeAlert = () => {
        setShowConfirmation(false);
    }

    const yesAlert = () => {
        showLoadingModal();
        setShowConfirmation(false);

        let userID = localStorage.getItem('UserID');
        var invoices = [] as any;
        invoiceList.forEach((element: any) => {
            invoices.push({
                CustomerId: element.CustomerID,
                InvoiceNumber: element.InvoiceNumber,
                InvoiceDate: element.InvoiceDate,
                InvoiceDescription: element.InvoiceDescription,
                Amount: element.Amount,
                CurrencyId: element.CurrencyID,
                Taxes: element.Taxes,
                Total: element.Total,
                ExternalCustomerName: element.ExternalCustomerName,
                ExternalCustomerEmail: element.ExternalCustomerEmail,
                InvoiceLink: element.InvoiceLink,
                CreatedBy: userID,
                InvoiceType: props.InvoiceType,
                Terms: element.Terms,
                BeneficiaryID: element.BeneficiaryID
            });
        }
        );
        var model = { Invoices: invoices };

        saveInvoices(model).then((response: any) => {
            if (typeof response !== undefined && response.httpStatusCode === 200) {
                hideLoadingModal();
                showModal(getResource('Js_MessageError_TitleSuccess'), getResource('Js_Message_SaveSuccessfully'));
                onClickExit();
             }
             else {
                hideLoadingModal();
                showModal(getResource('Js_MessageError_TitleError'), getResource('Something went wrong. Please try again later.'));
            }
        });
    }

    const onClickExit = () => {
        props.onClose();
    }

    const invoicingColumnsPayable: ColumnsType<ResponseGetInvoicing> = [
        {
            title: getResource('Label.InvoiceNumber'),
            dataIndex: 'InvoiceNumber',
            key: 'history_invoiceNumber',
            align: 'left',
            sorter: (a, b) => a.InvoiceNumber.localeCompare(b.InvoiceNumber)
        },
        {
            title: getResource('Label.InvoiceDate'),
            dataIndex: 'InvoiceDate',
            key: 'history_invoiceDate',
            align: 'center',
            render: (value: any, obj: ResponseGetInvoicing, index: any) => (
                <span>{formatDateString(value)}</span>
            ),
            defaultSortOrder: 'ascend',
            sorter: (a, b) => new Date(a.InvoiceDate).getTime() - new Date(b.InvoiceDate).getTime()
        },
        {
            title: getResource('Label.InvoiceBeneficiaryID'),
            dataIndex: 'BeneficiaryID',
            key: 'history_beneficiaryID',
            align: 'left',
            sorter: (a, b) => a['BeneficiaryID'] - b['BeneficiaryID']
        },
        {
            title: getResource('Label.InvoiceBenediciaryName'),
            dataIndex: 'BeneficiaryName',
            key: 'history_beneficiary',
            align: 'left',
            render: (value: any, obj: ResponseGetInvoicing, index: any) => (
                <span>{value}</span>
            ),
            sorter: (a, b) => a['BeneficiaryName'].localeCompare(b['BeneficiaryName'])
        },
        {
            title: getResource('Label.InvoiceDescription'),
            dataIndex: 'InvoiceDescription',
            key: 'history_invoiceDescription',
            sorter: (a, b) => a.InvoiceDescription.localeCompare(b.InvoiceDescription),
            align: 'left',
        },
        {
            title: getResource('Js_TH_Amount'),
            dataIndex: 'Amount',
            key: 'history_amount',
            sorter: (a, b) => a.Amount - b.Amount,
            align: 'left',
        },
        {
            title: getResource('Js_TH_Currency'),
            dataIndex: 'CurrencyID',
            key: 'history_currency',
            sorter: (a, b) => a['CurrencyID'].localeCompare(b['CurrencyID']),
            align: 'left',
        },
        {
            title: getResource('Label.InvoiceTaxes'),
            dataIndex: 'Taxes',
            key: 'history_taxes',
            sorter: (a, b) => a.Taxes - b.Taxes,
            align: 'left',
        },
        {
            title: getResource('Label.InvoiceTotal'),
            dataIndex: 'Total',
            key: 'history_totalInvoice',
            sorter: (a, b) => a.Total - b.Total,
            align: 'left',
        },
        {
            title: getResource('Label.InvoiceTerms'),
            dataIndex: 'Terms',
            key: 'history_terms',
            sorter: (a, b) => a.Terms - b.Terms,
            align: 'left',
        },
    ];
    const invoicingColumnsReceivable: ColumnsType<ResponseGetInvoicing> = [
        {
            title: getResource('Label.InvoiceNumber'),
            dataIndex: 'InvoiceNumber',
            key: 'history_invoiceNumber',
            align: 'left',
            sorter: (a, b) => a.InvoiceNumber.localeCompare(b.InvoiceNumber)
        },
        {
            title: getResource('Label.InvoiceDate'),
            dataIndex: 'InvoiceDate',
            key: 'history_invoiceDate',
            align: 'center',
            render: (value: any, obj: ResponseGetInvoicing, index: any) => (
                <span>{formatDateString(value)}</span>
            ),
            defaultSortOrder: 'ascend',
            sorter: (a, b) => new Date(a.InvoiceDate).getTime() - new Date(b.InvoiceDate).getTime()
        },
        {
            title: getResource('Label.InvoiceSenderName'),
            dataIndex: 'ExternalCustomerName',
            key: 'history_externalCustomerName',
            sorter: (a, b) => a.ExternalCustomerName.localeCompare(b.ExternalCustomerName),
            align: 'left',
        },
        {
            title: getResource('Label.InvoiceDescription'),
            dataIndex: 'InvoiceDescription',
            key: 'history_invoiceDescription',
            sorter: (a, b) => a['InvoiceDescription'].localeCompare(b['InvoiceDescription']),
            align: 'left',
        },
        {
            title: getResource('Js_TH_Amount'),
            dataIndex: 'Amount',
            key: 'history_amount',
            sorter: (a, b) => a.Amount - b.Amount,
            align: 'left',
        },
        {
            title: getResource('Js_TH_Currency'),
            dataIndex: 'CurrencyID',
            key: 'history_currency',
            sorter: (a, b) => a['CurrencyID'].localeCompare(b['CurrencyID']),
            align: 'left',
        },
        {
            title: getResource('Label.InvoiceTaxes'),
            dataIndex: 'Taxes',
            key: 'history_taxes',
            sorter: (a, b) => a.Taxes - b.Taxes,
            align: 'left',
        },
        {
            title: getResource('Label.InvoiceTotal'),
            dataIndex: 'Total',
            key: 'history_totalInvoice',
            sorter: (a, b) => a.Total - b.Total,
            align: 'left',
        },
        {
            title: getResource('Label.InvoiceSenderEmail'),
            dataIndex: 'ExternalCustomerEmail',
            key: 'history_externalCustomerEmail',
            sorter: (a, b) => a.ExternalCustomerEmail.localeCompare(b.ExternalCustomerEmail),
            align: 'left',
        },
        {
            title: getResource('Label.InvoiceLink'),
            dataIndex: 'InvoiceLink',
            key: 'history_invoiceLink',
            sorter: (a, b) => a.InvoiceLink.localeCompare(b.InvoiceLink),
            align: 'left',
        },
        {
            title: getResource('Label.InvoiceTerms'),
            dataIndex: 'Terms',
            key: 'history_terms',
            sorter: (a, b) => a.Terms - b.Terms,
            align: 'left',
        },
    ];
    const downloadBlankTemplate = () => {
        downloadTemplateCSV(invoiceImportTemplate, 'InvoicesTemplate');
    }
    return (
        <div>
            <Modal
                title={<h3 className="component-title">{'Upload ' + props.InvoiceType + ' Invoices'}</h3>}
                centered
                visible={true}
                footer={null}
                //onCancel={props.changeState()}
                maskClosable={false}
                width={'75%'}
                zIndex={1000}
                closable={false}

            >
                <div>
                    <div className='row end-xs slide-t-b'>
                        <div className='col-md-4'>
                            <Button
                                type={'secondary'}
                                onClick={downloadBlankTemplate}
                            >{getResource('SubMenu_DownloadTemplateForInvoices')}
                            </Button>
                        </div>
                        <div className='col-md-4'>
                            <Button
                                type={'secondary'}
                                onClick={handleUploadClick}
                            >{getResource('Upload')}
                            </Button>
                        </div>
                    </div>
                    <div className="row middle-xs center-xs">
                        <div className='col-md-12'>
                            <Table
                                key={'invoicing-table'}
                                columns={props.InvoiceType !== 'Payable' ? invoicingColumnsReceivable : invoicingColumnsPayable}
                                dataSource={invoiceList}
                            />
                        </div>
                    </div>

                    <div className="row middle-xs end-xs">
                        <div className="col-xs-12 col-md-3">
                            <div className="col">
                                <Button id="exit-button" type="secondary" onClick={onClickExit}>
                                    Exit
                                </Button>
                            </div>
                        </div>
                        <div className="col-xs-12 col-md-3">
                            <div className="col">
                                <Button id="exit-button" type="primary" onClick={handleSaveClick}>
                                    {getResource('Button.Save')}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <UploadModal showModalUpload={showUploadTemplate} title={'Upload File'} fileUploaded={fileUploaded} accept={'.csv'} setShowModalUpload={() => {
                setFileUploaded(undefined);
                setShowUploadTemplate(false);
            }}
                onFileChangeHandler={onFileChangeHandler} importFile={onUploadFile}>
            </UploadModal>
            {showConfirmation ?
                <Modal
                    title={<h3 className="component-title">{'Confirm'}</h3>}
                    centered
                    visible={showConfirmation}
                    footer={null}
                    //onCancel={props.changeState()}
                    maskClosable={false}
                    width={'25%'}
                    zIndex={1010}
                    closable={false}
                >
                    <div>
                        <div>
                            <h4>{getResource('AreYouSureYouWantToSave')}</h4>
                        </div>
                        <div className="row">
                            <div className="col-xs-12 col-md-6">
                                <div className="col">
                                    <Button id="confirmation-afex-OK" type="primary" onClick={yesAlert}>
                                        {'Yes'}
                                    </Button>
                                </div>
                            </div>
                            <div className="col-xs-12 col-md-6">
                                <div className="col">
                                    <Button id="confirmation-afex-cancel" type="primary" onClick={closeAlert}>
                                        {'No'}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal> : null}
        </div>
    );
}
export default InvoiceUpload;