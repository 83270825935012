import { genericCallWithBody, genericCallWithBodyAndResponseDecoded, genericDeleteWithParameters, genericGetWithParameters } from './Networking';

export async function searchTransactionUpload(customerIdMsb: any, fromDate: any, toDate: any) {
  try {
    let params = {
      'customerIdMSB': customerIdMsb,
      'fromDate': fromDate,
      'toDate': toDate
    };

    let responseJWT = await genericGetWithParameters('TransactionUpload/SearchTransactionUpload', params);
    return responseJWT
  } catch (error) {
    console.error(error);
  }
}

export async function deleteTransactionUpload(transactionUploadId: any) {
  let params = {
    'transactionUploadId': transactionUploadId
  };
  let response = await genericDeleteWithParameters('TransactionUpload/TransactionUpload_Delete', params);
  return response;
}

export async function getDetails(transactionUploadId: any) {
  try {
    let params = {
      'transactionUploadId': transactionUploadId
    };

    let responseJWT = await genericGetWithParameters('TransactionUpload/TransactionUpload_GetDetails', params);
    return responseJWT
  } catch (error) {
    console.error(error);
  }
}

export const updateComment = async (transactionUploadId: any, detailId: any, comment: any, updateToken: any) => {
  try {
    const model = {
      'TransactionUploadId': transactionUploadId,
      'DetailId': detailId,
      'Comment': comment,
      'UpdatedBy': localStorage.getItem('UserID'),
      'UpdateToken': updateToken
    };

    const response = await genericCallWithBody('PUT', 'TransactionUpload/TransactionUpload_UpdateComment', model);
    return response;
  }
  catch (error) {
    console.error(error);
  }
}

export const saveTransactionUpload = async (customerIdMSB: any, status: any, fileContent: any, fileName: any) => {
  try {
    const model = {
      'CustomerIdMSB': customerIdMSB,
      'Status': status,
      'UploadedBy': localStorage.getItem('UserID'),
      'FileContent': fileContent,
      'FileName': fileName
    };

    const response = await genericCallWithBodyAndResponseDecoded('POST', 'TransactionUpload/TransactionUpload_Save', model);
    return response;
  }
  catch (error) {
    console.error(error);
  }
}