import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { formatValidInputClass } from '../../../../helpers/FormatHelper';
import { getResource } from '../../../../helpers/ResourcesHelper';
import Button from '../../../inputs/Button';
import DatePicker, { dateFormat } from '../../../inputs/DatePicker';
import Dropdown from '../../../inputs/Dropdown';
import Input from '../../../inputs/Input';
import { TransferFrequencyDetailsModel } from '../AddEditRegularPayment';

type StepStatus = 'wait' | 'process' | 'finish' | 'error' | undefined;
interface TransferFrequencyDetailsProps {
  details: TransferFrequencyDetailsModel | null;
  action: string;
  onCancel: () => void;
  onStep: (currenStatus: StepStatus, newTransferDetails: TransferFrequencyDetailsModel, valid: boolean) => void;
  onTrySave: (currenStatus: StepStatus, newTransferDetails: TransferFrequencyDetailsModel, valid: boolean) => void;
}

interface Validation {
  valid: boolean;
  validations: {
    transferStartingDate: boolean;
    transferType: boolean;
    endingType: boolean;
    endingAfter: boolean;
    endingOn: boolean;
    transferEvery: boolean;
    startingOn: boolean;
    externalComments: boolean;
    status: boolean;
  };
}

const TransferFrequencyDetails: React.FC<TransferFrequencyDetailsProps> = ({ details, onStep, onCancel, onTrySave }) => {
  type TransferType = 'Weekly' | 'Bi-Weekly' | 'Monthly' | 'Semi-Monthly' | '';
  type EndingType = 'Never' | 'After' | 'End on' | '';
  const [transferStartingDate, setTransferStartingDate] = useState('');
  const [transferType, setTransferType] = useState<TransferType>('');
  const [endingType, setEndingType] = useState<EndingType>('');
  const [endingAfter, setEndingAfter] = useState<number | string>('');
  const [endingOn, setEndingOn] = useState('');
  const [transferEvery, setTransferEvery] = useState('');
  const [startingOn, setStartingOn] = useState<number | string>('');
  const [externalComments, setExternalComments] = useState('');
  const [status, setStatus] = useState('');
  const [validations, setValidations] = useState({
    transferStartingDate: true,
    transferType: true,
    endingType: true,
    endingAfter: true,
    endingOn: true,
    transferEvery: true,
    startingOn: true,
    externalComments: true,
    status: true
  });
  const initialLoad = useRef(true);

  const validateFields = useCallback((model: TransferFrequencyDetailsModel): Validation => {
    const isLeapYear = (year: number): boolean => {
      if (year % 4 === 0) {
        if (year % 100 === 0) {
          if (year % 400 === 0) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      }
      return false;
    };

    let transferStartingDate = true;
    let startingOn = true;
    let transferEvery = true;
    let transferType = true;
    let endingType = true;
    let endingOcurrences = true;
    let endingOnDate = true;
    let externalComments = true;
    let status = true;

    if (model.transferStartingDate === '') {
      transferStartingDate = false;
    }
    if (model.externalComments === '') {
      externalComments = false;
    }
    if (model.status === '') {
      status = false;
    }
    if (model.ending === '') {
      endingType = false;
    }
    if (model.transferType === '') {
      transferType = false;
    }
    if (model.transferType === 'Weekly' || model.transferType === 'Bi-Weekly') {
      if (model.transferEvery === '') {
        transferEvery = false;
      }
    } else if (model.transferType === 'Monthly') {
      if (model.startingOn === '') {
        startingOn = false;
      }
      if (model.transferStartingDate != null) {
        enum Month {
          January = 1,
          February,
          March,
          April,
          May,
          June,
          July,
          August,
          September,
          October,
          November,
          December
        }
        const [y, m] = model.transferStartingDate.split('/');
        const month: Month = parseInt(m);
        const year = parseInt(y);
        switch (month) {
          case Month.January:
          case Month.March:
          case Month.May:
          case Month.July:
          case Month.August:
          case Month.October:
          case Month.December: {
            if (model.startingOn < 1 || model.startingOn > 31) {
              startingOn = false;
            }
            break;
          }
          case Month.April:
          case Month.June:
          case Month.September:
          case Month.November: {
            if (model.startingOn < 1 || model.startingOn > 30) {
              startingOn = false;
            }
            break;
          }
          case Month.February: {
            if (isLeapYear(year)) {
              if (model.startingOn < 1 || model.startingOn > 29) {
                startingOn = false;
              }
            } else {
              if (model.startingOn < 1 || model.startingOn > 28) {
                startingOn = false;
              }
            }
            break;
          }
        }
      }
    }
    if (model.ending === 'After') {
      if (model.endingOcurrences === '') {
        endingOcurrences = false;
      }
    } else if (model.ending === 'End on') {
      if (model.endingOnDate === '') {
        endingOnDate = false;
      }
    }
    return {
      valid: transferStartingDate && startingOn && transferEvery && transferType && endingType && endingOcurrences && endingOnDate && externalComments && status,
      validations: {
        transferStartingDate,
        transferType,
        endingType,
        endingAfter: endingOcurrences,
        endingOn: endingOnDate,
        transferEvery,
        startingOn,
        externalComments,
        status
      }
    };
  }, []);

  useEffect(() => {
    if (details != null && initialLoad.current) {
      setTransferStartingDate(details.transferStartingDate);
      setTransferType(details.transferType as TransferType);
      setEndingType(details.ending as EndingType);
      setTransferEvery(details.transferEvery);
      setEndingAfter(details.endingOcurrences);
      setEndingOn(details.endingOnDate);
      setStartingOn(details.startingOn);
      setExternalComments(details.externalComments);
      setStatus(details.status);
      initialLoad.current = false;
    }
  }, [details, validateFields]);

  const goToPrevious = () => {
    const model: TransferFrequencyDetailsModel = {
      transferStartingDate: transferStartingDate,
      transferType: transferType,
      transferEvery: transferEvery,
      startingOn: startingOn,
      ending: endingType,
      endingOnDate: endingOn,
      endingOcurrences: endingAfter,
      externalComments: externalComments,
      status: status
    };
    if (validateFields(model)) {
      onStep('finish', model, true);
    } else {
      onStep('error', model, false);
    }
  };

  const trySave = () => {
    const model: TransferFrequencyDetailsModel = {
      transferStartingDate: transferStartingDate,
      transferType: transferType,
      transferEvery: transferEvery,
      startingOn: startingOn,
      ending: endingType,
      endingOnDate: endingOn,
      endingOcurrences: endingAfter,
      externalComments: externalComments,
      status: status
    };
    const { valid, validations } = validateFields(model);
    setValidations(validations);
    if (valid) {
      onTrySave('finish', model, true);
    } else {
      onTrySave('error', model, false);
    }
  };

  const getDate = (dateString: string) => {
    if (dateString === '') {
      return undefined;
    }
    const date = moment(dateString, dateFormat);
    return date && date.isValid() ? date : undefined;
  };

  const onChangeStartingDate = (_: any, dateString: string) => {
    setTransferStartingDate(dateString);
    setValidations({ ...validations, transferStartingDate: true });
  };

  const onChangeTransferType = (value: any) => {
    const type: TransferType = value.value;
    setTransferType(type);
    if (type === 'Weekly' || type === 'Bi-Weekly') {
      setStartingOn('');
    } else if (type === 'Monthly') {
      setTransferEvery('');
    }
    setValidations({ ...validations, transferType: true });
  };

  const onChangeEndingType = (value: any) => {
    // setEndingType(event.target.value);
    const type: EndingType = value.value;
    setEndingType(type);
    if (type === 'Never') {
      setEndingAfter('');
      setEndingOn('');
    } else if (type === 'After') {
      setEndingOn('');
    } else if (type === 'End on') {
      setEndingAfter('');
    }
    setValidations({ ...validations, endingType: true, endingAfter: true, endingOn: true });
  };

  const onChangeEndingAfter = (value: number) => {
    setEndingAfter(value);
    setValidations({ ...validations, endingAfter: true });
  };

  const onChangeTransferEvery = (value: any) => {
    setTransferEvery(value.value);
    setValidations({ ...validations, transferEvery: true });
  };

  const onChangeStartingOn = (value: number) => {
    setStartingOn(value);
    setValidations({ ...validations, startingOn: true });
  };

  const onChangeEndOn = (_: any, dateString: string) => {
    setEndingOn(dateString);
    setValidations({ ...validations, endingOn: true });
  };

  const onChangeExternalComment = (event: any) => {
    setExternalComments(event.target.value);
    setValidations({ ...validations, externalComments: true });
  };

  const onChangeStatus = (value: any) => {
    setStatus(value.value);
    setValidations({ ...validations, status: true });
  };

  return (
    <React.Fragment>
      <div className="screen-container slide-r-l">
        <div className="row center-xs">
          <div className="col-md-7">
            <div className="row">
              <div className="col-md-12 col-xs-12">
                <div className="row">
                  <span className="input-label">{getResource('Label.SaveThisPaymentAs') + '(*)'}</span>
                </div>
                <div className="row">
                  <Input
                    id="payment-transfer-external-comment"
                    type="text"
                    value={externalComments}
                    errorMessage={getResource('DataMsgRequired')}
                    onChange={onChangeExternalComment}
                    className={formatValidInputClass(validations.externalComments)}
                    // submit={!valid}
                  />
                </div>
              </div>
            </div>
            <div className="row separator-top">
              <div className="col-md-12 col-xs-12">
                <div className="row">
                  <span className="input-label">{getResource('Label.TransferStartingDate') + '(*)'}</span>
                </div>
                <div className="row">
                  <DatePicker
                    className={`${formatValidInputClass(validations.transferStartingDate)} datepicker-input`}
                    id="payment-transfer-starting-date"
                    value={getDate(transferStartingDate)}
                    errorMessage={getResource('DataMsgRequired')}
                    onChange={onChangeStartingDate}
                    // submit={!valid}
                    valueValidation={transferStartingDate}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-xs-6">
                <div className="row">
                  <span className="input-label">{getResource('Label.Frequency') + '(*)'}</span>
                </div>
                <div className="row">
                  <Dropdown
                    className={formatValidInputClass(validations.transferType)}
                    id="payment-frequency"
                    name="payment-frequency"
                    title={getResource('SelectItem')}
                    type="text"
                    select={{ value: transferType }}
                    list={[
                      { label: getResource('Label.TransferFrequencyWeekly'), value: getResource('Label.TransferFrequencyWeekly') },
                      { label: getResource('Label.TransferFrequencyBiWeekly'), value: getResource('Label.TransferFrequencyBiWeekly') },
                      { label: getResource('Label.TransferFrequencyMonthly'), value: getResource('Label.TransferFrequencyMonthly') },
                      { label: getResource('Label.TransferFrequencySemiMonthly'), value: getResource('Label.TransferFrequencySemiMonthly') }
                    ]}
                    errorMessage={getResource('DataMsgRequired')}
                    onChange={onChangeTransferType}
                    // submit={!valid}
                  />
                </div>
              </div>
              {(transferType === 'Weekly' || transferType === 'Bi-Weekly') && (
                <div className="col-md-6 col-xs-6">
                  <div className="row">
                    <span className="input-label">{getResource('Label.TransferFundsEvery') + '(*)'}</span>
                  </div>
                  <div className="row">
                    <Dropdown
                      className={formatValidInputClass(validations.transferEvery)}
                      list={[
                        { label: 'Monday', value: 'Monday' },
                        { label: 'Tuesday', value: 'Tuesday' },
                        { label: 'Wednesday', value: 'Wednesday' },
                        { label: 'Thursday', value: 'Thursday' },
                        { label: 'Friday', value: 'Friday' }
                      ]}
                      id="payment-transfer-every"
                      name="payment-transfer-every"
                      title={getResource('SelectItem')}
                      select={{ value: transferEvery }}
                      onChange={onChangeTransferEvery}
                      // submit={!valid}
                      errorMessage={getResource('DataMsgRequired')}
                    />
                  </div>
                </div>
              )}
              {transferType === 'Monthly' && (
                <div className="col-md-6 col-xs-6">
                  <div className="row">
                    <span className="input-label">{getResource('Label.TransferStartingOn') + '(*)'}</span>
                  </div>
                  <div className="row">
                    <Input
                      className={formatValidInputClass(validations.startingOn)}
                      type="number"
                      id="payment-starting-on"
                      value={startingOn}
                      onValueChange={(value: number) => {
                        onChangeStartingOn(value);
                      }}
                      // submit={!valid}
                      errorMessage={getResource('DataMsgRequired')}
                      decimalScale={0}
                      placeholder=""
                      min={1}
                      max={31}
                    />
                  </div>
                </div>
              )}
              {transferType === 'Semi-Monthly' && (
                <div className="col-md-6 col-xs-6">
                  <div className="row"></div>
                  <div className="row padding-top-20">
                    <span>{getResource('Label.TransferSemiMonthlyText')}</span>
                  </div>
                </div>
              )}
            </div>
            <div className="row">
              <div className="col-md-6 col-xs-6">
                <div className="row">
                  <span className="input-label">{getResource('Label.TransferEnding') + '(*)'}</span>
                </div>
                <div className="row">
                  <Dropdown
                    className={formatValidInputClass(validations.endingType)}
                    id="payment-transfer-ending"
                    name="payment-transfer-ending"
                    title={getResource('SelectItem')}
                    type="text"
                    select={{ value: endingType }}
                    list={[
                      { label: getResource('Label.TransferEndingUntilSuspended'), value: getResource('Label.TransferEndingNever') },
                      { label: getResource('Label.TransferEndingAfter'), value: getResource('Label.TransferEndingAfter') },
                      { label: getResource('Label.TransferEndingEndsOn'), value: getResource('Label.TransferEndingEndOn') }
                    ]}
                    errorMessage={getResource('DataMsgRequired')}
                    onChange={onChangeEndingType}
                    // submit={!valid}
                  />
                </div>
              </div>
              {endingType === 'After' && (
                <div className="col-md-6 col-xs-6">
                  <div className="row">
                    <span className="input-label">{getResource('Label.TransferEndingAfterOcurrences') + '(*)'}</span>
                  </div>
                  <div className="row">
                    <Input
                      id="payment-ending-after-input"
                      className={formatValidInputClass(validations.endingAfter)}
                      type="number"
                      value={endingAfter}
                      onValueChange={onChangeEndingAfter}
                      disabled={endingType !== 'After'}
                      decimalScale={0}
                      placeholder="0"
                    />
                  </div>
                </div>
              )}
              {endingType === 'End on' && (
                <div className="col-md-6 col-xs-6">
                  <div className="row">
                    <span className="input-label">{getResource('Label.TransferEndingDate') + '(*)'}</span>
                  </div>
                  <div className="row">
                    <DatePicker
                      id="payment-ending-end-on-datepicker"
                      value={getDate(endingOn)}
                      errorMessage={getResource('DataMsgRequired')}
                      className={`${formatValidInputClass(validations.endingOn)} datepicker-input`}
                      disabled={endingType !== 'End on'}
                      onChange={onChangeEndOn}
                      // submit={!valid}
                      valueValidation={endingOn}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="row">
              <div className="col-md-12 col-xs-12">
                <div className="row">
                  <span className="input-label">{getResource('Label.TransferStatus') + '(*)'}</span>
                </div>
                <div className="row">
                  <Dropdown
                    className={formatValidInputClass(validations.status)}
                    id="payment-transfer-status"
                    name="payment-transfer-status"
                    title={getResource('SelectItem')}
                    type="text"
                    select={{ value: status }}
                    list={[
                      { label: 'Active', value: 'Active' },
                      { label: 'Suspended', value: 'Suspended' },
                      { label: 'Expired', value: 'Expired' }
                    ]}
                    errorMessage={getResource('DataMsgRequired')}
                    onChange={onChangeStatus}
                    // submit={!valid}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="padding-top-20" />
        <div className="row">
          <div className="col-md-4 col-xs-12 center-lg center-md">
            <div className="row">
              <Button
                id="payment-cancel"
                type="secondary"
                onClick={() => {
                  onCancel();
                }}
              >
                {getResource('Button.Cancel')}
              </Button>
            </div>
          </div>
          <div className="col-md-4 col-xs-6 center-lg center-md">
            <div className="row">
              <Button id="payment-previous" type="secondary" onClick={goToPrevious}>
                {getResource('Button.Previous')}
              </Button>
            </div>
          </div>
          <div className="col-md-4 col-xs-6 center-lg center-md">
            <div className="row">
              <Button id="payment-next" type="primary" onClick={trySave}>
                {getResource('Button.Save')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TransferFrequencyDetails;
