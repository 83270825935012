import { useEffect, useState } from 'react';
import { getCurrencyList } from '../NetworkingCurrencies';

export interface Currency {
  CurrencyID: string;
  CurrencyIDName: string;
  label: string;
  name: string;
}

const useCurrencies = () => {
  const [currencies, setCurrencies] = useState<Currency[]>([]);
  const [needsReloading, setNeedsReloading] = useState(true);

  useEffect(() => {
    const loadCurrencies = async () => {
      const json = await getCurrencyList();
      if (json.currencies !== undefined) {
        const formattedCurrencies = json.currencies.map((c: Currency) => ({ ...c, label: c.CurrencyIDName, value: c.CurrencyID }));
        setCurrencies(formattedCurrencies);
      } else {
        setCurrencies([]);
      }
      setNeedsReloading(false);
    };
    if (needsReloading) {
      loadCurrencies();
    }
  }, [needsReloading]);

  return [currencies, setNeedsReloading];
};

export default useCurrencies;
