import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import { Collapse, Tooltip, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useContext, useState } from 'react';
import useOnlineRights from '../../api/hooks/useOnlineRights';
import { getQuickBooksDataForExport, importQuickBooksEntities } from '../../api/NetworkingBeneficiaries';
import { saveTransactionUpload } from '../../api/NetworkingTransactionUpload';
import AppContext from '../../App.context';
import { OnlineRightsNames } from '../../helpers/OnlineRightsHelper';
import { getResource } from '../../helpers/ResourcesHelper';
import UploadModal from '../beneficiaries/UploadModal';
import { UploadErrors } from '../models/UploadErrors';
import UploadFileTable from './UploadFileTable';
import UploadTransactionFileErrors from './UploadTransactionFileErrors';

const QuickBooks = (props: any) => {

  const { showModal } = useContext(AppContext);
  const [updloadModalTitle, setUpdloadModalTitle] = useState<string>();
  const [fileUploaded, setFileUploaded] = useState<any>();
  const [fileUploadAccept, setFileUploadAccept] = useState<string>();
  const [showModalUpload, setShowModalUpload] = useState<boolean>(false);
  const [quickBookType, setQuickBookType] = useState<string>("");
  const [showModalFileUpload, setShowModalFileUpload] = useState<boolean>(false);
  const [currentScreen, setCurrentScreen] = useState('quickBooks');
  const [showErrorGrid, setShowErrorGrid] = useState<boolean>(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [reloadGrid, setReloadGrid] = useState<boolean>(false);

  const { Panel } = Collapse;
  const OL_MSBRole = useOnlineRights(OnlineRightsNames.OL_MSBRole);;

  const downloadTemplateData = { Sheet1: [{ 'Transaction Date (MM/dd/yyyy)': '02/18/2021', 'Country': 'PAKISTAN', 'Transaction No': 'K40010', 'Customer Rate': '1.56', 'Currency': 'PKR', 'Amount': '1000', 'GBP DUE': '433', 'P/M': 'Cash', 'Remitter ID': 'PFID4149', 'Remitter Name': 'XXXXX', 'Remitter Address': '9 Tudor Road', 'Remitter City': 'Southall Greater London', 'Remitter Post Code': 'UB1 1NX', 'Remitter Contact No': '1111', 'Remitter Email': 'remitter@test.com', 'Remitter ID Type': 'Driving License', 'Remitter ID No': '1234', 'Remitter ID Expiry (MM/dd/yyyy)': '10/11/2023', 'Remitter Date of Birth (MM/dd/yyyy)': '1/01/2001', 'Remitter Place of Birth': 'PAKISTAN', 'Receiver Name': 'XXXXX', 'Receiver Address': 'PESHAWAR', 'Receiver City': 'PESHAWAR' }] }
  const downloadTemplateName = 'TransactionsTemplate' + new Date().getFullYear().toString() + (new Date().getMonth() + 1).toString().padStart(2, '0') + new Date().getDate().toString().padStart(2, '0') + new Date().getHours().toString().padStart(2, '0') + new Date().getMinutes().toString().padStart(2, '0') + ".xlsx";

  const importFile = () => {

    let userSession = JSON.parse(localStorage.getItem('UserSession')!);

    readFileAsBase64(fileUploaded).then(
      (data: any) => {
        //console.log(data)
        let fileContent = data.replace(/^data:.+;base64,/, '');

        if (!showModalFileUpload) {
          importQuickBooksEntities(fileContent, userSession.PersonID, fileUploaded.name, userSession.CustomerID, quickBookType).then((response) => {
            if (response !== undefined && response !== null) {
              let responseStatus = response.httpStatusCode
              switch (responseStatus) {
                case 200:
                  if (response.ErrorMessage !== '') {
                    let message = getResource('Js_Message_FileImportedSuccessfully');
                    showModal(getResource('Js_Message_SubmitSucessful'), message);
                  } else {
                    showModal(getResource('Js_MessageError_TitleSubmit'), response.ErrorMessage);
                  }
                  break;
                case 409:
                  showModal(getResource('Js_MessageError_TitleFormValidation'), response.httpErrorMessage);
                  break;
                case (responseStatus >= 400 && responseStatus < 500):
                  showModal(getResource('Js_MessageError_TitleSubmit'), response.Message);
                  break;
                case (responseStatus >= 500):
                  showModal(getResource('Js_MessageError_TitleSubmit'), getResource('InternalServerError') + response.Message);
                  break;
                default:
                  showModal(getResource('Js_MessageError_TitleFormValidation'), getResource('SomethingWentWrong'));
                  break;
              }
            }
            //}
          }).finally(() => {
            setShowModalUpload(false);
            setShowModalFileUpload(false);
            setQuickBookType("");
            setFileUploadAccept("");
            setFileUploaded("");
          });
        } else {
          const customerID = JSON.parse(localStorage.getItem('UserSession')!)['CustomerID'];
          saveTransactionUpload(customerID, 'Pending', fileContent, fileUploaded.name).then((response) => {
            if (response !== undefined && response !== null) {
              let responseStatus = response.httpStatusCode
              //debugger;
              switch (responseStatus) {
                case 200:
                  if (response.ErrorMessage !== '') {
                    setShowErrorGrid(false);
                    let message = getResource('Js_Message_FileImportedSuccessfully');
                    showModal(getResource('Js_Message_SubmitSucessful'), message);
                    setReloadGrid(true);
                  } else {
                    showModal(getResource('Js_MessageError_TitleSubmit'), response.ErrorMessage);
                  }
                  break;
                case 409:
                  showModal(getResource('Js_MessageError_TitleFormValidation'), response.httpErrorMessage);
                  break;
                case (responseStatus >= 400 && responseStatus < 500):
                  showModal(getResource('Js_MessageError_TitleSubmit'), response.Message);
                  break;
                case 503:
                  debugger;
                  //showModal(getResource('Js_MessageError_TitleSubmit'), 'Internal Server Error: ' + response.Message);
                  setErrors(response.Errors);
                  var temp = errors;
                  setShowErrorGrid(true);
                  break;
                default:
                  showModal(getResource('Js_MessageError_TitleFormValidation'), getResource('SomethingWentWrong'));
                  break;
              }
            }
          }).finally(() => {
            setShowModalUpload(false);
            setShowModalFileUpload(false);
            setQuickBookType("");
            setFileUploadAccept("");
            setFileUploaded("");
            setReloadGrid(false);
          });
        }
      }
    )
  }

  const readFileAsBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  const onFileChangeHandler = (event: any) => {
    setFileUploaded(event.target.files[0])
  }

  const onVisibleChange = () => {
    setShowModalFileUpload(true);
  }

  const exportQuickBooksData = (qbType: string, extension: string) => {

    let userSession = JSON.parse(localStorage.getItem('UserSession')!);

    getQuickBooksDataForExport(userSession.CustomerID, qbType).then((response) => {
      if (response !== undefined && response !== null) {
        console.log(response);


        let responseStatus = response.httpStatusCode
        switch (responseStatus) {
          case 200:
            let fileName = "quickbook_export_" + qbType + extension
            let csvContent = "data:text/plain;charset=utf-8,"
            let csvDataAsRows = response.data
            let header = ''
            let encodedUri = ''
            let link: HTMLElement

            encodedUri = encodeURI(csvContent + header + csvDataAsRows);
            link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", fileName);
            link.click();
            document.body.appendChild(link); // Required for FF
            break;
          case (responseStatus >= 400 && responseStatus < 500):
            showModal(getResource('Js_MessageError_TitleSubmit'), response.Message);
            break;
          case (responseStatus >= 500):
            showModal(getResource('Js_MessageError_TitleSubmit'), getResource('InternalServerError') + response.Message);
            break;
          default:
            showModal(getResource('Js_MessageError_TitleFormValidation'), getResource('SomethingWentWrong'));
            break;
        }
      }
    }).finally(() => {
      setShowModalUpload(false);
      setShowModalFileUpload(false);
      setQuickBookType("");
      setFileUploadAccept("");
      setFileUploaded("");
    });;

  }

  const errorsColumn: ColumnsType<UploadErrors> = [
    {
      title: getResource('Table_TH_Row'),
      dataIndex: 'row_n',
      key: 'row_n',
      align: 'center',
      sorter: (a, b) => a.row_n.localeCompare(b.row_n)
    },
    {
      title: getResource('Table_TH_Column'),
      dataIndex: 'column_name',
      key: 'column_name',
      align: 'center',
      sorter: (a, b) => a.column_name.localeCompare(b.column_name)
    },
    {
      title: getResource('Table_TH_Error'),
      dataIndex: 'message_error',
      key: 'message_error',
      align: 'center',
      sorter: (a, b) => a.message_error.localeCompare(b.message_error)
    }
  ];

  const renderScreen = () => {
    switch (currentScreen) {
      case 'quickBooks':
        return <div className="row">
          <div className="col-md-6 col-xs-12">
            <div>
              <span>{getResource('Label.ImportVendors')}</span>
              <Tooltip title={getResource('Label.ImportQuickbooksVendors')}>
                <Typography.Link
                  onClick={() => {
                    setShowModalUpload(true);
                    setShowModalFileUpload(false);
                    setQuickBookType("vendor");
                    setFileUploadAccept(".iif");
                    setUpdloadModalTitle(getResource('Label.ImportQuickbooksVendors'));
                  }}
                >
                  <UploadOutlined style={{ fontSize: 18 }} />
                </Typography.Link>
              </Tooltip>
            </div>
            <div>
              <span>{getResource('Label.ImportCustomers')}</span>
              <Tooltip title={getResource('Label.ImportQuickBooksCustomers')}>
                <Typography.Link
                  onClick={() => {
                    setShowModalUpload(true);
                    setShowModalFileUpload(false);
                    setQuickBookType("customer");
                    setFileUploadAccept(".iif");
                    setUpdloadModalTitle(getResource('Label.ImportQuickBooksCustomers'));
                  }}
                >
                  <UploadOutlined style={{ fontSize: 18 }} />
                </Typography.Link>
              </Tooltip>
            </div>
            <div>
              <span>{getResource('Label.ImportVendorBills')}</span>
              <Tooltip title={getResource('Label.ImportQuickBooksBills')}>
                <Typography.Link
                  onClick={() => {
                    setShowModalUpload(true);
                    setShowModalFileUpload(false);
                    setQuickBookType("bill");
                    setFileUploadAccept(".csv");
                    setUpdloadModalTitle(getResource('Label.ImportQuickBooksBills'));
                  }}
                >
                  <UploadOutlined style={{ fontSize: 18 }} />
                </Typography.Link>
              </Tooltip>
            </div>
            <div>
              <span>{getResource('Label.ImportCustomersInvoices')}</span>
              <Tooltip title={getResource('Label.ImportQuickBooksInvoices')}>
                <Typography.Link
                  onClick={() => {
                    setShowModalUpload(true);
                    setShowModalFileUpload(false);
                    setQuickBookType("invoice");
                    setFileUploadAccept(".csv");
                    setUpdloadModalTitle(getResource('Label.ImportQuickBooksInvoices'));
                  }}
                >
                  <UploadOutlined style={{ fontSize: 18 }} />
                </Typography.Link>
              </Tooltip>
            </div>
          </div>
          <div className="col-md-6 col-xs-12">
            <div>
              <span>{getResource('Label.ExportVendors')}</span>
              <Tooltip title={getResource('Label.ExportVendors')}>
                <Typography.Link
                  onClick={() => {
                    exportQuickBooksData("vendor", ".iif");
                  }}
                >
                  <DownloadOutlined style={{ fontSize: 18 }} />
                </Typography.Link>
              </Tooltip>
            </div>
            <div>
              <span>{getResource('Label.ExportCustomers')}</span>
              <Tooltip title={getResource('Label.ExportCustomers')}>
                <Typography.Link
                  onClick={() => {
                    exportQuickBooksData("customer", ".iif");
                  }}
                >
                  <DownloadOutlined style={{ fontSize: 18 }} />
                </Typography.Link>
              </Tooltip>
            </div>
            <div>
              <span>{getResource('Label.ExportVendorBills')}</span>
              <Tooltip title={getResource('Label.ExportVendorBills')}>
                <Typography.Link
                  onClick={() => {
                    exportQuickBooksData("bill", ".iif");
                  }}
                >
                  <DownloadOutlined style={{ fontSize: 18 }} />
                </Typography.Link>
              </Tooltip>
            </div>
            <div>
              <span>{getResource('Label.ExportCustomerInvoices')}</span>
              <Tooltip title={getResource('Label.ExportCustomerInvoices')}>
                <Typography.Link
                  onClick={() => {
                    exportQuickBooksData("invoice", ".iif");
                  }}
                >
                  <DownloadOutlined style={{ fontSize: 18 }} />
                </Typography.Link>
              </Tooltip>
            </div>
          </div>
        </div>;
      case 'uploadFile':
        return <div className="row">
          <div className="row">
            {!showErrorGrid && <div>
              <span onClick={onVisibleChange.bind(this)}>{getResource('FileUpload')}</span>
              <Tooltip title={getResource('FileUpload')} >
                <Typography.Link
                  onClick={() => {
                    setShowModalUpload(true);
                    setShowModalFileUpload(true);
                    setFileUploadAccept(".xlsx");
                    setUpdloadModalTitle(getResource('FileUpload'));
                  }}
                >
                  <UploadOutlined style={{ fontSize: 18 }} />
                </Typography.Link>
              </Tooltip>
              <div>
                {/*{showErrorGrid && <UploadTransactionFileErrors errors={errors} onErrorsClose={onErrorsClose} />}
                {showErrorGrid && <div className="col-md-3 col-xs-12" style={{ paddingTop: 30 }}>
                  <Button type="secondary" onClick={() => setShowErrorGrid(false)}>
                    {getResource('Button.Close')}
                  </Button>
                </div>}
                {showErrorGrid && <Table
                  className="slide-l-r"
                  key={'errors-table'}
                  columns={errorsColumn}
                  dataSource={errors}
                  exportableProps={{
                    fileName: 'Transactions',
                    btnProps: {
                      type: "default",
                      icon: <DownloadOutlined />,
                    }
                  }}
                />}*/}
              </div>
              <UploadFileTable reload={reloadGrid} />
            </div>}
            {showErrorGrid && <div>
              <div>
                <UploadTransactionFileErrors errors={errors} onErrorsClose={onErrorsClose} />
              </div>
            </div>}
          </div>
        </div>;
      default:
        return <div></div>;
    }
  }

  const onChangeScreen = (screen: string) => {
    setCurrentScreen(screen);
    if (screen === 'fileUpload') {
      setShowModalFileUpload(true);
    }
  };

  const onErrorsClose = (error: any, showErrors: boolean) => {
    setErrors(errors);
    setShowErrorGrid(showErrors);
  };

  return (
    <React.Fragment>
      {!showModalFileUpload && <UploadModal showModalUpload={showModalUpload} title={updloadModalTitle} fileUploaded={fileUploaded} accept={fileUploadAccept} setShowModalUpload={setShowModalUpload}
        onFileChangeHandler={onFileChangeHandler} importFile={importFile}>
      </UploadModal>}
      {showModalFileUpload && <UploadModal showModalUpload={showModalUpload} title={updloadModalTitle} fileUploaded={fileUploaded} accept={fileUploadAccept} setShowModalUpload={setShowModalUpload}
        onFileChangeHandler={onFileChangeHandler} importFile={importFile} downloadTemplate={true} downloadTemplateName={downloadTemplateName} downloadTemplateData={downloadTemplateData}>
      </UploadModal>}
      <div className="navbar-horizontal slide-t-b">
        <div className="row">
          <div
            className={currentScreen === 'quickBooks' ? 'col-xs navbar-horizontal-option-selected' : 'col-xs navbar-horizontal-option'}
            onClick={() => {
              onChangeScreen('quickBooks');
            }}
          >
            <span>{getResource('QuickBooks')}</span>
          </div>

          {OL_MSBRole && <div
            className={currentScreen === 'uploadFile' ? 'col-xs navbar-horizontal-option-selected' : 'col-xs navbar-horizontal-option'}
            onClick={() => {
              onChangeScreen('uploadFile');
            }}
          >
            <span>{getResource('Label.UploadFile')}</span>
          </div>}
        </div>
      </div>
      {renderScreen()}
    </React.Fragment>
  );
};

export default QuickBooks;
