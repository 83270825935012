import { DollarCircleOutlined, DownloadOutlined } from "@ant-design/icons";
import { Table } from "ant-table-extensions";
import { AutoComplete, Collapse, Tooltip, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useEffect, useState } from 'react';
import { getOnlineBeneficiaries } from '../../../api/NetworkingBeneficiaries';
import { getCountryByName } from "../../../api/NetworkingCountries";
import { getResource } from "../../../helpers/ResourcesHelper";
import Button from '../../inputs/Button';
import { Beneficiary } from '../../models/Beneficiary';

const { Panel } = Collapse;

const BeneficiaryPaymentsTable = (props: any) => {

  const [beneficiaries, setBeneficiaries] = useState([]);
  const userSession = JSON.parse(localStorage.getItem('UserSession')!);
  const [updating, setUpdating] = useState(false);
  const [beneficiaryName, setBeneficiaryName] = useState('');
  const [country, setCountry] = useState('all');
  const [countries, setCountries] = useState([
    { value: 'all', label: getResource('All') }
  ]);

  const selectRow = (record: any) => {
    props.selectRow(record.BeneficiaryID);
  };

  const columns: ColumnsType<Beneficiary> = [
    {
      title: getResource('Js_TH_Beneficiary'),
      dataIndex: 'BeneficiaryName',
      key: 'BeneficiaryName',
      align: 'center',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.BeneficiaryName.localeCompare(b.BeneficiaryName)
    },
    {
      title: getResource('Js_TH_Country'),
      dataIndex: 'CountryName',
      key: 'CountryName',
      align: 'center',
      sorter: (a, b) => a.CountryName.localeCompare(b.CountryName)
    },
    {
      title: getResource('Js_TH_Status'),
      dataIndex: 'Status',
      key: 'Status',
      align: 'center',
      sorter: (a, b) => a.Status.localeCompare(b.Status)
    },
    {
      title: getResource('Table_TH_Actions'),
      dataIndex: 'actions',
      key: 'actions',
      align: 'left',
      render: (value: any, obj: any, index: any) => (
        <>
          <Tooltip title={getResource('Js_Link_ViewPayments')}>
            <Typography.Link
              onClick={() => {
                selectRow(obj);
              }}
            >
              <DollarCircleOutlined />
            </Typography.Link>
          </Tooltip>
        </>
      )
    }
  ];


  const loadBeneficiaries = () => {
    if (userSession != null && userSession.CustomerID > 0) {
      getOnlineBeneficiaries(userSession.CustomerID).then((response) => {
        loadCountries(response.Beneficiaries);
        setBeneficiaries(response.Beneficiaries);
        applyFilters(response.Beneficiaries);
      });
    }
  }

  useEffect(() => {
    loadBeneficiaries();
  }, [updating]);

  const onChangeBeneficiaryName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBeneficiaryName(event.target.value);
  };

  const dropdownCountryChangeHandler = (item: any, option: any) => {
    if (option != null && !option.label.includes('Select')) {
      setCountry(option.label);
    }
  };
  const onSearch = (value: any) => {
    setCountry(value);
    let newValue = value.split(' -');
    getCountryByName(newValue[0]).then(
      (json) => {
        if (json.countries != null) {
          const formattedCountries = json.countries.map((c: any) => ({ ...c, label: c.CountryIdName, value: c.CountryId }));
          //formattedCountries.push({ label: 'Select a Country...', value: '' });
          setCountries(formattedCountries);
        }
      }
    )
  }
  const loadCountries = (beneficiaries: []) => {
    var countries = [{ value: 'all', label: getResource('All') }];
    beneficiaries.map((item: typeof Beneficiary) => {
      var findItem = countries.find(x => x.value == item.CountryName);
      if (!findItem)
        countries.push({ value: item.CountryName, label: item.CountryName });
    });

    setCountries(countries);
  }

  const applyFilters = (beneficiaries: any) => {
    var beneficiariesAux = beneficiaries;
    if (country != 'all' && country != '') {
      beneficiariesAux = beneficiariesAux.filter((item: typeof Beneficiary) => item.CountryName == country);
    }
    if (beneficiaryName != '') {
      beneficiariesAux = beneficiariesAux.filter((item: typeof Beneficiary) => item.BeneficiaryName.indexOf(beneficiaryName) > -1);
    }

    setBeneficiaries(beneficiariesAux);
  }

  const onResetFilters = () => {
    setBeneficiaryName('');
    setCountry('all');
    setUpdating(!updating);
  };

  return (
    <React.Fragment>
      <Collapse defaultActiveKey={['1']} style={{ marginBottom: '15px' }}>
        <Panel header={'Filter Options'} key="1">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-3 col-xs-12">
                <div className="row">
                  <span className="input-label">{getResource('Js_TH_Beneficiary')}: </span>
                </div>
                <div className="row">
                  <input id="beneficiaryName" value={beneficiaryName} onChange={onChangeBeneficiaryName} type="text" />
                </div>
              </div>
              <div className="col-md-3 col-xs-12">
                <div className="row">
                  <span className="input-label">{getResource('Js_TH_Country')}:</span>
                </div>
                <div className="row">
                  {/* <Dropdown
                    name="countryFilter"
                    title="Select Country..."
                    list={countries}
                    onChange={dropdownCountryChangeHandler}
                    //submit={true}
                    select={{ value: country }}
                  /> */}
                  <AutoComplete
                    allowClear
                    options={countries}
                    placeholder={getResource('SelectCountry')}
                    notFoundContent={getResource('PlaceHolder.NoResults')}
                    value={country}
                    onSelect={dropdownCountryChangeHandler}
                    onSearch={onSearch}
                  />
                </div>
              </div>
              <div className="col-md-2 col-xs-12" style={{ paddingTop: 30 }}>
                <Button type="secondary" onClick={() => setUpdating(!updating)}>
                  {getResource('ApplyFilter')}
                </Button>
              </div>
              <div className="col-md-2 col-xs-12" style={{ paddingTop: 30 }}>
                <Button type="primary" onClick={onResetFilters}>
                  {getResource('Reset')}
                </Button>
              </div>
            </div>
          </div>
        </Panel>
      </Collapse>
      <Table
        className="slide-l-r"
        key={'transactions-table'}
        columns={columns}
        dataSource={beneficiaries}
      /*onRow={(record, rowIndex) => ({
        onClick: () => {
          selectRow(record);
        }
      })}*/
        exportableProps={{
          fileName: 'BeneficiaryPayments',
          btnProps: {
            type: "default",
            icon: <DownloadOutlined />,
          }
        }}
      />
    </React.Fragment>
  );
}

export default BeneficiaryPaymentsTable;