import React from 'react';

interface RadioButtonProps {
  className?: string;
  changed: (event: any) => void;
  value: string;
  isSelected: boolean;
  id: string;
  label: string;
  name?: string;
}

const RadioButton: React.FC<RadioButtonProps> = (props: any) => {
  return (
    <div className={props.className ? props.className : 'RadioButton'}>
      <input id={props.id} onChange={props.changed} value={props.value} type="radio" checked={props.isSelected} name={props.name} />
      <label htmlFor={props.id}>{props.label}</label>
    </div>
  );
};

export default RadioButton;