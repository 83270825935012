import { genericCallWithBody, genericGetWithParameters } from './Networking';

export async function getBankAccountsByAccountID(bankAccountID: number) {
  try {
    let params = {
      bankAccountID: bankAccountID
    };
    let response = await genericGetWithParameters('BankAccount/GetBankAccountsByAccountID', params);
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function getBankAccountsByPerson_min(personID: any) {
  try {
    let params = {
      personID: personID
    };
    let response = await genericGetWithParameters('BankAccount/GetBankAccountsByPerson_min', params);
    return response;
  } catch (error) {
    console.error(error);
  }
}

export const getListOfCustomerBankAccounts = async (currencyID: string, customerID: number) => {
  try {
    const params = {
      CurrencyID: currencyID,
      CustomerID: customerID
    };
    const response = await genericGetWithParameters('BankAccount/GetListOfCustomerBankAccounts', params);
    return response;
  } catch (error) {
    console.error(error);
  }
};

export async function validateIBAN(iban: string) {

  let model = {
    IBAN: iban,
    UpdatedBy: localStorage.getItem('UserID')
  }
  try {
    let response = await genericCallWithBody('POST', 'BankAccount/ValidateIBAN', model);
    return response;
  } catch (error) {
    console.error(error);
  }
}
