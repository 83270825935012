import { PaperClipOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import moment from 'moment';
import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import { saveInvoices } from '../../api/NetworkingInvoice';
import AppContext from '../../App.context';
import { formatDate, formatValidInputClass, scaleByCurrencyPair } from '../../helpers/FormatHelper';
import { getResource } from '../../helpers/ResourcesHelper';
import AttachmentsForm from '../attachments/AttachmentsForm';
import Button from '../inputs/Button';
import DatePicker from '../inputs/DatePicker';
import Dropdown from '../inputs/Dropdown';
import Input from '../inputs/Input';
const dateFormat = 'YYYY/MM/DD';

interface InvoiceAddEditFormDialogProps {
  InvoiceType: string;
  settlementCurrencies: any[];
  onClose: () => void;
  beneficiaries: any[];
  changeState?: Function;
}

const InvoiceAddEditFormDialog: FunctionComponent<InvoiceAddEditFormDialogProps> = (props) => {
  const { showModal, showLoadingModal, hideLoadingModal } = useContext(AppContext);
  const [invoiceNumber, setInvoiceNumber] = useState('');
  const [invoiceDate, setInvoiceDate] = useState(moment(new Date(), dateFormat).format(dateFormat))
  const [invoiceDescription, setInvoiceDescription] = useState('');
  const [amount, setAmount] = useState(0);
  const [currencyId, setCurrencyId] = useState('');
  const [taxes, setTaxes] = useState(0);
  const [total, setTotal] = useState(0);
  const [senderName, setSenderName] = useState('');
  const [customerEmail, setCustomerEmail] = useState('');
  const [invoiceLink, setInvoiceLink] = useState('');
  const [fileName, setFileName] = useState('');
  const [fileContent, setFileContent] = useState([]);
  const [showUpload, setShowUpload] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [beneficiaryId, setBeneficiaryId] = useState(0);
  const [validations, setValidations] = useState({
    invoiceNumber: true,
    invoiceDate: true,
    invoiceDescription: true,
    amount: true,
    currencyId: true,
    taxes: true,
    senderName: true,
    terms: true,
    beneficiaryId: true
  });
  const [terms, setTerms] = useState(0);
  const [availableCurrencies, setAvailableCurrencies] = useState([]);
  const [scaleAmount, setScaleAmount] = useState(2);

  //-----------For Render------------
  const [InvoiceTypeControls, setInvoiceTypeControls] = useState('');

  useEffect(() => {
    //if (props.InvoiceType === 'Receivable') {
      setAvailableCurrencies(props.settlementCurrencies as any);
    //}
    isRender();
  }, [props.InvoiceType])

  useEffect(() => {
    isRender();
    setScaleAmount(scaleByCurrencyPair('', ''));
  }, [senderName, customerEmail])

  const onClickExit = () => {
    props.onClose();
  }

  const clearData = () => {
    setInvoiceNumber('');
    setInvoiceDate(moment(new Date(), dateFormat).format(dateFormat));
    setInvoiceDescription('');
    setAmount(0);
    setCurrencyId('');
    setTaxes(0);
    setTotal(0);
    setSenderName('');
    setCustomerEmail('');
    setInvoiceLink('');
    setFileName('');
    setFileContent([]);
    setShowUpload(false);
    setBeneficiaryId(0);
    setTerms(0);
    setValidations({
      invoiceNumber: true,
      invoiceDate: true,
      invoiceDescription: true,
      amount: true,
      currencyId: true,
      taxes: true,
      senderName: true,
      terms: true,
      beneficiaryId: true
    });
    setShowConfirmation(false);
    setAvailableCurrencies([]);
  }

  const handleInvoiceNumber = (event: any) => {
    setInvoiceNumber(event.target.value);
  }

  const handleUpdateInvoiceDate = (date: any) => {
    setInvoiceDate(date);
  }

  const handleInvoiceDescription = (event: any) => {
    setInvoiceDescription(event.target.value);
  }

  const handleGetCurrency = (obj: any) => {
    if (obj != null && obj.value != null) {
      setCurrencyId(obj.value);
      setScaleAmount(obj.numberOfDecimals);
    }
  }

  const handleGetBeneficiary = (obj: any) => {
    if (obj != null && obj.value != null) {
      var beneCurrencies = [] as any;
      var currencies = props.settlementCurrencies;
      var auxAvailableCurrencies = [] as any;
      if (obj.Currencies != null && obj.Currencies !== '') {
        beneCurrencies = obj.Currencies.replace('(', '').replace(')', '').split(',');

        currencies.forEach((element: any) => {
          var ccy = beneCurrencies.findIndex((ccy: any) => element.value === ccy);
          if (ccy >= 0) {
            auxAvailableCurrencies.push(element);
          }
        });
      }
      setBeneficiaryId(obj.value)
      setAvailableCurrencies(auxAvailableCurrencies);
    }
  }

  const handleInvoiceAmount = (value: number) => {
    var auxTotal = taxes + value;
    setAmount(value);
    setTotal(auxTotal);
  }

  const handleInvoiceTerms = (value: number) => {
    setTerms(value);
  }

  const handleInvoiceTaxes = (value: number) => {
    var auxTotal = amount + value;
    setTaxes(value);
    setTotal(auxTotal);
  }

  const handleSenderName = (event: any) => {
    setSenderName(event.target.value);
  }

  const handleCustomerEmail = (event: any) => {
    setCustomerEmail(event.target.value);
  }

  const handleInvoiceLink = (event: any) => {
    setInvoiceLink(event.target.value);
  }

  const onAttachmentsClick = () => {
    setShowUpload(true);
  }

  const onCloseAttachmentsForm = () => {
    setShowUpload(false);
  }

  const onUploadFile = (attachmentID: string, filename: string, description: any, fileContent: any) => {
    //(attachmentID, fileName, description) => {
    //console.log('--attachmentID -> ' + { attachmentID } + '--fileName' + fileName + '--description' + description);
    if (attachmentID != null) {
      hideLoadingModal();
      setShowUpload(false);
      setFileName(filename);
      setFileContent(fileContent);
    }
  }

  const handleSaveClick = () => {
    if (validate()) {
      setShowConfirmation(true);
    } else {
      showModal(getResource('Js_MessageError_TitleError'), getResource('Js_MessageError_FormValidationNFieldNHighlighted'));
    }
  }

  const resetValidations = (validations: any) => {
    validations.invoiceNumber =
      validations.invoiceDate =
      validations.invoiceDescription =
      validations.amount =
      validations.currencyId =
      validations.taxes =
      validations.senderName =
      validations.beneficiaryId =
      validations.terms = true;
    return validations;
  }

  const validate = () => {
    var result = true;
    var tempValidations = validations;
    tempValidations = resetValidations(tempValidations);

    if (invoiceNumber === null || invoiceNumber === undefined || invoiceNumber === '') {
      result = false;
      tempValidations.invoiceNumber = false;
    }

    if (invoiceDate === null || invoiceDate === undefined || invoiceDate === '') {
      result = false;
      tempValidations.invoiceDate = false;
    }

    if (invoiceDescription === null || invoiceDescription === undefined || invoiceDescription === '') {
      result = false;
      tempValidations.invoiceDescription = false;
    }

    if (amount === null || amount === undefined || amount === 0) {
      result = false;
      tempValidations.amount = false;
    }

    if (currencyId === '' || currencyId === 'select') {
      result = false;
      tempValidations.currencyId = false;
    }

    if (taxes === null || taxes === undefined) {
      result = false;
      tempValidations.taxes = false;
    }

    if (props.InvoiceType === 'Receivable' && (senderName === undefined || senderName === '')) {
      result = false;
      tempValidations.senderName = false;
    }

    if (props.InvoiceType === 'Payable' && (beneficiaryId === undefined || beneficiaryId === 0)) {
      result = false;
      tempValidations.senderName = false;
    }

    if (terms === undefined) {
      result = false;
      tempValidations.terms = false;
    }
    setValidations(tempValidations);

    return result;
  }

  const closeAlert = () => {
    setShowConfirmation(false);
  }

  const yesAlert = () => {
    //setState({ showConfirmation: false });
    showLoadingModal();
    const userSession = JSON.parse(localStorage.getItem('UserSession')!);
    var userID = localStorage.getItem('UserID');
    var customerId = userSession.CustomerID;
    var invoices = [];
    var date = formatDate(invoiceDate);
    invoices.push({
      CustomerId: customerId,
      InvoiceNumber: invoiceNumber,
      InvoiceDate: date,
      InvoiceDescription: invoiceDescription,
      Amount: amount,
      CurrencyId: currencyId,
      Taxes: taxes,
      Total: total,
      ExternalCustomerName: senderName,
      ExternalCustomerEmail: customerEmail,
      InvoiceLink: invoiceLink,
      CreatedBy: userID,
      FileName: fileName,
      FileContent: fileContent,
      InvoiceType: props.InvoiceType,
      Terms: terms,
      BeneficiaryID: beneficiaryId
    });


    var model = { Invoices: invoices };

    saveInvoices(model).then(
      (response) => {
        if (typeof response !== undefined && response.httpStatusCode === 200) {
          hideLoadingModal();
          showModal(getResource('Js_MessageError_TitleSuccess'), getResource('Js_Message_SaveSuccessfully'));
          clearData();
          onClickExit();
        }
        else {
          hideLoadingModal();
          showModal(getResource('Js_MessageError_TitleError'), getResource('Something went wrong. Please try again later.'));
        }
      });
  }

  const isRender = () => {
    var auxInvoiceTypeControls = '' as any;

    if (props.InvoiceType === 'Receivable') {
      auxInvoiceTypeControls =
        <div className="row middle-xs start-xs">
          <div className="col-md-12 col-xs-12 row middle-xs start-xs">
            <div className="row">
              {/* CUSTOMER NAME */}
              <div className="col-md-2 col-xs-12 row">
                <div className="row">
                  <span>{getResource('Label.InvoiceSenderName')}</span>
                </div>
              </div>
              <div className="col-md-4 col-xs-12">
                <div className="row">
                  <input type="text" name="f-mbi-invocesender" id="f-mbi-invocesender"
                  maxLength={150} className={formatValidInputClass(validations.senderName)}
                    value={senderName} onChange={handleSenderName}
                  />
                </div>
              </div>
              {/* CUSTOMER EMAIL */}
              <div className="col-md-2 col-xs-12 row">
                <div className="row end-xs">
                  <span>{getResource('Label.InvoiceSenderEmail')}</span>
                </div>
              </div>
              <div className="col-md-4 col-xs-12">
                <div className="row">
                  <input type="text" name="f-mbi-invoceemail" id="f-mbi-invoceemail"
                  maxLength={75}
                    value={customerEmail} onChange={handleCustomerEmail}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>;
    }
    else {
      auxInvoiceTypeControls =
        <div className="row middle-xs start-xs">
          {/* BENEFICIARY NAME */}
          <div className="col-md-12 col-xs-12 row middle-xs start-xs">
            <div className="row">
              <div className="col-md-2 col-xs-12 row">
                <div className="row">
                  <span>{getResource('Label.BeneficiaryName')}</span>
                </div>
              </div>
              <div className="col-md-10 col-xs-12">
                <div className="row">
                  <Dropdown
                  className={formatValidInputClass(validations.beneficiaryId)}
                    name="f-mbi-invoceCCY"
                    title={getResource('SelectBeneficiary')}
                    list={props.beneficiaries}
                    select={{ value: beneficiaryId }}
                    onChange={handleGetBeneficiary}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>;
    }
    setInvoiceTypeControls(auxInvoiceTypeControls);

  }

  const getDate = (dateString: string) => {
    if (dateString === '') {
      return undefined;
    }
    const date = moment(dateString, dateFormat);
    return date && date.isValid() ? date : undefined;
  };

  return (
    <div>
      <Modal
        title={<h3 className="component-title">{props.InvoiceType + " Invoice"}</h3>}
        centered
        visible={true}
        footer={null}
        //onCancel={props.changeState()}
        maskClosable={false}
        width={'60%'}
        zIndex={1000}
        closable={false}
      >
        <div>
          <div>
            {InvoiceTypeControls}

            <div className="row middle-xs start-xs">
              <div className="col-md-12 col-xs-12 row middle-xs start-xs">
                <div className="row">
                  {/* INVOICE NUMBER */}
                  <div className="col-md-2 col-xs-12 row">
                    <div className="row">
                      <span>{getResource('Label.InvoiceNumber')}</span>
                    </div>
                  </div>
                  <div className="col-md-4 col-xs-12">
                    <div className="row">
                      <input type="text" name="f-mbi-invoceNumber" id="f-mbi-invoceNumber"
                        maxLength={25} className={formatValidInputClass(validations.invoiceNumber)}
                        value={invoiceNumber} onChange={handleInvoiceNumber}
                      />
                    </div>
                  </div>

                  {/* INVOICE DATE */}

                  <div className="col-md-2 col-xs-12 row">
                    <div className="row end-xs">
                      <span>{getResource('Label.InvoiceDate')}</span>
                    </div>
                  </div>
                  <div className="col-md-4 col-xs-12">
                    <div className="row">
                      <DatePicker
                        id="f-ph-view-range-show-from"
                        key="f-ph-view-range-show-from"
                        className={formatValidInputClass(validations.invoiceDate)}
                        onChange={handleUpdateInvoiceDate}
                        value={getDate(invoiceDate)}
                        valueValidation={invoiceDate}
                      //disabledDays={[0, 6]}
                      //readOnly={true}
                      />
                    </div>
                  </div>
                </div>
              </div>


              <div className="col-md-12 col-xs-12 row middle-xs start-xs">
                <div className="row">
                  {/* TERMS */}
                  <div className="col-md-2 col-xs-12 row">
                    <div className="row">
                      <span>{getResource('Label.InvoiceTerms')}</span>
                    </div>
                  </div>
                  <div className="col-md-4 col-xs-12">
                    <div className="row">
                      <Input
                        type="number"
                        className={formatValidInputClass(validations.terms)}
                        value={terms}
                        onValueChange={handleInvoiceTerms}
                        decimalScale={0}
                      //onBlur={onBlurAmount}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* SECTION AMOUNT */}
              <div className="col-md-12 col-xs-12 row middle-xs center-xs">
                {/* DESCRIPTION */}
                <div className="col-md-6 col-xs-12">
                  <label className="input-required">{getResource('Description')}</label>
                </div>
                {/* AMOUNT */}
                <div className="col-md-3 col-xs-12">
                  <label className="input-required">{getResource('Amount')}</label>
                </div>
                {/* CURRENCY */}
                <div className="col-md-3 col-xs-12">
                  <label className="input-required">{getResource('Label.Currency')}</label>
                </div>
              </div>
            </div>

            <hr style={{ marginTop: '0px' }}></hr>
            <div className="row middle-xs start-xs">
              <div className="col-md-12 col-xs-12 row middle-xs start-xs">
                {/* DESCRIPTION */}
                <div className="col-md-6 col-xs-12">
                  <div className="row">
                    <input type="text" name="f-mbi-invoceDes" id="f-mbi-invoceDes"
                      maxLength={150} className={formatValidInputClass(validations.invoiceDescription)}
                      value={invoiceDescription} onChange={handleInvoiceDescription}
                    />
                  </div>
                </div>
                {/* AMOUNT */}
                <div className="col-md-3 col-xs-12">
                  <div className="row">
                    <Input
                      type="number"
                      className={formatValidInputClass(validations.amount)}
                      value={amount}
                      onValueChange={handleInvoiceAmount}
                      decimalScale={scaleAmount}
                    //onBlur={onBlurAmount}
                      placeholder={'0.00000'}
                    />
                  </div>
                </div>
                {/* CURRENCY */}
                <div className="col-md-3 col-xs-12">
                  <div className="row">
                    <Dropdown
                      className={formatValidInputClass(validations.currencyId)}
                      name="f-mbi-invoceCCY"
                      title={getResource('SelectCurrency')}
                      list={availableCurrencies}
                      select={{ value: currencyId }}
                      onChange={handleGetCurrency}
                    />
                  </div>
                </div>

              </div>
            </div>

            <hr></hr>
            <div className="row middle-xs start-xs">
              <div className="col-md-12 col-xs-12 row middle-xs">
                {/* TAXES */}
                <div className="col-md-6 col-xs-12">
                  <div className="row end-xs">
                    <span>{getResource('Label.InvoiceTaxes')}</span>
                  </div>
                </div>
                <div className="col-md-3 col-xs-12">
                  <Input
                    type="number"
                    value={taxes}
                    onValueChange={handleInvoiceTaxes}
                    decimalScale={scaleAmount}
                  //onBlur={onBlurAmount}
                    placeholder={'0.00000'}
                  />
                </div>
              </div>

              <div className="col-md-12 col-xs-12 row middle-xs">

                {/* TAXES */}
                <div className="col-md-6 col-xs-12">
                  <div className="row end-xs">
                    <span>{getResource('Label.InvoiceTotal')}</span>
                  </div>
                </div>
                <div className="col-md-3 col-xs-12">
                  <Input
                    type="number"
                    value={total}
                    //onChange={handleInvoiceTaxes}
                    //onBlur={onBlurAmount}
                    disabled={true}
                    decimalScale={scaleAmount}
                    placeholder={'0.00000'}
                  />
                </div>
              </div>
            </div>

            <hr></hr>

            <div className="row middle-xs start-xs">
              <div className="col-md-12 col-xs-12 row middle-xs">
                {/* INVOICE LINK */}
                <div className="col-md-2 col-xs-12">
                  <div className="row">
                    <span>{getResource('Label.InvoiceLink')}</span>
                  </div>
                </div>
                <div className="col-md-10 col-xs-12">
                  <input type="text" name="f-mbi-invocelink" id="f-mbi-invocelink"
                    maxLength={150}
                    value={invoiceLink} onChange={handleInvoiceLink}
                  />
                </div>
              </div>

              <div className="col-md-12 col-xs-12 row middle-xs">
                {/* INVOICE ATTACHMENT */}
                <div className="col-md-2 col-xs-12">
                  <div className="row">
                    <span>{getResource('Label.InvoiceAttachment')}</span>
                  </div>
                </div>
                <div className="col-md-10 col-xs-12">
                  <PaperClipOutlined onClick={onAttachmentsClick} />
                  <span className="fnt-size-08">{fileName}</span>
                </div>
              </div>
            </div>


            <div className="row middle-xs end-xs">
              <div className="col-xs-12 col-md-3">
                <div className="col">
                  <Button id="exit-button" type="secondary" onClick={onClickExit}>
                    {getResource('Btn_Exit')}
                  </Button>
                </div>
              </div>
              <div className="col-xs-12 col-md-3">
                <div className="col">
                  <Button id="exit-button" type="primary" onClick={handleSaveClick}>
                    {getResource('Button.Save')}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {showConfirmation ?
        <Modal
          title={<h3 className="component-title">{'Confirm'}</h3>}
          centered
          visible={showConfirmation}
          footer={null}
          //onCancel={props.changeState()}
          maskClosable={false}
          width={'25%'}
          zIndex={1010}
          closable={false}
        >
          <div>
            <div>
              <h4>{getResource('AreYouSureYouWantToSave')}</h4>
            </div>
            <div className="row">
              <div className="col-xs-12 col-md-6">
                <div className="col">
                  <Button id="confirmation-afex-OK" type="primary" onClick={yesAlert}>
                    {getResource('Yes')}
                  </Button>
                </div>
              </div>
              <div className="col-xs-12 col-md-6">
                <div className="col">
                  <Button id="confirmation-afex-cancel" type="primary" onClick={closeAlert}>
                    {getResource('No')}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Modal> : null}
      <AttachmentsForm
        key={'orders-disbursement-attachment-form'}
        id={'orders-disbursement-attachment-form'}
        show={{ id: '', show: showUpload }}
        onClose={onCloseAttachmentsForm}
        useLinkID={false}
        onUploadWithoutLink={onUploadFile}
        showList={false}
      />
    </div>
  );
}
export default InvoiceAddEditFormDialog;