import jwt from 'jsonwebtoken';
import { getResource } from '../helpers/ResourcesHelper';

//global extend
declare global {
    interface Window {
        REACT_APP_API_URL: string;
        REACT_APP_API_KEY: string;
        REACT_APP_AFEX_REGISTRATIONFORM_URL: string;
        REACT_APP_REGISTRATIONFORM_URL: string;
        REACT_APP_TITLE: string;
        REACT_APP_SESSION_TICK: number;
        REACT_APP_COMPANY: string;
        REACT_APP_COMPANY_PRIVACY_URL: string;
    }
}

const apiURL = window.REACT_APP_API_URL;
const apiKey = window.REACT_APP_API_KEY;

export async function genericGetWithParameters(apiName:string, params:any) {
    try {
        let sessionID = await localStorage.getItem('SessionID');

        let query = Object.keys(params)
            .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
            .join('&');  
        let response = await fetch(apiURL + apiName + '?' + query, {
            method: 'GET',
            headers: {
                'SessionID': sessionID!,
                'APIKey': apiKey!,
            },
        });
        
        if(response.status === 200)
        {
            let responseJWT = await response.json();
            var decoded = jwt.decode(responseJWT.data);
            console.group(apiName)
            console.log(decoded);
            console.groupEnd();
            //@ts-ignore
            let responseJSON = decoded.response;
            responseJSON.httpStatusCode = response.status;
            return responseJSON;
        }else{
            let responseJSON = await response.json();
            responseJSON.httpStatusCode = response.status;
            let httpErrorMessage = '';
            switch(response.status)
            {
                case 200:
                    break;
                case 409:
                    httpErrorMessage = 'This record has been updated by someone else. Please reload the information and try again.';
                    break;
                case 412:
                    httpErrorMessage = 'Sorry we cannot process your transaction at this time. Please try again later.';
                    break;
                default:
                    httpErrorMessage = 'An error occurred. Please try again.';
                    break;
            }
            responseJSON.httpErrorMessage = httpErrorMessage;
            return responseJSON;
        }
    } catch (error) {
        console.error(error);
    }
}

export async function genericCallWithBodyAndResponseDecoded(method:string, apiName:string, model:any){
    try {
        let sessionID = await localStorage.getItem('SessionID'); 
        let response = await fetch(apiURL + apiName, {
            method: method,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'SessionID': sessionID!,
                'APIKey': apiKey!,
            },
            body: JSON.stringify(model),
        });
        console.log(response)
        let responseJWT = await response.json();
        let responseJSON

        console.group(apiName)

        if(responseJWT.data !== undefined) {
            let decoded = jwt.decode(responseJWT.data);
            console.groupEnd();
            //@ts-ignore
            responseJSON = decoded.response;
        }
        else {
            responseJSON = responseJWT;
        }
        //console.log(responseJSON)
        responseJSON.httpStatusCode = response.status;
        let httpErrorMessage = '';
        switch(response.status)
        {
            case 200:
                break;
            case 409:
                httpErrorMessage = 'This record has been updated by someone else. Please reload the information and try again.';
                break;
            case 412:
                httpErrorMessage = 'Sorry we cannot process your transaction at this time. Please try again later.';
                break;
            default:
                httpErrorMessage = 'An error occurred. Please try again.';
                break;
        }
        responseJSON.httpErrorMessage = httpErrorMessage;
        return responseJSON;

    } catch (error) {
        console.error(error);
    }
}

export async function genericCallWithBody(method:string, apiName:string, model:any){
    try {
        let sessionID = await localStorage.getItem('SessionID'); 
        let response = await fetch(apiURL + apiName, {
            method: method,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'SessionID': sessionID!,
                'APIKey': apiKey!,
            },
            body: JSON.stringify(model),
        });
        console.log(response);
        let responseJson = await response.json();
        responseJson.httpStatusCode = response.status;
        var httpErrorMessage = undefined;
        switch(response.status)
        {
            case 409:
                httpErrorMessage = getResource('Js_MessageError_Concurrency'); 
                break;
            case 412:
                httpErrorMessage = 'Sorry we cannot process your transaction at this time. Please try again later.';
                break;
            default:
                break;
        }
        responseJson.httpErrorMessage = httpErrorMessage;
        return responseJson;
    } catch (error) {
        console.error(error);
    }
}

export async function genericDeleteWithParameters(apiName:string, params:any){
    try {
        let sessionID = await localStorage.getItem('SessionID');

        let query = Object.keys(params)
            .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
            .join('&');  
        let response = await fetch(apiURL + apiName + '?' + query, {
            method: 'DELETE',
            headers: {
                'SessionID': sessionID!,
                'APIKey': apiKey!,
            },
        });
        let responseJson = await response.json();
        responseJson.httpStatusCode = response.status;
        return responseJson;
    } catch (error) {
        console.error(error);
    }
}

export async function genericGetWithParametersNoEncrypted(apiName:string, params:any){
    try {
        let sessionID = await localStorage.getItem('SessionID');
        let query = '';
        if(params !== {}){
            query = Object.keys(params)
                .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
                .join('&');
        } 
        let response = await fetch(apiURL + apiName + '?' + query, {
            method: 'GET',
            headers: {
                'SessionID': sessionID!,
                'APIKey': apiKey!,
            },
        });
        let jsonResponse = await response.json();
        jsonResponse.httpStatusCode = response.status;
        var httpErrorMessage = '';
        switch(Number(response.status)){
            case 409:
                httpErrorMessage = getResource('Js_MessageError_Concurrency'); 
                break;
            case 412:
                httpErrorMessage = 'Sorry we cannot process your transaction at this time. Please try again later.';
                break;
            default:
                break;
        }
        jsonResponse.httpErrorMessage = httpErrorMessage;
        return jsonResponse;
    } catch (error) {
        console.error(error);
    }
}

export async function genericCallWithOutBody(apiName:string, params:any){
    try {
        let sessionID = await localStorage.getItem('SessionID');

        let query = Object.keys(params)
            .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
            .join('&');  
        let response = await fetch(apiURL + apiName + '?' + query, {
            method: 'POST',
            headers: {
                'SessionID': sessionID!,
                'APIKey': apiKey!,
            },
        });
        let responseJson = await response.json();
        responseJson.httpStatusCode = response.status;
        return responseJson;
    } catch (error) {
        console.error(error);
    }
}

export async function validateSession(){
    try {
        let response = await genericGetWithParametersNoEncrypted('Session/Get',{});
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getServerVersion() {
    let responseWeb = await fetch(apiURL + 'Version/GetOnlineVersion', {
            method: 'GET'
        });
    let response = await responseWeb.json();
    response.httpStatusCode = responseWeb.status;
    return response;  
}