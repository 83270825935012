import { Modal } from 'antd';
import { useExcelDownloder } from 'react-xls';
import { getResource } from '../../helpers/ResourcesHelper';
import Button from '../inputs/Button';

const UploadModal = (props: any) => {

  const { title, showModalUpload, fileUploaded, accept, setShowModalUpload, onFileChangeHandler, importFile, downloadTemplate, downloadTemplateName, downloadTemplateData }: {
    title: string, showModalUpload: boolean, fileUploaded: boolean, accept: string, downloadTemplate: boolean, downloadTemplateName: string, downloadTemplateData: []
    setShowModalUpload: (b: boolean) => void, onFileChangeHandler: any, importFile: any
  } = props

  const { ExcelDownloder, Type } = useExcelDownloder();

  return <Modal
    title={
      <h3 className="component-title">{title}</h3>}
    centered
    visible={showModalUpload}
    footer={null}
    onCancel={() => { setShowModalUpload(false); }}
    width={'50%'}
    zIndex={1020}
  >
    {/* <Dragger
            {...uploadProps}           >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Click or drag file to this area to upload</p>
          </Dragger> */}
    <div className="row center-md center-xs">
      <div className="col-md-9 col-xs-12 center-md">
        <input type="file" name="file" onChange={onFileChangeHandler} accept={accept} />
      </div>
      {downloadTemplate && <div className="col-md-3 col-xs-12 center-md">
        <ExcelDownloder
          data={downloadTemplateData}
          filename={downloadTemplateName}
          type={Type.Link}>
          {getResource('SubMenu_DownloadTemplateForOrders')}
        </ExcelDownloder>
      </div>}
    </div>

    <div className="row center-md center-xs">
      <div className="col-md-4 col-xs-12 center-md">
        <Button
          type="secondary"
          onClick={() => { setShowModalUpload(false); }}
        >{getResource('Button.Cancel')}</Button>
      </div>
      <div className="col-md-4 col-xs-12 center-md">
        <Button
          type={fileUploaded !== undefined ? "primary" : 'disable'}
          onClick={importFile}
        >{getResource('Upload')}</Button>
      </div>
    </div>
  </Modal>
}

export default UploadModal