import React, { FunctionComponent, useEffect, useState } from "react";
import useOnlineRights from "../../../api/hooks/useOnlineRights";
import { OnlineRightsNames } from "../../../helpers/OnlineRightsHelper";
import { getResource } from "../../../helpers/ResourcesHelper";


interface NavBarProps {
    onChangeScreen: Function;
    currentScreen: string;
}

const NavBar: FunctionComponent<NavBarProps> = (props) => {

    const [selectedScreen, setSelectedScreen] = useState('dashboard');

    useEffect(() => {
        setSelectedScreen(props.currentScreen);
    })

    let userSession = JSON.parse(localStorage.getItem('UserSession')!);
    let OL_RatesSection = useOnlineRights(OnlineRightsNames.OL_RatesSection);
    let OL_RatesSpotAccess = useOnlineRights(OnlineRightsNames.OL_RatesSpotAccess);
    let OL_RatesForwardAccess = useOnlineRights(OnlineRightsNames.OL_RatesForwardAccess);
    let OL_SpotAccess = useOnlineRights(OnlineRightsNames.OL_SpotAccess);
    let OL_ForwardAccess = useOnlineRights(OnlineRightsNames.OL_ForwardAccess);
    let OL_RegularPaymentAccess = useOnlineRights(OnlineRightsNames.OL_RegularPaymentAccess);
    let OL_OrderAccess = useOnlineRights(OnlineRightsNames.OL_OrderAccess);
    let OL_BeneficiaryAccess = useOnlineRights(OnlineRightsNames.OL_BeneficiaryAccess);
    let OL_DealApprove = useOnlineRights(OnlineRightsNames.OL_DealApprove);
    let OL_BeneficiaryApprove = useOnlineRights(OnlineRightsNames.OL_BeneficiaryApprove);
    let OL_TransactionsHistoryAccess = useOnlineRights(OnlineRightsNames.OL_TransactionsHistoryAccess);
    let OL_MyAccountInformation_Balances = useOnlineRights(OnlineRightsNames.OL_MyAccountInformation_Balances);
    let OL_ToolsAccess = useOnlineRights(OnlineRightsNames.OL_ToolsAccess);;
    let OL_Invoicing = useOnlineRights(OnlineRightsNames.OL_Invoicing);

    /* const onLogoutClick = () => {
        logout().then((logoutResponse) => {
            localStorage.clear();
            props.onLogout();
        })
    } */

    const onNavBarOptionClick = (screen: 'dashboard' | 'rates' | 'payment' | 'balance' | 'transaction' | 'beneficiaries' | 'quickbooks' | 'approvals' | 'invoicing') => {
        setSelectedScreen(screen);
        props.onChangeScreen(screen);
    }

    return (
        <React.Fragment>
            <div className="navbar">
                {/* <div className={(selectedScreen === 'dashboard') ? 'col navbar-option-selected' : 'col navbar-option'} onClick={()=>{onNavBarOptionClick('dashboard')}}>
                    <div>
                        <span className="material-icons-round navbar-icon">dashboard</span>
                    </div>
                    <div>
                        <span>{getResource('Title.Dashboard')}</span>
                    </div>
                </div> */}
                {OL_RatesSection &&
                    (OL_RatesSpotAccess || OL_RatesForwardAccess)
                    && <div className={(selectedScreen === 'rates') ? 'col navbar-option-selected' : 'col navbar-option'} onClick={() => { onNavBarOptionClick('rates') }}>
                    <div>
                        <span className="material-icons-round navbar-icon">trending_up</span>
                    </div>
                    <div>
                        <span>{getResource('Title.RateWatch')}</span>
                    </div>
                    </div>}

                {(OL_SpotAccess || OL_ForwardAccess || OL_RegularPaymentAccess || OL_OrderAccess)
                    && <div className={(selectedScreen === 'payment') ? 'col navbar-option-selected' : 'col navbar-option'} onClick={() => { onNavBarOptionClick('payment') }}>
                    <div><span className="material-icons-round navbar-icon">payment</span></div>
                    <div><span>{getResource('Title.Payments')}</span></div>
                    </div>}

                {(OL_TransactionsHistoryAccess)
                    && <div className={(selectedScreen === 'transaction') ? 'col navbar-option-selected' : 'col navbar-option'} onClick={() => { onNavBarOptionClick('transaction') }}>
                    <div><span className="material-icons-round navbar-icon">receipt_long</span></div>
                    <div><span>{getResource('Title.Transactions')}</span></div>
                    </div>}

                {OL_MyAccountInformation_Balances
                    && <div className={(selectedScreen === 'balance') ? 'col navbar-option-selected' : 'col navbar-option'} onClick={() => { onNavBarOptionClick('balance') }}>
                    <div>
                        <span className="material-icons-round navbar-icon">view_list</span>
                    </div>
                    <div>
                        <span>{getResource('Tab_MyBalances')}</span>
                    </div>
                    </div>}

                {OL_BeneficiaryAccess
                    && <div className={(selectedScreen === 'beneficiaries') ? 'col navbar-option-selected' : 'col navbar-option'} onClick={() => { onNavBarOptionClick('beneficiaries') }}>
                    <div>
                        <span className="material-icons-round navbar-icon">people</span>
                    </div>
                    <div>
                        <span>{getResource('Tab.Option.Beneficiary')}</span>
                    </div>
                </div>
                }

                {OL_ToolsAccess
                    && <div className={(selectedScreen === 'quickbooks') ? 'col navbar-option-selected' : 'col navbar-option'} onClick={() => { onNavBarOptionClick('quickbooks') }}>
                    <div>
                        <span className="material-icons-round navbar-icon">construction</span>
                    </div>
                    <div>
                        <span>{'Tools'}</span>
                    </div>
                </div>
                }

                {((userSession.DealApproveRequired && OL_DealApprove)
                    || (userSession.BeneApproveRequired && OL_BeneficiaryApprove))
                    && <div className={(selectedScreen === 'approvals') ? 'col navbar-option-selected' : 'col navbar-option'} onClick={() => { onNavBarOptionClick('approvals') }}>
                    <div>
                        <span className="material-icons-round navbar-icon">check_circle_outline</span>
                    </div>
                    <div>
                        <span>{getResource('Tab_Approvals')}</span>
                    </div>
                </div>
                }
                {OL_Invoicing
                    && <div className={(selectedScreen === 'invoicing') ? 'col navbar-option-selected' : 'col navbar-option'} onClick={() => { onNavBarOptionClick('invoicing') }}>
                        <div>
                            <span className="material-icons-round navbar-icon">receipt</span>
                        </div>
                        <div>
                            <span>{getResource('Title.Invoicing')}</span>
                        </div>
                    </div>
                }
                {/* <div className="col navbar-option" onClick={onLogoutClick}>
                    <div>
                        <span className="material-icons-round navbar-icon">logout</span>
                    </div>
                    <div>
                    <span>{getResource('Login.Logout')}</span>
                    </div>
                </div> */}
                <div className="navbar-trader-info-container">
                    <div className="row middle-xs">
                        <div className="col col-xs-md-8 col-xs-12">
                            <span className="trader-name">{userSession.TraderName}</span>
                            <span className="trader-info">{userSession.TraderEmail}</span>
                            <span className="trader-info">{userSession.TraderPhone}</span>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default NavBar;