import { genericCallWithBody, genericGetWithParameters } from './Networking';

export async function saveEntityProperty(model: any) {
  var method = 'POST';
  var apiName = 'EntityProperty/SaveByEntityId';
  let response = await genericCallWithBody(method, apiName, model);
  return response;
}

export async function getEntityProperties(entityID: number, entityType: string) {
  try {
    let params = {
      'EntityType': entityType,
      'EntityId': entityID,
      'Page': 0,
      'Items': 0,
    };
    let response = await genericGetWithParameters('EntityProperty/GetPropertiesByEntityId', params);
    return response;
  } catch (error) {
    console.error(error);
  }
}
