import publicIp from 'public-ip';
import {genericCallWithBodyAndResponseDecoded, genericGetWithParameters, genericCallWithOutBody} from './Networking';

export async function onlineLogin(loginRequest:{UserName:string, Password:string, InstallationID: string, PublicIP?:string}) {
    try {
        let publicIP = await publicIp.v4({fallbackUrls:[
            'https://api.ipify.org/'
        ]});
        loginRequest.PublicIP = publicIP;
        let method = 'POST';
        let apiName = 'Login/ValidateUserID';
        let response = await genericCallWithBodyAndResponseDecoded(method, apiName, loginRequest);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function updateSession(customerID:number, installationID:string){
    try {
        let params = {
            'CustomerID': customerID,
            'InstallationID': installationID
        };
        let response = await genericGetWithParameters('Login/UpdateSession',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function logout() {
    try {
        let apiName = 'Logout/Post';
        let response = await genericCallWithOutBody(apiName, {});
        return response;
    } catch (error) {
        console.error(error);
    }
}