import { Divider, Modal } from 'antd';
import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import useCustomerCurrencies from '../../../api/hooks/useCustomerCurrencies';
import { saveDealTemplate } from '../../../api/NetworkingDealTemplates';
import AppContext from '../../../App.context';
import { formatToCurrency } from '../../../helpers/FormatHelper';
import { getResource } from '../../../helpers/ResourcesHelper';
import Button from '../../inputs/Button';
import Dropdown from '../../inputs/Dropdown';
import Input from '../../inputs/Input';
import { ICustomerCurrencies } from '../currencyDetails/CurrencyDetails';

interface AddEditOrderTemplateProps {
  type: string;
  deal?: any;
  anotherDeal?: any;
  templateObj?: any;
  disbursement?: any;
  show: boolean;
  saveTemplateModalProps: {
    ButtonLabel: string,
    Modaltitle: string
  };
  onCancel: Function;
  orderObj?: any;
  fromOrdersDisbursement?: boolean;
}

const AddEditOrderTemplate: FunctionComponent<AddEditOrderTemplateProps> = (props) => {
  const { showModal } = useContext(AppContext);
  const [customerCurrencies] = useCustomerCurrencies();
  const [updating, setUpdating] = useState(false);
  const [templateName, setTemplateName] = useState('');
  const [savingTemplate, setSavingTemplate] = useState(false);
  const [buyCurrencyTemplate, setBuyCurrencyTemplate] = useState('');
  const [sellCurrencyTemplate, setSellCurrencyTemplate] = useState('');
  const [amount, setAmount] = useState(NaN);
  const [sellCurrency, setSellCurrency] = useState('');
  const [buyCurrency, setBuyCurrency] = useState('');

  // --- For Input Number Scale ----------------------------------------------------------------------
  const [buyScale, setBuyScale] = useState(2);

  useEffect(() => {
    if (props.type === 'view') {
      setTemplateName(props.templateObj.TemplateName);
      setAmount(props.templateObj.SellAmount);
      setBuyCurrency(props.templateObj.SellCurrency);
      setSellCurrency(props.templateObj.BuyCurrency);
    }
    if (props.type === 'add' && props.fromOrdersDisbursement === true) {
      if (props.orderObj.Type === 'template')
        setTemplateName(props.orderObj.TemplateObj.TemplateName);
    }
  }, [updating]);

  useEffect(() => {
    if (props.orderObj != null && customerCurrencies !== undefined && (customerCurrencies as ICustomerCurrencies).payment !== undefined) {
      let buycurrencyItemAux = (customerCurrencies as ICustomerCurrencies).payment.find((currency: any) => currency.value === props.orderObj.BuyCurrency);
      setBuyScale(buycurrencyItemAux.NumberOfDecimals);
    }
  }, [customerCurrencies, props.orderObj]);

  const saveTemplate = () => {
    setSavingTemplate(true);
    if (templateName !== '') {

      let disbursements: any[] = [];
      let anotherDeal = props.anotherDeal;
      let deal = props.deal;
      if (props.fromOrdersDisbursement) {
        props.orderObj.Disbursements.forEach((outgoing: any) => {
          /*
            AccountIsActive: 1
            BankAccountID: 0
            BeneficiaryBankAccountName: ""
            BeneficiaryID: 14178
            BeneficiaryName: "Andre's Mechanical"
            BeneficiaryStatus: "Pending"
            CountryName: "United States of America"
            Description: "Andre's Mechanical"
            PreferredDeliveryMethod: ""
            PreferredMethodOfPayment: "Electronic Funds Transfer"
          */
          let newDisbursement = {
            BeneficiaryPaymentID: 0,
            Instrument: outgoing.instrument,
            BeneficiaryID: outgoing.beneficiary.BeneficiaryID,
            ValueDate: '',
            BeneficiaryBankAccountID: outgoing.beneficiary.BankAccountID,
            PaymentReference: outgoing.reference,
            DeliveryMethod: outgoing.PreferredDeliveryMethod,
            //SellAmount: outgoing.amount,
            SellAmount: formatToCurrency(outgoing.amount, props.orderObj.BuyCurrency),
          }
          disbursements.push(newDisbursement);
        });
      } else {
        anotherDeal.OutgoingFunds.forEach((outgoing: any) => {
          let newDisbursement = {
            BeneficiaryPaymentID: 0,
            Instrument: outgoing.PaymentInstrument,
            BeneficiaryID: outgoing.BeneficiaryID,
            ValueDate: '',
            BeneficiaryBankAccountID: outgoing.BeneficiaryBankAccountID,
            PaymentReference: outgoing.PaymentReference,
            DeliveryMethod: outgoing.DeliveryMethod,
            //SellAmount: outgoing.PaymentAmount,
            SellAmount: formatToCurrency(outgoing.amount, props.orderObj.BuyCurrency),
          }
          disbursements.push(newDisbursement);
        });
      }

      let request = {
        DealTemplateID: props.orderObj.Type === 'template' ? props.orderObj.TemplateObj.DealTemplateID : 0,
        CustomerId: JSON.parse(localStorage.getItem('UserSession')!).CustomerID,
        ContactId: JSON.parse(localStorage.getItem('UserSession')!).ContactID,
        TemplateName: templateName,
        SellAmount: props.fromOrdersDisbursement === true ? formatToCurrency(props.orderObj.Amount, props.orderObj.BuyCurrency) : formatToCurrency(deal['OutgoingsAmount'], deal['Buy Currency']),
        SellCurrency: props.fromOrdersDisbursement === true ? props.orderObj.SellCurrency : deal['Sell Currency'],
        BuyCurrency: props.fromOrdersDisbursement === true ? props.orderObj.BuyCurrency : deal['Buy Currency'],
        Disbursements: disbursements,
        UpdateToken: props.orderObj.Type === 'template' ? props.orderObj.TemplateObj.UpdateToken : 0,
        UpdatedBy: sessionStorage.getItem('UserID')
      }
      saveDealTemplate(request).then((responseSaveDealTemplate) => {

        let title = getResource('Js_MessageError_TitleError');
        let message = getResource('SomethingWentWrongTryAgain');

        if (responseSaveDealTemplate !== undefined) {
          console.log('saveDealTemplate', responseSaveDealTemplate);
          /*
            httpErrorMessage: undefined
            httpStatusCode: 200
            result: true
          */
          switch (responseSaveDealTemplate.httpStatusCode) {
            case 200:
              {
                if (responseSaveDealTemplate.result === true) {
                  title = getResource('Js_MessageError_TitleSuccess');
                  message = getResource('Js_Message_SaveSuccessfullyTemplate');
                  setSavingTemplate(false);
                  props.onCancel();
                  setTemplateName('');
                }
                break;
              }
            default:
              {
                if (responseSaveDealTemplate.httpErrorMessage !== undefined) {
                  message = responseSaveDealTemplate.httpErrorMessage !== '' ? responseSaveDealTemplate.httpErrorMessage : message;
                }
                break;
              }
          }
        }
        showModal(title, message);
      });
    }
  }

  let outgoingsTemplate: any[] = [];
  if (props.type === 'add' && props.anotherDeal) {
    if (props.anotherDeal.OutgoingFunds) {
      props.anotherDeal.OutgoingFunds.forEach((outgoing: any, index: number) => {
        outgoingsTemplate.push(<div className='row'>
          <div className="col-md-6 col-xs-12">
            <label
              id="input-beneficiary-name"
            //type='text'
            //value={outgoing.Name}
            //disabled={true}
            >{outgoing.Name}</label>
          </div>

          <div className="col-md-6 col-xs-12">
            <label
              id="input-beneficiary-name"
            //type='number'
            //value={outgoing.PaymentAmount}
            //disabled={true}
            >{formatToCurrency(outgoing.PaymentAmount, props.anotherDeal.BuyCurrency)}</label>
          </div>
        </div>)
      });
    }
  } else {
    if (props.type === 'view' && props.templateObj) {
      props.templateObj.Disbursements.forEach((disbursement: any, index: number) => {
        outgoingsTemplate.push(<div className='row'>
          <div className="col-md-6 col-xs-12">
            <label
              id="input-beneficiary-name"
            //type='text'
            //value={outgoing.Name}
            //disabled={true}
            >{disbursement.BeneficiaryName}</label>
          </div>

          <div className="col-md-6 col-xs-12">
            <label
              id="input-beneficiary-amount"
            //type='number'
            //value={outgoing.PaymentAmount}
            //disabled={true}
            >{formatToCurrency(disbursement.SellAmount, props.orderObj.SellCurrency)}</label>
          </div>
        </div>)
      })
    } else {
      if (props.type === 'add' && props.orderObj !== undefined) {
        props.orderObj.Disbursements.forEach((disbursement: any, index: number) => {
          outgoingsTemplate.push(<div className='row'>
            <div className="col-md-6 col-xs-12">
              <label
                id="input-beneficiary-name"
              >{disbursement.beneficiary.BeneficiaryName}</label>
            </div>

            <div className="col-md-6 col-xs-12">
              <label
                id="input-beneficiary-amount"
              >{formatToCurrency(disbursement.amount, props.orderObj.BuyCurrency)}</label>
            </div>
          </div>)
        })
      }
    }
  }

  return (
    <Modal
      title={<h3 className="component-title">{props.saveTemplateModalProps ? props.saveTemplateModalProps.Modaltitle : getResource('NewDealTemplate')}</h3>}
      centered
      visible={props.show}
      footer={null}
      onCancel={() => { setTemplateName(''); setSavingTemplate(false); props.onCancel() }}
      maskClosable={false}
      width={'40%'}
      zIndex={1020}
    >
      <div>
        <div className="row">
          <div className="col-md-8 col-xs-12">
            <div className="row">
              <span className="input-label">{getResource('TemplateName')}</span>
            </div>
            <div className="row">
              <Input
                className={(savingTemplate && templateName === '') ? 'input-error' : ''}
                id="input-template-name"
                type='text'
                value={templateName}
                onChange={(event: any) => {
                  setTemplateName(event.target.value);
                }}
                /*submit={savingTemplate}
                errorMessage={"Template Name is required!"}*/
                disabled={props.type == 'add' ? false : true}
                maxLength={30}
              />
            </div>
          </div>
          <div className="col-md-4 col-xs-12">
            <div className="row">
              <span className="input-label">{getResource('TotalAmount')}</span>
            </div>
            <div className="row">
              <Input
                id="input-amount-name"
                type='number'
                value={props.type == 'add' ? (props.fromOrdersDisbursement ? props.orderObj.Amount : props.deal['OutgoingsAmount']) : amount}
                onValueChange={(value: number) => {
                  setTemplateName(value.toString())
                }}
                submit={savingTemplate}
                errorMessage={getResource('DataMsgRequired')}
                disabled={true}
                decimalScale={buyScale}
              />
            </div>
          </div>
          <div className="col-md-6 col-xs-6">
            <div className="row">
              <span className="input-label">{getResource('Form.Label.BuyCurrency')}</span>
            </div>
            <div className="row">
              <Dropdown
                name="buyCurrency"
                title={getResource('SelectCurrencyBuy')}
                list={(customerCurrencies as ICustomerCurrencies).payment}
                onChange={(item: any) => { setBuyCurrencyTemplate(item.value); }}
                select={{ value: props.type == 'add' ? (props.fromOrdersDisbursement ? props.orderObj.BuyCurrency : props.deal['Sell Currency']) : buyCurrency }}
                disabled={true}
              />
            </div>
          </div>
          <div className="col-md-6 col-xs-6">
            <div className="row">
              <span className="input-label">{getResource('Form.Label.SellCurrency')}</span>
            </div>
            <div className="row">
              <Dropdown
                name="sellCurrency"
                title={getResource('SelectCurrencySell')}
                list={(customerCurrencies as ICustomerCurrencies).settlement}
                onChange={(item: any, name: string) => { setSellCurrencyTemplate(item.value); }}
                select={{ value: props.type == 'add' ? (props.fromOrdersDisbursement ? props.orderObj.SellCurrency : props.deal['Buy Currency']) : sellCurrency }}
                disabled={true}
              />
            </div>
          </div>
        </div>
        <Divider style={{ marginTop: 10, marginBottom: 10, fontSize: 'large', fontWeight: 'bold' }} orientation="left">{getResource('DisbursementsInformation')}</Divider>
        {<div className="row">
          {/*<div className="col-xs-12 subtitle-text-primary">Disbursements Information</div>*/}
          <div className="row">
            <div className="col-md-6 col-xs-12 ">
              <span className="input-label start-xs">{getResource('Js_TH_Beneficiary')}</span>
            </div>
            <div className="col-md-6 col-xs-12">
              <span className="input-label start-xs">{getResource('Amount')}</span>
            </div>
          </div>
          {outgoingsTemplate}
        </div>}
        <Divider style={{ marginTop: 10, marginBottom: 20, }} />
        <div className="row around-xs">
          <div className="col-md-5 col-xs-6">
            <div className="col">
              <Button id="confirmation-afex-cancel" type="secondary" onClick={() => { setTemplateName(''); setSavingTemplate(false); props.onCancel(); }}>
                {getResource('Button.Cancel')}
              </Button>
            </div>
          </div>
          {props.type === 'add' && <div className="col-md-5 col-xs-6">
            <div className="col">
              <Button id="confirmation-afex-OK" type="primary" onClick={saveTemplate}>
                {getResource('Button.Save')}
              </Button>
            </div>
          </div>}
        </div>
      </div>
    </Modal>
  );
}

export default AddEditOrderTemplate;
