import React, { useState } from 'react';
import useOnlineRights from '../../api/hooks/useOnlineRights';
import { OnlineRightsNames } from '../../helpers/OnlineRightsHelper';
import { getResource } from '../../helpers/ResourcesHelper';
import PendingBeneficiariesList from './pendingBeneficiaries/PendingBeneficiariesList';
import PendingDealList from './pendingDeals/PendingDealList';


const ApprovalsScreen = (props: any) => {
  const [currentScreen, setCurrentScreen] = useState('deals');
  const [updating, setUpdating] = useState(false);

  let userSession = JSON.parse(localStorage.getItem('UserSession')!);
  const OL_DealApprove = useOnlineRights(OnlineRightsNames.OL_DealApprove);
  const OL_BeneficiaryApprove = useOnlineRights(OnlineRightsNames.OL_BeneficiaryApprove);

  const onChangeScreen = (screen: string) => {
    setCurrentScreen(screen);
  };

  const renderScreen = () => {
    switch (currentScreen) {
      case 'deals':
        return <PendingDealList />;
      case 'beneficiaries':
        return <PendingBeneficiariesList />;
      default:
        return <div></div>;
    }
  };

  return (
    <React.Fragment>
      <div className="navbar-horizontal slide-t-b">
        <div className="row">
          {userSession.DealApproveRequired && OL_DealApprove
            && <div
            className={currentScreen === 'deals' ? 'col-xs navbar-horizontal-option-selected' : 'col-xs navbar-horizontal-option'}
            onClick={() => {
              onChangeScreen('deals');
            }}
          >
            <span>{getResource('Title.Deals')}</span>
            </div>}

          {userSession.BeneApproveRequired && OL_BeneficiaryApprove
            && <div
            className={currentScreen === 'beneficiaries' ? 'col-xs navbar-horizontal-option-selected' : 'col-xs navbar-horizontal-option'}
            onClick={() => {
              onChangeScreen('beneficiaries');
            }}
          >
            <span>{getResource('Tab.Option.Beneficiary')}</span>
            </div>}
        </div>
      </div>
      {renderScreen()}
    </React.Fragment>
  );
};

export default ApprovalsScreen;
