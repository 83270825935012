export class Deal {
  DealID: number;
  DealType: string;
  BuyCurrency: string;
  SellCurrency: string;
  BuyAmount: number;
  SellAmount: number;
  ValueDate: string;
  IssuedDate: string;
  SettlementMethod: string;
  CustomerRate?: number;
  Status?: string;
  DealDisbursementStatus?: string;
  DealPaidStatus?: string;
  ForwardType?: string;

  constructor(dealID: number, dealType: string, buyCurrency: string, sellCurrency: string, buyAmount: number, sellAmount: number, valueDate: string, issuedDate: string, settlementMethod: string, forwardType?: string) {
    this.DealID = dealID;
    this.DealType = dealType;
    this.BuyCurrency = buyCurrency;
    this.SellCurrency = sellCurrency;
    this.BuyAmount = buyAmount;
    this.SellAmount = sellAmount;
    this.ValueDate = valueDate;
    this.IssuedDate = issuedDate;
    this.SettlementMethod = settlementMethod;
    this.ForwardType = forwardType;
  }
}
