import React, { useContext, useState } from 'react';
import { savePassword } from '../../../api/NetworkingUsers';
import AppContext from '../../../App.context';
import { getResource } from '../../../helpers/ResourcesHelper';
import Button from '../../inputs/Button';
import Input from '../../inputs/Input';

const ChangePassword = () => {
  const { showModal } = useContext(AppContext);
  const [existingPassword, setExistingPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [saving, setSaving] = useState(false);
  const [confirmPasswordMessage, setConfirmPasswordMessage] = useState(getResource('ConfirmPasswordRequired'));

  const changePassword = async () => {
    setSaving(true);
    if (validateFields()) 
    {
      const userSession = JSON.parse(localStorage.getItem('UserSession')!);
      const model = {
        ContactID: userSession.ContactID,
        OldPassword: existingPassword,
        NewPassword: newPassword,
        InstallationID: userSession.InstallationID,
        UserName: userSession.UserName,
        ContactName: userSession.ContactName
      };
      const json = await savePassword(model);
      if (json != null) {
        if (json != null) {
          if (json.httpStatusCode === 200) {
            showModal(getResource('Js_MessageError_TitleSuccess'), getResource('PasswordChangedSuccessfully'));
          } else {
            if (json.result != null) {
              if (json.result === 0) {
                showModal(getResource('Js_MessageError_TitleError'), getResource('PasswordError'));
              } else if (json.result === 2) {
                showModal(getResource('Js_MessageError_TitleError'), getResource('PasswordUsed'));
              }
            }
          }
        }
      }
    }else{
      showModal(getResource('Js_MessageError_TitleError'), getResource('Js_MessageError_FormValidationNFieldNHighlighted'));
    }
  };

  const validateFields = () => {
    let existingPasswordValid = true;
    let newPasswordValid = true;
    let confirmPasswordValid = true;

    if(existingPassword == ''){existingPasswordValid = false;}
    if(newPassword == ''){newPasswordValid = false;}
    if(confirmPassword == ''){confirmPasswordValid = false;}
    if(newPassword != confirmPassword){
      confirmPasswordValid = false;
      setConfirmPasswordMessage(getResource('DataMsgNewConfirmPasswordNoMatch'));
    }

    return(
      existingPasswordValid &&
      newPasswordValid &&
      confirmPasswordValid
    );
  }

  const onChangeExistingPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setExistingPassword(event.target.value);
  };

  const onChangeNewPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewPassword(event.target.value);
  };

  const onChangeConfirmPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(event.target.value);
  };

  return (
    <div className="screen-container slide-r-l">
      <div className="row center-xs">
        <div className="col-md-5 col-xs-12">
          <div className="row">
            <span className="input-label">{getResource('ExistingPassword')}</span>
          </div>
          <div className="row">
            <Input 
              value         = {existingPassword} 
              onChange      = {onChangeExistingPassword} 
              type          = "password"
              submit        = {saving}
              errorMessage={getResource('ExistingPasswordRequired')}
            />
          </div>
        </div>
      </div>
      <div className="row center-xs">
        <div className="col-md-5 col-xs-12">
          <div className="row">
            <span className="input-label">{getResource('Label_NewPassword')}</span>
          </div>
          <div className="row">
            <Input 
              value         = {newPassword} 
              onChange      = {onChangeNewPassword} 
              type          ="password"
              submit        = {saving}
              errorMessage={getResource('DataMsgNewPasswordRequired')}
            />
          </div>
        </div>
      </div>
      <div className="row center-xs">
        <div className="col-md-5 col-xs-12">
          <div className="row">
            <span className="input-label">{getResource('Label_ConfirmPassword')}</span>
          </div>
          <div className="row">
            <Input 
              value         = {confirmPassword} 
              onChange      = {onChangeConfirmPassword} 
              type          = "password"
              submit        = {saving}
              errorMessage  = {confirmPasswordMessage}
            />
          </div>
          <div className="row center-xs">
            <Button onClick={changePassword}>{getResource('Button.Save')}</Button>
          </div>
        </div>
      </div>
      <div className="screen-container">
        <div className="row center-xs">
          <p>
            {getResource('Content.Column.ChangePassword')}
            {getResource('Content.Column.ChangePassword2')}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
