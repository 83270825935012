import { useEffect, useState } from 'react';
import { getAllCountries } from '../NetworkingCountries';

export interface Country {
  Country: string;
  'Country Name': string;
  Currency: string;
  'Display Order': number;
  ISOCode$: string;
  Nationality$: string;
  'Num ID': number;
  'Risk Score': number;
  Status: string;
  UpdateToken$: string;
}

const useCountries = () => {
  const [countries, setCountries] = useState<Country[]>([]);
  const [needsReloading, setNeedsReloading] = useState(true);

  useEffect(() => {
    const loadCountries = async () => {
      const json = await getAllCountries();
      if (json != null && json.countries != null) {
        console.log(json);
        const formattedCountries = json.countries.map((c: Country) => ({ ...c, label: `${c.Country} - ${c['Country Name']}`, value: c.Country }));
        formattedCountries.push({ label: 'Select a Country...', value: '' });
        setCountries(formattedCountries);
      } else {
        setCountries([]);
      }
      setNeedsReloading(false);
    };
    if (needsReloading) {
      loadCountries();
    }
  }, [needsReloading]);

  return [countries, setNeedsReloading];
};

export default useCountries;
