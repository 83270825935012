import { DatePicker as AntDatePicker } from 'antd';
import { Moment } from 'moment';
import React, { FunctionComponent } from 'react';
const { RangePicker } = AntDatePicker;
export const dateFormat = 'YYYY/MM/DD';

interface DatePickerProps {
  className?: string;
  id?: string;
  format?: string;
  disabledDate?: (currentDate: Moment) => boolean;
  disabled?: boolean;
  disabledRange?: [boolean, boolean];
  onChange?: (date: Moment | null, dateString: string) => void;
  value?: Moment | null;
  valueRange?:[Moment, Moment] | undefined;
  isRange?: boolean;
  ranges?: Record<string, [Moment, Moment] | (() => [Moment, Moment])> | undefined;
  onChangeRange?: (values: any, formatString: [string, string]) => void;
  submit?: boolean;
  errorMessage?: string;
  valueValidation?: string;
  style?: any;
}

const DatePicker: FunctionComponent<DatePickerProps> = (props) => {
  return (
    <>
    {!props.isRange ? <AntDatePicker
      id={props.id ? props.id : 'DatePicker'}
      className={ props.className? props.className: "datepicker-input"}
      format={props.format != null ? props.format : dateFormat}
      disabledDate={props.disabledDate}
      disabled={props.disabled != null ? props.disabled : false}
      onChange={props.onChange != null ? props.onChange : (date, dateString) => {}}
      value={props.value}
        style={props.style}
    /> : <RangePicker
      id={props.id ? props.id : 'RangePicker'}
      ranges={props.ranges}
      format= {props.format != null ? props.format : dateFormat}
      onChange={props.onChangeRange != null ? props.onChangeRange : (dates, dateStrings) => {}}
      disabled={props.disabledRange}
      value={props.valueRange}
    />}
    {props.submit && props.valueValidation === '' && <div className="row">
      <div className="ant-form-item-explain ant-form-item-explain-error">
        <div role="alert">{ props.errorMessage === undefined ? 'Required!' : props.errorMessage}</div>
      </div>
    </div>}
    </>
  );
};

export default DatePicker;
