import moment from 'moment';
import React, { FunctionComponent, useState } from 'react';
import { Message } from '../../../api/NetworkingMessages';
import { getResource } from '../../../helpers/ResourcesHelper';
import Button from '../../inputs/Button';
import DatePicker, { dateFormat } from '../../inputs/DatePicker';
import MessageReply from './MessageReply';

interface MessageContentProps {
  handleBackLink: () => void;
  message: Message | null;
}

const MessageContent: FunctionComponent<MessageContentProps> = ({ message, handleBackLink: returnToGrid }) => {
  const [subject, setSubject] = useState(message?.Subject);
  const [from, setFrom] = useState(message?.Receiver);
  const [date, setDate] = useState(message?.SentOn);
  const [content, setContent] = useState(message?.Content);
  const [viewReplyScreen, setViewReplyScreen] = useState(false);

  const handleReply = () => {
    setViewReplyScreen(true);
  };

  const onChangeSubject = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSubject(event.target.value);
  };

  const onChangeFrom = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setFrom(event.target.value);
  };

  const onChangeDate = (_: any, dateString: string) => {
    let auxDate = moment(new Date(), dateFormat).format(dateFormat);
    if (dateString !== '') {
      setDate(dateString);
    } else {
      setDate(auxDate);
    }
  };

  const onChangeContent = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setContent(event.target.value);
  };

  const handleBackLink = () => {
    returnToGrid();
  };

  const handleBackLinkFromMessageReply = () => {
    setViewReplyScreen(false);
  };

  if (viewReplyScreen) {
    return <MessageReply subject={`Reply - ${message!.Subject}`} message={message} handleBackLink={handleBackLinkFromMessageReply} />;
  }

  return (
    <div className="screen-container">
      <div className="row center-xs">
        <div className="col-md-5 col-xs-12">
          <div className="row">
            <span className="input-label">{getResource('Table_TH_Subject')}</span>
          </div>
          <div className="row">
            <input id="subject" disabled={true} value={subject} onChange={onChangeSubject} type="text" />
          </div>
        </div>
      </div>
      <div className="row center-xs">
        <div className="col-md-5 col-xs-12">
          <div className="row">
            <span className="input-label">{getResource('From')}</span>
          </div>
          <div className="row">
            <input id="from" disabled={true} value={from} onChange={onChangeFrom} type="text" />
          </div>
        </div>
      </div>
      <div className="row center-xs">
        <div className="col-md-5 col-xs-12">
          <div className="row">
            <span className="input-label">{getResource('Content')}</span>
          </div>
          <div className="row">
            <textarea id="lastName" disabled={true} value={content} onChange={onChangeContent} />
          </div>
        </div>
      </div>
      <div className="row center-xs">
        <div className="col-md-5 col-xs-12">
          <div className="row">
            <span className="input-label">{getResource('Table_TH_Date')}</span>
          </div>
          <div className="row">
            <DatePicker id="dateOfBirth" disabled={true} value={moment(date, dateFormat)} onChange={onChangeDate} />
          </div>
        </div>
      </div>
      <div className="row center-xs">
        <div className="col-md-5 col-xs-12">
          <div className="row center-xs">
            <Button key="go-back-address" type="secondary" onClick={handleBackLink}>
              {getResource('Button.Cancel')}
            </Button>
          </div>
        </div>
        <div className="col-md-5 col-xs-12">
          <div className="row center-xs">
            <Button key="save-address" onClick={handleReply}>
              {getResource('Button.Reply')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessageContent;
