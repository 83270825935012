import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import Switch from "antd/lib/switch";
import moment, { Moment } from "moment";
import React, { useEffect, useState } from "react";
import { formatValidInputClass, validInputClass } from "../../../../../helpers/FormatHelper";
import { getResource } from "../../../../../helpers/ResourcesHelper";
import Button from "../../../../inputs/Button";
import DatePicker, { dateFormat } from "../../../../inputs/DatePicker";
import Input from "../../../../inputs/Input";

export interface MainInformation
{
  MainContact     : boolean;
  FirstName       : string;
  LastName        : string;
  PrimaryPhone    : string;
  SecondaryPhone  : string;
  Email           : string;
  DateOfBirth     : string;
}

const MainInformationStep = (props: any) => {

  const [isMainContact, setIsMainContact] = useState<boolean>(true);
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [primaryPhone, setPrimaryPhone] = useState<string>('');
  const [secondaryPhone, setSecondaryPhone] = useState<string>('');
  const [emailAddress, setEmailAddress] = useState<string>('');
  const [dateOfBirth, setDateOfBirth] = useState<string>('');
  const [validations, setValidations] = useState({
    firstName: true,
    lastName: true,
    primaryPhone: true,
    emailAddress: true,
    dateOfBirth: true,
  });

  useEffect(() => {
    if((props.do == 'edit' && props.contact !== null) || (props.do == 'add' && props.contact !== null))
    {
      setIsMainContact(props.contact.MainContact);
      setFirstName(props.contact.FirstName);
      setLastName(props.contact.LastName);
      setPrimaryPhone(props.contact.PhoneNumber);
      setSecondaryPhone(props.contact.MobileNumber);
      setEmailAddress(props.contact.Email);
      setDateOfBirth(props.contact.DateOfBirth);
      if (props.validForm) {
        setValidations({
          firstName: validInputClass(props.contact.FirstName),
          lastName: validInputClass(props.contact.LastName),
          primaryPhone: validInputClass(props.contact.PhoneNumber),
          emailAddress: validInputClass(props.contact.Email),
          dateOfBirth: validInputClass(props.contact.DateOfBirth),
        });
      }
    }
  }, [props.contact]);

  const switchActions = (value: any, type: string) => {
    let objSetters: { [key: string]: any } = {
      ['maincontact']   : () => {setIsMainContact(value);},
    }
    return(objSetters[type]());
  }

  const inputsActions = (event:any, type:string) => {
    let objSetters: { [key: string]: any } = {
      ['firstname']: () => {
        setValidations({ ...validations, firstName: true });
        setFirstName(event.target.value);
      },
      ['lastname']: () => {
        setValidations({ ...validations, lastName: true });
        setLastName(event.target.value);
      },
      ['primaryphone']: () => {
        setValidations({ ...validations, primaryPhone: true });
        setPrimaryPhone(event.target.value);
      },
      ['secondaryphone']: () => {setSecondaryPhone(event.target.value);},
      ['emailaddress']: () => {
        setValidations({ ...validations, emailAddress: true });
        setEmailAddress(event.target.value);
      },
    }
    return(objSetters[type]())
  }

  const datesAcctions = (date: (Moment | null), dateString: string, type:string) => {
    let objSetters: { [key: string]: any } = {
      ['dateofbirth']: () => {
        setValidations({ ...validations, dateOfBirth: true });
        setDateOfBirth(date === null ? '' : date.format('YYYY/MM/DD'))
      },
    }
    return(objSetters[type]())
  }

  const validateFields = () => {
    let firstNameValid    : boolean = true;
    let lastNameValid     : boolean = true;
    let primaryPhoneValid : boolean = true;
    let emailAddressValid : boolean = true;
    let dateOfBirthValid  : boolean = true;

    if(firstName === ''){firstNameValid = false;}
    if(lastName === ''){lastNameValid = false;}
    if(primaryPhone === ''){primaryPhoneValid = false;}
    if(emailAddress === ''){emailAddressValid = false;}
    if(dateOfBirth === ''){dateOfBirthValid = false;}

    setValidations({
      firstName: validInputClass(firstName),
      lastName: validInputClass(lastName),
      primaryPhone: validInputClass(primaryPhone),
      emailAddress: validInputClass(emailAddress),
      dateOfBirth: validInputClass(dateOfBirth),
    });

    return(
      firstNameValid    &&
      lastNameValid     &&
      primaryPhoneValid &&
      emailAddressValid &&
      dateOfBirthValid
    );
  }
  const getDate = (dateString: string) => {
    if (dateString === '') {
      return undefined;
    }
    const date = moment(dateString, dateFormat);
    return date && date.isValid() ? date : undefined;
  };
  return (
    <>
      <div className="col-md-1 col-xs-12" />
      <div className="col-md-10 col-xs-12 start-sm start-xs slide-r-l" >
        {/*Main Contact*/}
        <div className="row">
          <div className="col-md-2 col-xs-4">
            <span className="input-label start-sm start-xs">{getResource('MainContact')}</span>
          </div>
          <div className="col-md-10 col-xs-8 start-sm start-xs">
            <Switch
              checkedChildren   = {<CheckOutlined />}
              unCheckedChildren = {<CloseOutlined />}
              defaultChecked    = {false}
              checked           = {isMainContact}
              onChange          = {(event:any)=>{switchActions(event, 'maincontact');}}
              disabled          = {
                (props.do === 'add' && props.hasMainContact.can) || 
                (props.do === 'edit' && props.hasMainContact.can && props.contact !== null ? 
                  (props.hasMainContact.id === props.contact.ContactID ? false : true) : false
                )
              }
            />
          </div>
        </div>
      </div>
      <div className="col-md-1 col-xs-12" />
      <div className="col-md-1 col-xs-12" />
      <div className="col-md-5 col-xs-12 slide-r-l">
        <div className="row">
          {/*First Name*/}
          <div className="col-md-12 col-xs-12">
            <div className="row">
              <span className="input-label">{getResource('Label.FirstName')} (*)</span>
            </div>
            <div className="row">
              <Input 
                id="contact-first-name"
                type     = 'text' 
                value    = {firstName} 
                onChange = {(event:any)=>{
                  inputsActions(event, 'firstname')
                }}
                className={formatValidInputClass(validations.firstName)}
                errorMessage={getResource('DataMsgRequired')}
              />
            </div>
          </div>
          {/*Primary Phone*/}
          <div className="col-md-12 col-xs-12">
            <div className="row">
              <span className="input-label">{getResource('Label.PrimaryPhone')} (*)</span>
            </div>
            <div className="row">
              <Input
                id="contact-primary-phone"
                type     = 'text' 
                value    = {primaryPhone} 
                onChange = {(event:any)=>{
                  inputsActions(event, 'primaryphone')
                }}
                className={formatValidInputClass(validations.primaryPhone)}
                errorMessage={getResource('DataMsgRequired')}
              />
            </div>
          </div>
          {/*Email Address*/}
          <div className="col-md-12 col-xs-12">
            <div className="row">
              <span className="input-label">{getResource('Label.Email')} (*)</span>
            </div>
            <div className="row">
              <Input
                id="contact-email-address"
                type     = 'text' 
                value    = {emailAddress} 
                onChange = {(event:any)=>{
                  inputsActions(event, 'emailaddress')
                }}
                className={formatValidInputClass(validations.emailAddress)}
                errorMessage={getResource('DataMsgRequired')}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-5 col-xs-12 slide-r-l">
        <div className="row">
          {/*Last Name*/}
          <div className="col-md-12 col-xs-12">
            <div className="row">
              <span className="input-label">{getResource('Label.LastName')} (*)</span>
            </div>
            <div className="row">
              <Input
                id="contact-last-name"
                type     = 'text' 
                value    = {lastName} 
                onChange = {(event:any)=>{
                  inputsActions(event, 'lastname')
                }}
                className={formatValidInputClass(validations.lastName)}
                errorMessage={getResource('DataMsgRequired')}
              />
            </div>
          </div>
          {/*Secondary Phone*/}
          <div className="col-md-12 col-xs-12">
            <div className="row">
              <span className="input-label">{getResource('Label.SecondaryPhone')}</span>
            </div>
            <div className="row">
              <Input
                id="contact-secondary-phone"
                type     = 'text' 
                value    = {secondaryPhone} 
                onChange = {(event:any)=>{
                  inputsActions(event, 'secondaryphone')
                }}
              />
            </div>
          </div>
          {/*Date Of Birtb*/}
          <div className="col-md-12 col-xs-12">
            <div className="row">
              <span className="input-label">{getResource('Label.DateBirth')} (*)</span>
            </div>
            <div className="row">
              <DatePicker
                id        = "dateOfBirth-datepicker-expireson"
                key       = "dateOfBirth-datepicker-expireson"
                onChange  = {
                  (date: Moment | null, dateString: string) => {
                    datesAcctions(date, dateString, 'dateofbirth');
                  }
                }
                className={formatValidInputClass(validations.dateOfBirth)}
                errorMessage    = {"Date of Birth is required!"}
                valueValidation = {dateOfBirth}
                value={getDate(dateOfBirth)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-1 col-xs-12" />
      {/*Buttons*/}
      <div className="col-md-12 col-xs-12 slide-r-l">
        <div className="row center-md">
          <div className="col-md-1"></div>
          <div className="col-md-5 col-xs-6">
            <Button 
              id="contacts-main-info-cancel"
              type    = "secondary" 
              onClick = {() => { props.onCancel(); }}
            >{getResource('Button.Cancel')}</Button>
          </div>
          <div className="col-md-5 col-xs-6">
            <Button 
              id="contacts-main-info-next"
              type    = "primary" 
              onClick = {(event: any) => { 
                let returnMainInfo: MainInformation = {
                  MainContact: isMainContact,
                  FirstName:firstName,
                  LastName: lastName,
                  PrimaryPhone: primaryPhone,
                  SecondaryPhone: secondaryPhone,
                  Email: emailAddress,
                  DateOfBirth: dateOfBirth
                };
                
                if(validateFields())
                {
                  props.onNextStep('finish', returnMainInfo, false);
                }else{
                  props.onNextStep('error', returnMainInfo, true);
                }
              }}>{getResource('Button.Next')}</Button>
          </div>
          <div className="col-md-1"></div>
        </div>
      </div>
    </>
  );
}

export default MainInformationStep