import { genericGetWithParameters } from './Networking';

export const getCurrenciesByCustomer = async (customerID: number) => {
  try {
    const params = {
      customerID: customerID
    };
    const response = await genericGetWithParameters('Currency/GetCurrenciesByCustomer', params);
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const getListOfSettlementCurrenciesForPaymentOrders = async (customerID: number, currencyID: string) => {
  try {
    const params = {
      customerID: customerID,
      currencyID: currencyID
    };
    const response = await genericGetWithParameters('Currency/GetListOfSettlementCurrenciesForPaymentOrders', params);
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const getCurrenciesByStatus = async (status: string) => {
  try {
    const params = {
      status: status
    };
    const response = await genericGetWithParameters('Currency/GetCurrenciesByStatus', params);
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const getSettlementOrPaymentCurrencyByCustomer = async (customerId: number, currencyType: string) => {
  try {
    const params = {
      customerID: customerId,
      currencyType: currencyType
    };
    const response = await genericGetWithParameters('Currency/GetSettlementOrPaymentCurrencyByCustomer', params);
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const getCurrencyList = async () => {
  try {
      const params = {}
      const response = await genericGetWithParameters('Currency/GetCurrenciesMin', params);
      return response;
  } catch (error) {
      console.error(error);
  }
}