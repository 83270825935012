import { useEffect, useState } from 'react';
import { getTradingAndCurrencyHolidays } from '../NetworkingHolidays';

interface Currencies {}

const useHolidays = () => {
  const [holidays, setHolidays] = useState([]);
  const [needsReloading, setNeedsReloading] = useState(true);

  useEffect(() => {
    const loadHolidays = async () => {
      let startDate = new Date();
      let endDate = new Date(startDate);
      endDate.setDate(startDate.getDate() + 90);
      const json = await getTradingAndCurrencyHolidays(startDate.toISOString(), endDate.toISOString());
      let holidays = json.holidays;
      if (holidays) {
        setHolidays(holidays);
      } else {
        setHolidays([]);
      }
      setNeedsReloading(false);
    };
    if (needsReloading) {
      loadHolidays();
    }
  }, [needsReloading]);

  return [holidays, setNeedsReloading];
};

export default useHolidays;
