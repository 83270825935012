import React, { FunctionComponent } from "react";
import { getResource } from "../../../helpers/ResourcesHelper";

interface FooterProps {
  from?: string;
}

const Footer: FunctionComponent<FooterProps> = ({ from }) => {

  const company = window.REACT_APP_COMPANY;
  const urlPolicy = window.REACT_APP_COMPANY_PRIVACY_URL;

  const style = (from === 'login' ? 'footer-login' : 'footer');

  switch (company) {
    case 'Premier':
      return (

        <React.Fragment>
          <div className={style} >
            <a href={urlPolicy} target='_blank'>{getResource('PrivacyPolicy')}</a>
          </div>
        </React.Fragment>

      );
    default:
      return (

        <React.Fragment>
          <div>
          </div>
        </React.Fragment>

      );
  }
};

export default Footer;