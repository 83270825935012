import { SearchOutlined } from "@ant-design/icons";
import { AutoComplete } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { validateIBAN } from "../../api/NetworkingBankAccount";
import { getOnlineBankBranches, getOnlineBanks } from '../../api/NetworkingBranch';
import { getAllCountries, getCountryByName } from "../../api/NetworkingCountries";
import { getStatesByCountryMin } from '../../api/NetworkingStates';
import AppContext from '../../App.context';
import { formatValidAutocompleteClass, formatValidInputClass, validInputClass } from '../../helpers/FormatHelper';
import { getResource } from '../../helpers/ResourcesHelper';
import Button from '../inputs/Button';
import Dropdown from '../inputs/Dropdown';
import Input from '../inputs/Input';
import RadioButton from '../inputs/RadioButton';
interface BankAccountProps {
  bankAccountFields: number;
  [x: string]: any;
  //props: any;
}

const BankAccountInfo: React.FC<BankAccountProps> = ({ bankAccountFields, ...props }) => {
  const { showModal } = useContext(AppContext);
  const [bankAccountId, setBankAccountId] = useState('');
  const [countryID, setCountryID] = useState('');
  const [bankName, setBankName] = useState('');
  const [branchName, setBranchName] = useState('');
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [city, setCity] = useState('');
  const [stateID, setStateID] = useState(0);
  const [stateName, setStateName] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [Institution, setInstitution] = useState('');
  const [currency, setCurrency] = useState('');
  const [accountNo, setAccountNo] = useState('');
  const [bankCode, setBankCode] = useState('');
  const [swift, setSwift] = useState('');
  const [afexBanksSetup, setAfexBanksSetup] = useState(false);
  const [banks, setBanks] = useState([]);
  const [bankID, setBankID] = useState('0');
  const [bankDescription, setBankDescription] = useState('');
  const [branches, setBranches] = useState([]);
  const [branchID, setBranchID] = useState('0');
  const [branchDescription, setBranchDescription] = useState('');
  const [openBanks, setOpenBanks] = useState(false);
  const [openBranches, setOpenBranches] = useState(false);
  const [states, setStates] = useState([]);
  const [load, setLoad] = useState(true);
  const [searchBy, setSearchBy] =useState('bank');
  const [countries, setCountries] = useState([]);
  const [countriesAux, setCountriesAux] = useState([]);

  const [needsIBAN, setNeedsIBAN] = useState(false);
  const [IbanLoaded, setIBANLoaded] = useState(false);

  const [validations, setValidations] = useState({
    countryID: true,
    bankName: true,
    branchName: true,
    currency: true,
    accountNo: true,
    stateID: true,
    city: true,
    zipCode: true
  });

  const validationsFields = () => {
    var valCountryID = validInputClass(countryID);
    var valBankName = validInputClass(bankName);
    var valBranchName = validInputClass(branchName);
    var valCurrency = validInputClass(currency);
    var valAccountNo = validInputClass(accountNo);
    var valStateID = validInputClass(stateID.toString());
    var valCity = validInputClass(city);
    var valZipCode = validInputClass(zipCode);

    setValidations({
      countryID: valCountryID,
      bankName: valBankName,
      branchName: valBranchName,
      currency: valCurrency,
      accountNo: valAccountNo,
      stateID: valStateID,
      city: valCity,
      zipCode: valZipCode
    });

    var isValid = valCountryID && valBankName && valBranchName && valCurrency && valAccountNo && valStateID && valCity && valZipCode;
    if (!isValid) { showModal(getResource('Js_MessageError_TitleError'), getResource('Js_MessageError_FormValidationNFieldNHighlighted')); }
    else {
      if (needsIBAN && !IbanLoaded) {
        showModal(getResource('Js_MessageError_TitleError'), getResource('IbanValidError'));
      }
      else {
      props.onUpdateStep(1);
      }
    }

  }
  useEffect(() => {
    const loadCountries = async () => {
      const json = await getAllCountries();
      if (json != null && json.countries != null) {
        console.log(json);
        const formattedCountries = json.countries.map((c: any) => ({ ...c, label: `${c.Country} - ${c['Country Name']}`, value: c.Country, NeedsIban: c.NeedsIban }));
        formattedCountries.push({ label: 'Select a Country...', value: '' });
        setCountries(formattedCountries);
        setCountriesAux(formattedCountries);
      } else {
        setCountries(props.countries);
        setCountriesAux(props.countries);
      }
    }
    loadCountries();
  }, [props.countries]);

  useEffect(() => {
    if (props.bankAccount != null) {
      setBankAccountId(props.bankAccount.BankAccountID);
      setCountryID(props.bankAccount.CountryID);
      setBankName(props.bankAccount.BankName);
      setBankDescription(props.bankAccount.BankName);
      setBranchName(props.bankAccount.BranchName);
      setBranchDescription(props.bankAccount.BranchName);
      setAddress1(props.bankAccount.Address1);
      setAddress2(props.bankAccount.Address2);
      setCity(props.bankAccount.City);
      //setStateName(props.bankAccount.States);
      setZipCode(props.bankAccount.ZipCode);
      setInstitution(props.bankAccount.Institution);
      setCurrency(props.bankAccount.CurrencyID);
      setAccountNo(props.bankAccount.AccountNo);
      setBankCode(props.bankAccount.BankCode);
      setSwift(props.bankAccount.Swift);
      setStateID(props.bankAccount.StateID);

      findNeedsIban(props.bankAccount.CountryID);
      setIBANLoaded(true);
      updateStates(props.bankAccount.CountryID, props.bankAccount.StateID);      
    }
    var userSession = JSON.parse(localStorage.getItem('UserSession')!);
    if (userSession.BeneficiaryBanksConfiguration === 'AFEX') {
      setAfexBanksSetup(true);
    }
  }, [props.bankAccount]);

  useEffect(() => {
    if (bankAccountFields != null && bankAccountFields > 0) {
      validationsFields();
    }
  }, [bankAccountFields]);

  const findNeedsIban = async (countryID: string) => {
    if (countryID != null && countryID != '') {
      var countries = countriesAux;
      if (countries == null || countries.length == 0) {
        const json = await getAllCountries();
        if (json != null && json.countries != null) {
          console.log(json);
          countries = json.countries.map((c: any) => ({ ...c, label: `${c.Country} - ${c['Country Name']}`, value: c.Country, NeedsIban: c.NeedsIban }));
          setCountriesAux(countries);
        }
      }
      var country = countries.find((c: any) => c.Country == countryID) as any;
      if (country != null) {
        setNeedsIBAN(country.NeedsIban);
      }
    }

  }
  const handleSearchBank = (value: string) => {
    setBankDescription(value);
  };

  const onSearchBank = () => {
    getOnlineBanks(bankDescription, countryID, searchBy).then((response) => {
      if (response !== undefined && response !== null && response.httpStatusCode === 200 && response.banks !== undefined) {
        let banks = response.banks;
        // let placeHolder = banks.length > 0 ? 'Click to show banks' : 'No results';
        console.log(banks);
        banks.forEach((x: any) => {
          if (afexBanksSetup) {
            let id = x['BankName'] + ',' + x['SWIFT'];
            let desc = x['BankName'] + ',' + x['SWIFT'] + ',' + x['Address'] + ',' + x['City'];
            x.value = id;
            x.label = desc;
            x.name = x['BankName'];
          } else {
            x.value = x['Bank ID'];
            x.label = x['Bank Name'];
            x.name = x['Bank Name'];
          }
        });
        setBanks(banks);
      } else {
        setBanks([]);
      }
      setOpenBanks(true);
      setOpenBranches(false);
    });
  };

  const handleSearchBranch = (value: string) => {
    setBranchDescription(value);
  };

  const onSearchBranches = () => {
    getOnlineBankBranches(bankName, branchDescription, countryID).then((response) => {
      if (response !== undefined && response !== null && response.httpStatusCode === 200 && response.bankBranches !== undefined) {
        let bankBranches = response.bankBranches;
        // let placeHolder = bankBranches.length > 0 ? 'Click to show bank branches' : 'No results';
        bankBranches.forEach((x: any) => {
          if (afexBanksSetup) {
            let id = x['BranchDescription'] + ',' + x['AddressLine1'] + ',' + x['City'] + ',' + x['TransitNo'];
            let desc = x['BranchDescription'] + ',' + x['AddressLine1'] + ',' + x['City'] + ',' + x['TransitNo'];
            x.value = id;
            x.label = desc;
            x.name = x['BranchDescription'];
          } else {
            x.value = x['BranchDescription'] + x['AddressLine1'];
            x.label = x['BranchDescription'];
            x.name = x['BranchDescription'];
          }
        });
        setBranches(bankBranches);
      } else {
        setBranches([]);
      }
      setOpenBranches(true);
      setOpenBanks(false);
    });
  };

  const updateStates = (countryID: string, stateID: number = 0) => {
    if (countryID !== '') {
      getStatesByCountryMin(countryID).then((response) => {
        if (response != null && response.httpStatusCode === 200 && response.states != null) {
          let states = response.states;
          states = states.map((x: typeof states) => ({ label: x['State Name'], value: x['State ID'] }));
          let state = states.find((x: typeof states) => x.value === stateID);
          console.log(state);
          if (state != null) {
            setStateName(state.label);
          } else {
            setStateID(0);
            setStateName('');
          }
          setStates(states);
        }
      });
    }
  };

  const countryChangeHandler = (item: any, option: any) => {
    if (option != null && !option.label.includes('Select')) {
      setCountryID(option.label);
      findNeedsIban(item);
      setIBANLoaded(false);
      updateStates(item);
      setValidations({ ...validations, countryID: true });
      props.onUpdateBankAccount('CountryID', item);
      updateStates(item);
      if (!load) {
        //setStateID(0);
      } else {
        setLoad(false);
      }
    }
  };

  const onSearch = (value: any) => {
    setCountryID(value);
    let newValue = value.split(' -');
    if (value === '') setStates([]);
    getCountryByName(newValue[0]).then(
      (json) => {
        if (json.countries != null) {
          const formattedCountries = json.countries.map((c: any) => ({ ...c, label: c.CountryIdName, value: c.CountryId }));
          //formattedCountries.push({ label: 'Select a Country...', value: '' });
          setCountries(formattedCountries);
        }
      }
    )
  }
  const stateChangeHandler = (item: any, name: string) => {
    if (item != null) {
      setStateID(item.value);
      setValidations({ ...validations, stateID: true });
      props.onUpdateBankAccount('StateID', item.value);
    }
  };

  const onSelectBank = (item: any, option: any) => {
    setBankDescription(option.label);
    setBankID(option.value);
    setBankName(option.name);
    setInstitution(option.ABA);
    setSwift(option.SWIFT);
    setOpenBanks(false);
    setValidations({ ...validations, bankName: true });
    props.onUpdateBankAccount('BankName', option.name);
    props.onUpdateBankAccount('Institution', option.ABA);
    props.onUpdateBankAccount('Swift', option.SWIFT);

    //clear branch
    setBranchDescription('');
    setBranchID('');
    setBranchName('');
    setAddress1('');
    setAddress2('');
    setCity('');
    setBankCode('');
    setStateID(0);
    setZipCode('');

    props.onUpdateBankAccount('BranchName', '');
    props.onUpdateBankAccount('Address1', '');
    props.onUpdateBankAccount('Address2', '');
    props.onUpdateBankAccount('City', '');
    props.onUpdateBankAccount('BankCode', '');
    props.onUpdateBankAccount('StateID', 0);
    props.onUpdateBankAccount('ZipCode', '');
  };

  const onSelectBranch = (item: any, option: any) => {
    setBranchDescription(option.label);
    setBranchID(option.value);
    setBranchName(option.name);
    setAddress1(option.AddressLine1);
    setAddress2(option.AddressLine2);
    setCity(option.City);
    setBankCode(option.TransitNo);
    setStateID(option.StateID);
    setZipCode(option.PostalCode);
    console.log(option);
    setOpenBranches(false);
    setValidations({ ...validations, branchName: true });
    props.onUpdateBankAccount('BranchName', option.name);
    props.onUpdateBankAccount('Address1', option.AddressLine1);
    props.onUpdateBankAccount('Address2', option.AddressLine2);
    props.onUpdateBankAccount('City', option.City);
    props.onUpdateBankAccount('BankCode', option.TransitNo);
    props.onUpdateBankAccount('StateID', option.StateID);
    props.onUpdateBankAccount('ZipCode', option.PostalCode);
  };

  const onChange = (data: string, option: any) => {
    setBankDescription(data);
    setOpenBanks(false);
    setOpenBranches(false)
    setBankID('0');
    if (data != null) {
      setBankName(data);
      props.onUpdateBankAccount('BankName', data);
    }
  };

  const onChangeBranch = (data: string) => {
    setBranchDescription(data);
    setOpenBranches(false);
    setBranchName(data);
    setBranchID('0');
    props.onUpdateBankAccount('BranchName', data);
  };

  const currencyChangeHandler = (item: any, name: string) => {
    if (item != null) {
      setCurrency(item.value);
      setValidations({ ...validations, currency: true });
      props.onUpdateBankAccount('CurrencyID', item.value);
    }
  };

  const onChangeAccountNo = (e: any) =>
{ 
    setAccountNo(e.target.value);
    setIBANLoaded(false);
    setValidations({ ...validations, accountNo: true });
    props.onUpdateBankAccount('AccountNo', e.target.value);
}

const onChangeBankCode = (e: any) =>
{ 
    setBankCode(e.target.value);
    props.onUpdateBankAccount('BankCode', e.target.value);
}

const onChangeSWIFT = (e: any) =>
{ 
    setSwift(e.target.value);
    props.onUpdateBankAccount('Swift', e.target.value);
}

const onChangeInstitution = (e: any) =>
{ 
    setInstitution(e.target.value);
    props.onUpdateBankAccount('Institution', e.target.value);
}

  const onChangeAddress1 = (e: any) =>
{ 
    setAddress1(e.target.value);
    props.onUpdateBankAccount('Address1', e.target.value);
}

const onChangeAddress2 = (e: any) =>
{ 
    setAddress2(e.target.value);
    props.onUpdateBankAccount('Address2', e.target.value);
}

const onChangeCity = (e: any) =>
{ 
    setCity(e.target.value);
  setValidations({ ...validations, city: true });
    props.onUpdateBankAccount('City', e.target.value);
}

const onChangeZipCode = (e: any) =>
{ 
    setZipCode(e.target.value);
  setValidations({ ...validations, zipCode: true });
    props.onUpdateBankAccount('ZipCode', e.target.value);
}

const radioChangeHandler = (event: any) => {
  setSearchBy(event.target.value);
};

  const onValidateIBAN = () => {
    validateIBAN(accountNo).then(
      (json) => {
        if (json.httpStatusCode === 200) {
          var bankAccount = json.bankAccounts;

          setCountryID(bankAccount.CountryID);
          props.onUpdateBankAccount('CountryID', bankAccount.CountryID);
          setBankID(bankAccount.BankID);
          setBankName(bankAccount.BankName);
          setBankDescription(bankAccount.BankName);
          props.onUpdateBankAccount('BankName', bankAccount.BankName);

          setBranchID(bankAccount.BranchID);
          setBranchName(bankAccount.BranchName);
          setBranchDescription(bankAccount.BranchName);
          props.onUpdateBankAccount('BranchName', bankAccount.BranchName);

          setAddress1(bankAccount.AddressLine1);
          props.onUpdateBankAccount('Address1', bankAccount.AddressLine1);

          setCity(bankAccount.City);
          props.onUpdateBankAccount('City', bankAccount.City);

          setSwift(bankAccount.Swift);
          props.onUpdateBankAccount('Swift', bankAccount.Swift);

          if (bankAccount.StateID > 0) {
            setStateID(bankAccount.StateID);
            props.onUpdateBankAccount('StateID', bankAccount.StateID);
          }
          else {
            if (bankAccount.StateName != null) {
              var state = states.find((st: any) => st.label.toLowerCase().includes(bankAccount.StateName.toLowerCase())) as any;
              if (state != null) {
                setStateID(state.value);
                setStateName(state.label);
                props.onUpdateBankAccount('StateID', state.value);
              }
              else {
                setStateID(0);
                setStateName('');
                props.onUpdateBankAccount('StateID', 0);
              }
            }
          }

          var bankCode = bankAccount.TransitNo != null ? bankAccount.TransitNo : '';
          setBankCode(bankCode);
          props.onUpdateBankAccount('BankCode', bankCode);

          var aba = bankAccount.ABA != null ? bankAccount.ABA : '';
          setInstitution(aba);
          props.onUpdateBankAccount('Institution', aba);

          var postalCode = bankAccount.PostalCode != null ? bankAccount.PostalCode : '';
          setZipCode(postalCode);
          props.onUpdateBankAccount('ZipCode', postalCode);

          var messageCurrency = '';
          if (validateIbanCurrency(bankAccount.AccountCurrencyID)) {
            setCurrency(bankAccount.AccountCurrencyID);
            props.onUpdateBankAccount('CurrencyID', bankAccount.AccountCurrencyID);
            setIBANLoaded(true);
          }
          else {
            setCurrency('');
            props.onUpdateBankAccount('CurrencyID', '');
            messageCurrency = " " + getResource('IbanValidCurrency');
          }


          showModal(getResource('IbanValidTitle'), getResource('IbanValid') + messageCurrency);



        } else {
          let message = json.Message;
          showModal(getResource('IbanValidTitle'), message);
        }
      });
  }

  const validateIbanCurrency = (currencyID: string) => {
    var valid = false;
    if (props.currencies != null) {
      var ccy = props.currencies.find((c: any) => c.CurrencyID == currencyID);
      if (ccy != null) {
        valid = true;
      }
    }

    return valid;
  }

  return (
    <div className={props.canEdit ? '' : 'readonly'}>
      <div className="row center-md">
        <div className="col-md-12">
          <div className="row">

            <div className="col-md-12 col-xs-12">
              <div className="row">
                <div className="col-md-5 col-xs-12">
                  <div className="row">
                    <span className="input-label">{getResource('Label.Country') + '(*)'}</span>
                  </div>
                  <div className="row">
                    {/* <Dropdown
                      name="country"
                      title={getResource('SelectCountry')}
                      list={props.countries}
                      select={{ value: countryID }}
                      onChange={countryChangeHandler}
                      errorMessage={getResource('DataMsgRequired')}
                      className={formatValidInputClass(validations.countryID)}
                    /> */}
                    <AutoComplete
                      allowClear
                      options={countries}
                      className={formatValidInputClass(validations.countryID)}
                      placeholder={getResource('SelectCountry')}
                      notFoundContent={getResource('PlaceHolder.NoResults')}
                      value={countryID}
                      onSelect={countryChangeHandler}
                      onSearch={onSearch}
                    />
                  </div>
                </div>
                {props.canEdit && afexBanksSetup && !needsIBAN && (
                <div className="col-md-5 col-xs-12">
                  <div className="row">{getResource('Label.SearchBy')}</div>
                  <div className="row">
                    <div className="col-md-2 col-xs-12">
                      <RadioButton changed={radioChangeHandler} id="1" isSelected={searchBy === 'bank'} label={getResource('Label_Bank')} value="bank" />
                    </div>
                    <div className="col-md-2 col-xs-12">
                      <RadioButton changed={radioChangeHandler} id="2" isSelected={searchBy === 'swift'} label={getResource('Label_Swift')} value="swift" />
                    </div>
                  </div>
                </div>
                )}
              </div>
            </div>

            <div className="col-md-12 col-xs-12">
              <div className="col-md-5 col-xs-12">
                <div className="row">
                  <span className="input-label">{getResource('Label.BankName') + '(*)'}</span>
                </div>
                {(!props.canEdit || needsIBAN) && (
                  <div className="row">
                    <input type="text" value={bankDescription}></input>
                  </div>
                )}
              </div>

              {(props.canEdit && !needsIBAN) && (
                <div className="row">
                  <div className="col-md-5 col-xs-12">
                    <AutoComplete
                      allowClear
                      options={banks}
                      notFoundContent={getResource('PlaceHolder.NoResults')}
                      placeholder={getResource('PlaceHolder.BankName')}
                      open={openBanks}
                      onSelect={onSelectBank}
                      onChange={onChange}
                      value={bankDescription}
                      onSearch={handleSearchBank}
                      className={formatValidAutocompleteClass(validations.bankName)}
                      onBlur={() => setOpenBanks(false)}
                    />
                    {/*(bankName == undefined || bankName == '')   &&
                    <div className="row">
                      <div className="ant-form-item-explain ant-form-item-explain-error">
                        <div role="alert">{getResource('DataMsgRequired')}</div>
                      </div>
                    </div>*/
                    } 
                  </div>
                  <div className="col-md-1 col-xs-12 start-md">
                    <Button type="search" onClick={onSearchBank}>
                      <SearchOutlined />
                    </Button>
                  </div>
                </div>
              )}
            </div>

            <div className="col-md-12 col-xs-12">
              <div className="col-md-5 col-xs-12">
                <div className="row">
                  <span className="input-label">{getResource('Label.BranchName') + '(*)'}</span>
                </div>
                {(!props.canEdit || needsIBAN) && (
                  <div className="row">
                    <input type="text" value={branchDescription}></input>
                  </div>
                )}
              </div>
              {(props.canEdit && !needsIBAN) && (
                <div className="row">
                  <div className="col-md-5 col-xs-12">
                    <AutoComplete
                      allowClear
                      options={branches}
                      notFoundContent={getResource('PlaceHolder.NoResults')}
                      placeholder={getResource('PlaceHolder.BranchName')}
                      open={openBranches}
                      onSelect={onSelectBranch}
                      onChange={onChangeBranch}
                      value={branchDescription}
                      onSearch={handleSearchBranch}
                      className={formatValidAutocompleteClass(validations.branchName)}
                      onBlur={() => setOpenBranches(false)}
                    />
                    {/*(branchName == undefined || branchName == '')  &&
                    <div className="row">
                      <div className="ant-form-item-explain ant-form-item-explain-error">
                        <div role="alert">{getResource('DataMsgRequired')}</div>
                      </div>
                    </div>*/
                    } 
                  </div>
                  <div className="col-md-1 col-xs-12 start-md">
                    <Button type="search" onClick={onSearchBranches}>
                      <SearchOutlined />
                    </Button>
                  </div>
                </div>
              )}
            </div>

            <div className="col-md-12 col-xs-12 separator-top">
              <div className="row">
                <div className="col-md-5 col-xs-12">
                  <div className="row">
                    <span className="input-label">{getResource('Label.Currency') + '(*)'}</span>
                  </div>
                  <div className="row">
                    <Dropdown
                      name="currency"
                      title={getResource('SelectCurrency')}
                      list={props.currencies}
                      select={{ value: currency }}
                      onChange={currencyChangeHandler}
                      errorMessage={getResource('DataMsgRequired')}
                      className={formatValidInputClass(validations.currency)}
                      disabled={needsIBAN}
                    />
                  </div>
                </div>
                <div className="col-md-5 col-xs-12">
                  <div className="row">
                    <span className="input-label">{getResource('Label.AccountIBAN') + '(*)'}</span>
                  </div>
                  <div className="row">
                    <div className="col-md-10 col-xs-12">
                      <Input type="text" value={accountNo} onChange={onChangeAccountNo} errorMessage={getResource('DataMsgRequired')}
                        className={formatValidInputClass(validations.accountNo)}></Input>
                    </div>
                    <div className="col-md-1 col-xs-12 start-md" style={{ marginLeft: '10px' }}>
                      {needsIBAN && <Button type="search" onClick={onValidateIBAN} >{getResource('Button.Translate')}</Button>}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-12 col-xs-12">
              <div className="row">
                <div className="col-md-5 col-xs-12">
                  <div className="row">
                    <span className="input-label">{getResource('Label.BankCode')}</span>
                  </div>
                  <div className="row">
                    <Input type="text" value={bankCode} onChange={onChangeBankCode} disabled={needsIBAN}></Input>
                  </div>
                </div>
                <div className="col-md-5 col-xs-12">
                  <div className="row">
                    <span className="input-label">{getResource('Label.SWIFTBIC')}</span>
                  </div>
                  <div className="row">
                    <Input type="text" value={swift} onChange={onChangeSWIFT} disabled={needsIBAN}></Input>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-12 col-xs-12">
              <div className="col-md-5 col-xs-12">
                <div className="row">
                  <span className="input-label">{getResource('Label.InstitutionABA')}</span>
                </div>
                <div className="row">
                  <Input type="text" value={Institution} onChange={onChangeInstitution} disabled={needsIBAN}></Input>
                </div>
              </div>
            </div>

            <div className="col-md-12 col-xs-12 separator-top">
              <div className="row">
                <div className="col-md-5 col-xs-12">
                  <div className="row">
                    <span className="input-label">{getResource('Label.Address1')}</span>
                  </div>
                  <div className="row">
                    <Input type="text" value={address1} onChange={onChangeAddress1} disabled={needsIBAN}></Input>
                  </div>
                </div>
                <div className="col-md-5 col-xs-12">
                  <div className="row">
                    <span className="input-label">{getResource('Label.Address2')}</span>
                  </div>
                  <div className="row">
                    <input type="text" value={address2} onChange={onChangeAddress2}></input>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-12 col-xs-12">
              <div className="row">
                <div className="col-md-5 col-xs-12">
                  <div className="row">
                    <span className="input-label">{getResource('Label.City') + '(*)'}</span>
                  </div>
                  <div className="row">
                    <Input type="text" value={city} onChange={onChangeCity} errorMessage={getResource('DataMsgRequired')}
                      className={formatValidInputClass(validations.city)} disabled={needsIBAN}></Input>
                  </div>
                </div>
                <div className="col-md-5 col-xs-12">
                  <div className="row">
                    <span className="input-label">{getResource('Label.ProvinceState') + '(*)'}</span>
                  </div>
                  <div className="row">
                    {!props.canEdit && <input type="text" value={stateName}></input>}
                    {props.canEdit && (
                      <Dropdown name="state" title={getResource('SelectState')} list={states} select={{ value: stateID }}
                        onChange={stateChangeHandler} errorMessage={getResource('DataMsgRequired')}
                        className={formatValidInputClass(validations.stateID)} />
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-12 col-xs-12">
              <div className="col-md-5 col-xs-12">
                <div className="row">
                  <span className="input-label">{getResource('Label.PostalZip') + '(*)'}</span>
                </div>
                <div className="row">
                  <Input type="text" value={zipCode} onChange={onChangeZipCode} errorMessage={getResource('DataMsgRequired')}
                    className={formatValidInputClass(validations.zipCode)}></Input>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BankAccountInfo;
