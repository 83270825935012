import { RegularPayment } from '../components/payments/regularPayments/RegularPayments';
import { genericCallWithBody, genericCallWithBodyAndResponseDecoded, genericGetWithParameters } from './Networking';

interface RegularPaymentsResponse {
  RegularPayment: RegularPayment[];
}

export const getListOfRegularPayments = async (customerID: number, dateFormated: boolean): Promise<RegularPaymentsResponse | undefined> => {
  try {
    const params = {
      CustomerID: customerID,
      DateFormated: dateFormated
    };
    const response = await genericGetWithParameters('Payment/GetListOfRegularPayments', params);
    return response;
  } catch (error) {
    console.error(error);
  }
};

export interface SaveRegularPayment {
  objRegularPayment: objRegularPayment;
  CustomerID: string;
  CustomerContactID: string;
  UpdatedBy: string;
}

interface objRegularPayment {
  RegularPaymentID: number;
  //"BranchID": 1,
  IssueDate: string;
  //"RegularPaymentOrigin": "Online Deal",
  CustomerRefNo: string;
  ExternalComments: string;
  InternalComments: string;
  NotificationMethod: string;
  //"ConfirmedByClient": 0,
  //"ConfirmedTS": "2020-07-31T19:03:04.483228-04:00",
  SettlementCurrencyID: string;
  SettlementInstrument: string;
  SettlementDeliveryMethod: string;
  SettlementBankAccount: number;
  SettlementNostroAccount: number;
  ThirdPartyBeneficiaryPayment: number;
  PaymentCurrencyID: string;
  PaymentBeneficiaryID: number;
  //"PaymentBeneficiaryType": 0,
  PaymentBeneficiaryBankAccountID: number;
  PaymentAmount: number;
  CurrencyID: string;
  Status: string;
  TransferType: string;
  TransferDay: string;
  TransferMonthlyOnDay: number | string | null;
  TransferStartingDate: string;
  TransferEndingType: string;
  TransferEndingAfterOccurrences: number | null;
  TransferEndingOn: string | null;
  LastExecuted: string;
  NextExecute: string;
  BeneficiaryName: string;
  UpdateToken: string;
}

export const saveRegularPayment = async (model: SaveRegularPayment) => {
  try {
    const response = await genericCallWithBody('POST', 'Payment/SaveRegularPayment', model);
    return response;
  } catch (error) {
    console.error(error);
  }
};
export async function getListOfPaymentOrderImportRecords(customerID: string) {
  try {
    let params = {
      "CustomerID": customerID
    };

    let responseJWT = await genericGetWithParameters('Payment/GetListOfPaymentOrderImportRecords', params);
    return responseJWT
  } catch (error) {
    console.error(error);
  }
}
export async function importPaymertOrdersTemplate(model: any) {
  try {
    const response = await genericCallWithBodyAndResponseDecoded('POST', 'Payment/ImportPaymertOrdersTemplate', model);
    return response;
  } catch (error) {
    console.error(error);
  }
}