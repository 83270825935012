import { Column } from '@ant-design/charts';
import { Breadcrumb } from 'antd';
import React, { FunctionComponent, useEffect, useState } from 'react';
import CurrencyFlag from 'react-currency-flags';
import { last12MonthsTurnover } from '../../../api/NetworkingWidget';
import { formatAmount } from '../../../helpers/FormatHelper';
import { getResource } from '../../../helpers/ResourcesHelper';
/**customerID
width
height */

interface Last12MonthsProps {
    customerID: any,
    width: string,
    height: string,
}

const Last12MonthsTurnover: FunctionComponent<Last12MonthsProps> = (props: any) => {
    const [last12MonthsTurnoverList, setLast12MonthsTurnoverList] = useState([] as any);

    const [config, setConfig] = useState({
        data: [],
        isGroup: false,
        xField: 'yyyyMM',
        yField: 'MonthlyTotal',
        seriesField: 'MonthlyTotal',
        label: {
            position: 'middle',
            content: function content(item: any) {
                return item.value.toFixed(2);
            },
            style: {
                fill: 'transparent',
            },
        },
        color: '#2AC9DB',
        legend: false,
    } as any);
    useEffect(() => {
        last12MonthsTurnover(props.customerID).then(
            (response) => {
                if (response !== undefined) {
                    //console.log("last12MonthsTurnover: " + JSON.stringify(response));
                    //console.log(response);
                    response.Last12MonthsTurnover.forEach((element: any) => {
                        element.id = element.yyyyMM + ' ' + element['MonthlyTotal'];
                        element.label = element.yyyyMM
                        element.value = element['MonthlyTotal'];
                    });
                    setLast12MonthsTurnoverList(response.Last12MonthsTurnover);
                }
            }
        );
    }, [props.customerID])

    useEffect(() => {
        setConfig({
            data: last12MonthsTurnoverList,
            isGroup: false,
            xField: 'yyyyMM',
            yField: 'MonthlyTotal',
            seriesField: 'MonthlyTotal',
            label: {
                position: 'middle',
                content: function content(item: any) {
                    return item.value.toFixed(2);
                },
                style: {
                    fill: 'transparent',
                },
            },
            color: '#2AC9DB',
            legend: false
        });
    }, [last12MonthsTurnoverList])

    return (
        <div
            style={{
                width: props.width,
                height: props.height,
                //overflow: 'auto',
                //backgroundColor: 'yellow',
                flexDirection: 'column',
                padding: '10px 5px',
                //
                display: 'flex',
                alignItems: 'stretch',
                margin: '0 auto',
                //borderRadius: '10px',
                //border: '2px solid #E7E7E7',
            }}
        >
            <div
                className='title-widget'
                style={{
                    display: 'block',
                    textAlign: 'center'
                }}
            >
                <div className="breadcrumb-container">
                    <Breadcrumb separator="">
                        <Breadcrumb.Item >
                            {getResource('Js_Action_Payments')} {last12MonthsTurnoverList.length !== 0 ? '(' + formatAmount(last12MonthsTurnoverList[0].scale) + "'s) " : ''} {getResource('Label.ForLast12Months')} {last12MonthsTurnoverList.length > 0 ? last12MonthsTurnoverList[0].baseccy + ' ' : ''}
                            {last12MonthsTurnoverList.length > 0 ? <CurrencyFlag currency={last12MonthsTurnoverList[0].baseccy} width={16} /> : null}</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            </div>
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    margin: 0,
                    overflow: 'hidden',
                    paddingTop: 10,
                    paddingBottom: 5
                }}
            >
                <Column {...config} style={{ height: '100%', width: '100%' }} />
            </div>
        </div>
    );
}
export default Last12MonthsTurnover;