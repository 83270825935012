import { Steps } from 'antd';
import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import useCustomerCurrencies from '../../api/hooks/useCustomerCurrencies';
import AppContext from '../../App.context';
import { formatDate, formatToCurrency } from '../../helpers/FormatHelper';
import { getResource } from '../../helpers/ResourcesHelper';
import { RegularPayment } from '../payments/regularPayments/RegularPayments';
import Summary from '../payments/summary/Summary';
import SendApprovals from '../payments/validates/SendApprovals';
import CurrencyDetails, { ICustomerCurrencies } from './currencyDetails/CurrencyDetails';
import OrdersDisbursement from './ordersDisbursement/OrdersDisbursement';
const { Step } = Steps;

interface OrdersProps {
  dealID: number;
  regularPayment?: RegularPayment | null;
  objOrderScreen?: {} | null;
  fromBalance?: boolean;
  onBackOrder?: Function;
}

export interface ApprovalOption {
  Approvals: string;
  DealHeaderID: number;
}

const OrdersScreen: FunctionComponent<OrdersProps> = (props) => {
  const [updating, setUpdating] = useState(false);
  const { showModal } = useContext(AppContext);
  const [currentScreen, setCurrentScreen] = useState(0);
  const [customerCurrencies] = useCustomerCurrencies();

  //lo q tengo
  const [buyCurrencyID, setBuyCurrencyID] = useState('');
  const [sellCurrencyID, setSellCurrencyID] = useState('');
  const [valueDate, setValueDate] = useState('');
  const [settlementMehotd, setSettlementMethod] = useState<any>();
  const [buyCurrencyItem, setBuyCurrencyItem] = useState();
  //
  const [dealId, setDealId] = useState(0);
  const [isWorkInProgress, setIsWorkInProgress] = useState(false);
  //
  const [templateSelected, setTemplateSelected] = useState<any>();

  //Approvals
  const [showSendApprovals, setShowSendApprovals] = useState(false);
  const [approvalOptions, setApprovalOptions] = useState<ApprovalOption>({ Approvals: '', DealHeaderID: 0 });

  useEffect(() => {
    setDealId(props.dealID);
  }, [updating]);

  useEffect(() => {
    if (customerCurrencies !== undefined && (customerCurrencies as ICustomerCurrencies).payment !== undefined) {
      if (props.regularPayment != null) {
        const buycurrencyItemAux = (customerCurrencies as ICustomerCurrencies).payment.find((currency: any) => currency.value === props.regularPayment!['Payment Currency ID']);
        if (buycurrencyItemAux != null) {
          setBuyCurrencyItem(buycurrencyItemAux);
        } else {
          showModal(getResource('Js_MessageError_TitleSubmit'), getResource('Js_Message_Account'));
          props.onBackOrder!();
        }
      }
    }
  }, [customerCurrencies]);

  useEffect(() => {
    if (props.regularPayment != null) {
      console.log(props.regularPayment);
      const customerID = JSON.parse(localStorage.getItem('UserSession')!)['CustomerID'];
      const contactID = localStorage.getItem('ContactID');
      const valueDate = formatDate(new Date());
      const template = {
        DealTemplateID: 0,
        CustomerId: customerID,
        ContactId: contactID,
        TemplateName: 'Regular Payment',
        SellAmount: props.regularPayment['Payment Amount'],
        SellCurrency: props.regularPayment['Settlement CurrencyID'],
        BuyCurrency: props.regularPayment['Payment Currency ID'],
        Disbursements: [
          {
            BeneficiaryPaymentID: props.regularPayment['Payment Beneficary ID'],
            Instrument: 'Electronic Funds Transfer',
            BeneficiaryID: props.regularPayment['Payment Beneficary ID'],
            BeneficiaryBankAccountID: props.regularPayment['Payment Beneficiary BankAccount ID'],
            ValueDate: valueDate,
            SellAmount: props.regularPayment['Payment Amount'],
            PaymentReference: '',
            WireItemFee: 0,
            WireItemFeeCurrencyID: null,
            InterfaceBalanceAccountId: null,
            Comments: null,
            Attachments: [],
            DeliveryMethod: '',
            CurrencyID: null,
            BeneficiaryName: null,
            BankName: null,
            AccountNo: null,
            BeneficiaryType: null,
            BeneficiaryStatus: null,
            BankAccountID: 0
          }
        ],
        UpdateToken: '',
        SendingToBeneficiary: `Sending ${formatToCurrency(props.regularPayment['Payment Amount'], props.regularPayment['Settlement CurrencyID'])} to 1 beneficiary(ies)`,
        PayIn: `Paying in ${formatToCurrency(props.regularPayment['Settlement CurrencyID'], props.regularPayment['Payment Currency ID'])}`
      };
      setTemplateSelected(template);
      setValueDate(valueDate);
      const settlementMethod = {
        details: [
          { label: 'Currency', description: props.regularPayment['Settlement CurrencyID'] },
          { label: 'Instrument', description: 'Electronic Funds Transfer' },
          { label: 'Delivery Method', description: 'ACH' }
        ],
        label: `${props.regularPayment['Settlement CurrencyID']} - Electronic Funds Transfer - ${props.regularPayment['Settlement CurrencyID']}`
      };
      setBuyCurrencyID(props.regularPayment['Payment Currency ID']);
      setSellCurrencyID(props.regularPayment['Settlement CurrencyID']);
      setSettlementMethod(settlementMethod);
      setCurrentScreen(1);
    }
  }, [props.regularPayment]);

  const onAddDisbursement = (buyCurrencyID: string, buyCurrencyItem: any, sellCurrencyID: string, valueDate: string, settlementMethod: string) => {
    setBuyCurrencyID(buyCurrencyID);
    setBuyCurrencyItem(buyCurrencyItem);
    setSellCurrencyID(sellCurrencyID);
    setValueDate(valueDate);
    setSettlementMethod(settlementMethod);
    setCurrentScreen(1);
  };

  const onFinishDisbursement = (dealId: number, isWorkInProgress: boolean, appOptions: ApprovalOption) => {
    if (dealId > 0) {
      setDealId(dealId);
      setCurrentScreen(2);
      setIsWorkInProgress(isWorkInProgress);
      if (appOptions.Approvals !== '') {
        setApprovalOptions(appOptions);
        setShowSendApprovals(true);
      }
    }
  };

  return (
    <React.Fragment>
      <div className="screen-container slide-r-l">
        <Steps current={currentScreen}>
          <Step title={getResource('Label.CurrencyDetails')} />
          <Step title={getResource('Disburse')} />
          <Step title={getResource('ViewPrintSummary')} />
        </Steps>
      </div>
      {currentScreen === 0 && (
          <CurrencyDetails
            onAddDisbursement={onAddDisbursement}
            useTemplate={(template: any) => {
              setTemplateSelected(template);
              setCurrentScreen(1);
            }}
            objOrderScreen={props.objOrderScreen}
          fromBalance={props.fromBalance}
        />
      )}
      {currentScreen === 1 && (
        <OrdersDisbursement
          buyCurrencyID={buyCurrencyID}
          buyCurrencyItem={buyCurrencyItem}
          sellCurrencyID={sellCurrencyID}
          valueDate={valueDate}
          settlementMehotd={settlementMehotd}
          onFinish={onFinishDisbursement}
          onBack={() => {
            setCurrentScreen(0);
            setBuyCurrencyID('');
            setBuyCurrencyItem(undefined);
            setSellCurrencyID('');
            setSettlementMethod(undefined);
            setTemplateSelected(undefined);
            props.onBackOrder!();
          }}
          fromTemplate={templateSelected}
          fromBalance={props.fromBalance}
        />
      )}
      {currentScreen === 2 && (
        <Summary
          useConfirmationButton={!isWorkInProgress}
          useSaveTemplateButton={false}
          /*saveTemplateModalProps={{
          ButtonLabel: 'Add Order as template',
          Modaltitle: 'New Order Template'
        }}*/
          dealHeaderID={dealId}
          useNewTradeButton={true}
          onClickNewTrade={() => {
            setCurrentScreen(0);
          }}
        />
      )}
      <SendApprovals
        show={showSendApprovals}
        dealHeaderID={approvalOptions.DealHeaderID}
        onClose={() => {
          setShowSendApprovals(false);
        }}
        approvals={approvalOptions.Approvals}
      />
    </React.Fragment>
  );
};

export default OrdersScreen;
