import { Breadcrumb, Modal } from 'antd';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { last10Transactions } from '../../../api/NetworkingWidget';
import { getResource } from '../../../helpers/ResourcesHelper';
//import ViewDealDetailScreen from '../../payments/ViewDealDetailScreen';
import Button from '../../inputs/Button';
import Summary from '../../payments/summary/Summary';
import Transaction from './Transaction';

interface Last10TransactionsProps {
    width: string,
    height: string,
}

const Last10Transactions: FunctionComponent<Last10TransactionsProps> = (props: any) => {

    const [listDeals, setListDeals] = useState([] as any);
    const [objDealClicked, setObjDealClicked] = useState({} as any);
    const [showTransactionDetail, setShowTransactionDetail] = useState(false);

    useEffect(() => {
        const customerID = JSON.parse(localStorage.getItem('UserSession')!).CustomerID;
        last10Transactions(customerID).then(
            (response) => {
                console.log("last10Transactions: " + JSON.stringify(response));
                console.log(response);
                setListDeals(response.Last10Transactions);
            }
        );
    }, [])

    const clickedADeal = (deal: any) => {
        console.log("deal ------------------ " + JSON.stringify(deal));
        /*
            {
                Buy Amount: "1,000.00 USD",
                Deal: 11106,
                Rate: 1.3501,
                Sell Amount: "1,350.10 CAD",
                Status: "Status : Accepted Paid status : Fully Paid Disbursement status : Fully Disbursed",
                Type: "Spot",
                Value Date: "2020/10/09"
            }
        */
        if (deal !== undefined) {
            setObjDealClicked(deal);
            setShowTransactionDetail(true);
        }
    }

    const closeDialogTransactionDetail = () => {
        setObjDealClicked({});
        setShowTransactionDetail(false);
    }

    return (
        <div
            style={{
                width: props.width,
                height: props.height,
                overflow: 'auto',
                //backgroundColor: 'yellow',
                flexDirection: 'column',
                padding: '10px 5px',
                //
                display: 'flex',
                alignItems: 'stretch',
                margin: '0 auto',
                //borderRadius: '10px',
                //border: '2px solid #E7E7E7',
            }}
        >
            <div className="breadcrumb-container">
                <Breadcrumb separator="">
                    <Breadcrumb.Item >{getResource('Tittle.Last10Transactions')}</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <ul
                style={{
                    //backgroundColor : 'red', 
                    listStyleType: 'none',
                    margin: 0,
                    padding: 2
                }}
            >
                {listDeals != null &&
                    listDeals.map(
                    (item: any, index: number) =>
                        <Transaction
                            deal={item}
                            key={index}
                            onClickTransaction={clickedADeal}
                        />
                )
                }
            </ul>
            <Modal
                title={
                    <div className="breadcrumb-container">
                        <Breadcrumb separator="">
                            <Breadcrumb.Item >{getResource('Title.DealDetails')}</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                }
                centered
                visible={showTransactionDetail}
                footer={null}
                onCancel={closeDialogTransactionDetail}
                maskClosable={false}
                width={'50%'}
                zIndex={1020}
                closable={false}
            >
                <Summary dealHeaderID={objDealClicked.DealID} useNewTradeButton={false} useConfirmationButton={true} />
                <div className="row">
                    <div className="col-md-9"></div>
                    <div className="col-md-3">
                        <Button id="confirmation-OK" type="primary" onClick={closeDialogTransactionDetail}>
                            {getResource('Button.Ok')}
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    );
}
export default Last10Transactions;