import { genericCallWithBody, genericDeleteWithParameters, genericGetWithParameters, genericGetWithParametersNoEncrypted } from './Networking';

export async function getAttachments(linkType: any, linkID: any) {
  try {
    if (linkType && linkID) {
      let params = {
        'LinkType': linkType,
        'LinkId': linkID
      }
      let response = await genericGetWithParameters('Attachment/GetAttachmentByLink', params);
      return response;
    } else {
      return {};
    }
  } catch (error) {
    console.error(error);
  }
}

export async function getAttachment(id: any) {
  try {
    let params = {
      'id': id,
    }
    let response = await genericGetWithParametersNoEncrypted('Attachment/GetAttachmentContent', params);
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function deleteE2EAttachment(id: any) {
  try {
    let params = {
      'attachmentId': id,
    }
    let response = await genericDeleteWithParameters('Attachment/Delete', params);
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function uploadAttachment(description: any, linkType: any, linkID: any, fileName: any, fileContent: any) {
  try {
    let userID = localStorage.getItem('UserID');
    var date = new Date();
    let isoDate = date.toISOString();
    let addFileModel = {
      'Description': description,
      'UploadedBy': userID,
      'LinkType': linkType,
      'LinkId': linkID,
      'UpdateToken': '0',
    };
    //AddFileCall
    let addFileResponse = await genericCallWithBody('POST', 'Attachment/AddFile', addFileModel);
    if (addFileResponse.httpStatusCode === 200) {
      let uploadFileModel = {
        'Id': addFileResponse.id,
        'Filename': fileName,
        'Description': addFileResponse.description,
        'UploadedBy': userID,
        'UploadedOn': isoDate,
        'FileContent': fileContent,
        'UpdateToken': addFileResponse.updateToken,
      };
      //UploadFileCall
      let uploadFileResponse = await genericCallWithBody('POST', 'Attachment/UploadFile', uploadFileModel);
      return uploadFileResponse;
    } else {
      return addFileResponse;
    }
  } catch (error) {
    console.error(error);
  }
}

export async function getAttachmentsBySubEntityType(subEntityType: any) {
  try {
    let params = {
      subEntityType: subEntityType,
      countryId: '',
    };
    let response = await genericGetWithParameters('AttachmentsBySubEntityType/GetAttachmentsBySubEntityType', params);
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function getAttachmentBySubEntityTypeByID(attachmentBySubEntityTypeID: any) {
  try {
    let params = {
      attachmentBySubEntityTypeID: attachmentBySubEntityTypeID
    }
    let response = await genericGetWithParameters('AttachmentsBySubEntityType/GetAttachmentBySubEntityTypeByID', params);
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function deleteAttachmentsBySubEntityType(attachmentBySubEntityTypeID: any) {
  let params = {
    'attachmentBySubEntityTypeID': attachmentBySubEntityTypeID
  };
  let response = await genericDeleteWithParameters('AttachmentsBySubEntityType/Delete', params);
  return response;
}

export async function saveAttachmentsBySubEntityType(objAttachmentsBySubEntityType: any) {
  var method = '';
  var apiName = '';
  method = 'POST';
  apiName = 'AttachmentsBySubEntityType/Save';
  let response = await genericCallWithBody(method, apiName, objAttachmentsBySubEntityType);
  return response;
}

export const uploadAttachmentWithoutLink = async (fileName: any, description: any, fileContent: any, uploadedBy: any, uploadedOn: any) => {
  try {
    const uploadFileModel = {
      'Id': '00000000-0000-0000-0000-000000000000',
      'Filename': fileName,
      'Description': description,
      'UploadedBy': uploadedBy,
      'UploadedOn': uploadedOn,
      'FileContent': fileContent,
      'UpdateToken': 0,
    };
    const uploadFileResponse = await genericCallWithBody('POST', 'Attachment/UploadFile', uploadFileModel);
    return uploadFileResponse;
  }
  catch (error) {
    console.error(error);
  }
}