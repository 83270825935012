import { RateWatch } from '../components/models/RateWatch';
import { genericCallWithBodyAndResponseDecoded, genericGetWithParameters } from './Networking';

export async function getListOfOnlineOrders(customerID: number, rateWatchStatus: string, startIssueDate: string, endIssueDate: string, dealType: string) 
{
    try {
        let params = {
            "CustomerID": customerID,
            "RateWatchStatus": rateWatchStatus,
            "StartIssueDate": startIssueDate,
            "EndIssueDate": endIssueDate,
            "DealType": dealType
        }

        let response = await genericGetWithParameters('RateWatch/GetListOfOnlineOrders', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function saveRateWatch(rateWatch: RateWatch) {
    try {
        let method = 'POST';
        let apiName = 'RateWatch/Post';
        let response = await genericCallWithBodyAndResponseDecoded(method, apiName, rateWatch);
        return response;
    } catch (error) {
        console.error(error);
    }
}


export async function getRateWatchById(rateWatchID: number) {
    try {
        let params = {
            "rateWatchID": rateWatchID
        }

        let response = await genericGetWithParameters('RateWatch/GetRateWatchByID', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}
/*
export async function getRateWatchConfirmation(rateWatchID){
    try {
        let params = {
            'RateWatchID': rateWatchID,
        }
        let response = await genericGetWithParametersNoEncrypted('RateWatch/GetRateWatchConfirmation',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}*/