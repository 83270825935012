import { Steps } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import useCurrencies from '../../../api/hooks/useCurrencies';
import { usePrevious } from '../../../api/hooks/usePrevious';
import { getRateWatchById } from '../../../api/NetworkingRateWatches';
import AppContext from '../../../App.context';
import { getResource } from '../../../helpers/ResourcesHelper';
import Button from '../../inputs/Button';
import { OnlineOrder } from '../../models/OnlineOrder';
import { RateWatch } from '../../models/RateWatch';
import CurrenciesAndRates from './CurrenciesAndRates';
import OrderType from './OrderType';

const moment = require('moment');

const AddEditRateWatch = (props: any) => {

  const { showModal } = useContext(AppContext);
  const { Step } = Steps;
  const [rateWatchIdSelected, setRateWatchIdSelected] = useState(props.rateWatchId);
  const prevRateWatchIdSelected = usePrevious(rateWatchIdSelected);
  const [rateWatchById, setRateWatchById] = useState<RateWatch | null>(null);

  const [updating, setUpdating] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [validationFieldStep1, setValidationFieldStep1] = useState(0);
  const [validationFieldStep2, setValidationFieldStep2] = useState(0);

  const [rateWatch, setRateWatch] = useState<RateWatch>({
    'RateWatchID': 0,
    'WeAction': 'We Sell',
    'BuyCurrency': '',
    'SellCurrency': '',
    'BuyAmount1': 0,
    'SellAmount1': 0,
    'CustomerRate1': 0,
    'BuyAmount2': 0,
    'SellAmount2': 0,
    'CustomerRate2': 0,
    'ExecutionDate': '',
    'ExpirationDate': '',
    'RateWatchStatus': 'Outstanding',
    'RateWatchType': 'Order',
    'RateWatchOrigin': '',
    'NotificationMethod': 'Email',
    'CheckSingleRate': true,
    'GoodTillCancelled': true,
    'CreatedBy': '',
    'CreatedOn': '',
    'UpdatedBy': '',
    'UpdatedOn': '',
    'NearDate': '',
    'FarDate': '',
    'OpenFor': 0,
    'DealTypeID': 1,
    'UpdateToken': ''
  });

  const [ratewatchID, setRatewatchID] = useState<number>(0);
  const [updateToken, setUpdateToken] = useState<string | null>('');
  const [createdOnRateWatch, setCreatedOnRateWatch] = useState<string>(moment().format('YYYY/MM/DD'));
  const [createdByRateWatch, setCreatedByRateWatch] = useState<string>(JSON.parse(localStorage.getItem('UserSession')!).PersonID);
  const [updatedOnRateWatch, setUpdatedOnRateWatch] = useState<string>(moment().format('YYYY/MM/DD'));
  const [updatedByRateWatch, setUpdatedByRateWatch] = useState<string>(JSON.parse(localStorage.getItem('UserSession')!).PersonID);

  const [ratewatchtype, setRateWatchtype] = useState<string>('Order');
  const [status, setStatus] = useState<string>('Outstanding');
  const [goodTillCancelled, setGoodTillCancelled] = useState<string>('true');
  const [expiresOn, setExpiresOn] = useState<string | null>('');
  const [notifyMeVia, setNotifyMeVia] = useState<string>('Email');
  const [dealType, setDealType] = useState<string>('Spot');
  const [fordwardOption, setFordwardOption] = useState<string>('days');
  const [forDays, setForDays] = useState<number | null | string>(null);
  const [forStartDate, setForStartDate] = useState<string | null>('');
  const [forEndDate, setForEndDate] = useState<string | null>('');

  const [rateType, setRateType] = useState<string>('single');
  const [currencyBuyList] = useCurrencies();
  const [weWantTo, setWeWantTo] = useState<string>('buy');
  const [buyCurency, setBuyCurency] = useState<string | null>('');
  const [buyAmount, setBuyAmount] = useState<number | null>(null);
  const [sellCurency, setSellCurency] = useState<string | null>('');
  const [sellAmount, setSellAmount] = useState<number | null>(null);
  const [firstRate, setFirstRate] = useState<number | null>(null);
  const [firstAmount, setFirstAmount] = useState<number | null>(null);
  const [secondRate, setSecondRate] = useState<number | null>(null);
  const [secondAmount, setSecondAmount] = useState<number | null>(null);

  const [rateTerms, setRateTerms] = useState<string>();
  const [customerRate, setCustomerRate] = useState<number>();

  const [showCalculateRate, setShowCalculateRate] = useState(false);

  const onUpdateStep = (step: number) => {
    if (step == 2) {
      savingOnlineOrder();
    }
    else {
      setCurrentStep(step);
    };
  };

  const onUpdateRateWatch = (label: keyof typeof rateWatch, value: any) => {
    let rWatch = rateWatch;
    if (rWatch != null) {
      (rWatch[label] as any) = value;
      setRateWatch(rWatch);
    }
  };

  const steps = [
    {
      title: 'Order Type',
      content: (
        <OrderType
          rateWatch={rateWatch}
          onUpdateRateWatch={onUpdateRateWatch}
          onUpdateStep={onUpdateStep}
          validationField={validationFieldStep1}
          lookupTables={props.lookupTables}
        /* beneficiary={beneficiary}
         canEdit={ol_BeneficiaryAddNew || ol_BeneficiaryEdit}
         onUpdateBeneficiary={onUpdateBeneficiary}
         countries={countries}
         beneficiaryTypes={lookUpTables.BeneficiaryType}
         onValidate={onNextClick}
         beneFields={beneFieldsStep1}
         */
        />
      )
    },
    {
      title: 'Currencies and Rates',
      content: <CurrenciesAndRates
        rateWatch={rateWatch}
        onUpdateRateWatch={onUpdateRateWatch}
        onUpdateStep={onUpdateStep}
        validationField={validationFieldStep2}
      /*beneficiary={beneficiary} canEdit={ol_BeneficiaryAddNew || ol_BeneficiaryEdit}
        onUpdateBeneficiary={onUpdateBeneficiary} paymentMethods={outgoingInstruments} beneFields={beneFieldsStep2}
        onUpdateStep={onUpdateStep} */
      />
    }
  ];

  useEffect(() => {
    if (props.rateWatchId !== 0 && prevRateWatchIdSelected !== props.rateWatchId) {
      console.log("ratewatchID que llega -> ", props.rateWatchObj);
      getRateWatchById(props.rateWatchId).then(
        (response) => {
          if (response !== undefined && response !== null && response.RateWatchID > 0) {
            let rateWatchObj: RateWatch = response;
            setRateWatch(rateWatchObj);
            setStatus(rateWatchObj.RateWatchStatus!);
/*
            setRateWatchById(rateWatchObj);

            setRatewatchID(rateWatchObj.RateWatchID!);
            setUpdateToken(rateWatchObj.UpdateToken!);

            setRateWatchtype(rateWatchObj.RateWatchType!);
            setStatus(rateWatchObj.RateWatchStatus!);
            setGoodTillCancelled(rateWatchObj.GoodTillCancelled!.toString());
            setExpiresOn(rateWatchObj.ExpirationDate === null ? '' : rateWatchObj.ExpirationDate!);
            setNotifyMeVia(rateWatchObj.NotificationMethod!);
            setDealType(rateWatchObj.DealTypeID === 1 ? 'Spot' : 'Fordward');
            setFordwardOption((rateWatchObj.OpenFor === null || rateWatchObj.OpenFor === 0) ? 'daterange' : 'days');
            setForDays(rateWatchObj.OpenFor === null || rateWatchObj.OpenFor === 0 ? '' : rateWatchObj.OpenFor!);
            setForStartDate(rateWatchObj.NearDate === null ? '' : rateWatchObj.NearDate!);
            setForEndDate(rateWatchObj.FarDate === null ? '' : rateWatchObj.FarDate!);
            setRateType(rateWatchObj.CheckSingleRate === true ? 'single' : 'oco');
            setBuyCurency(rateWatchObj.BuyCurrency!);
            setSellCurency(rateWatchObj.SellCurrency!);

            if (rateWatchObj.WeAction === "We Sell") {
              setWeWantTo('buy');
              setBuyAmount(parseFloat(formatToCurrency(rateWatchObj.BuyAmount1!.toString())));
              setFirstAmount(rateWatchObj.SellAmount1!);
            } else {
              setWeWantTo('sell');
              setSellAmount(rateWatchObj.SellAmount1!)
              setFirstAmount(rateWatchObj.BuyAmount1!);
            }

            getRates();

            let asudhsagksdg = firstRate;
            setFirstRate(rateWatchObj.CustomerRate1!);
            let ldskjghdkjg = firstRate;
            calculateAmountsForRate1();
            let afsdafasdf = firstRate;

            if (rateWatchObj.CheckSingleRate === false) {
              setSecondRate(rateWatchObj.CustomerRate2!);
              calculateAmountsForRate2();
            }
            */
          }
        }
      );
    }
  }, [updating, props]);

  const onNextClick = () => {
    switch (currentStep) {
       case 0:
        setValidationFieldStep1(validationFieldStep1 + 1);
         break;
       case 1:
        setValidationFieldStep2(validationFieldStep2 + 1);
         break;
       default: setCurrentStep(currentStep + 1); break;
    }
  };

  const onPreviousClick = () => {
    setValidationFieldStep1(0);
    setValidationFieldStep2(0);
    setCurrentStep(currentStep - 1);
  };

  const savingOnlineOrder = () => {
    if (true) {//validateFields()) {
      let order: OnlineOrder = {
        OrderID: rateWatch.RateWatchID!,
        OrderType: rateWatch.RateWatchType!,
        Status: rateWatch.RateWatchStatus!,
        GoodTilCancelled: rateWatch.GoodTillCancelled!.toString() === 'true' ? 1 : 0,
        ExpirationDate: rateWatch.ExpirationDate!,
        NotificationMethod: rateWatch.NotificationMethod!,
        DealType: rateWatch.DealTypeID! == 1 ? 'Spot' : 'Forward',
        DealTypeID: rateWatch.DealTypeID!,
        OpenFor: rateWatch.OpenFor === null ? 0 : rateWatch.OpenFor,
        NearDate: rateWatch.NearDate === null ? '' : rateWatch.NearDate,
        FarDate: rateWatch.FarDate === null ? '' : rateWatch.FarDate,
        CheckSingleRate: rateWatch.CheckSingleRate! ? 1 : 0,
        BuyCurrency: rateWatch.BuyCurrency === null ? '' : rateWatch.BuyCurrency,
        SellCurrency: rateWatch.SellCurrency === null ? '' : rateWatch.SellCurrency,
        BuyAmount1: rateWatch.WeAction == 'We Sell' ? (rateWatch.BuyAmount1 === null ? 0 : rateWatch.BuyAmount1) : (rateWatch.SellAmount1 === null ? 0 : rateWatch.SellAmount1),
        BuyAmount2: ((rateWatch.WeAction == 'We Sell') && (!rateWatch.CheckSingleRate)) ? rateWatch.BuyAmount2 : rateWatch.SellAmount2,
        SellAmount1: rateWatch.WeAction == 'We Sell' ? (rateWatch.SellAmount1 === null ? 0 : rateWatch.SellAmount1) : (rateWatch.BuyAmount1 === null ? 0 : rateWatch.BuyAmount1),
        SellAmount2: ((rateWatch.WeAction == 'We Sell') && (!rateWatch.CheckSingleRate)) ? rateWatch.SellAmount2 : rateWatch.BuyAmount2,
        CustomerRate1: rateWatch.CustomerRate1 === null ? 0 : rateWatch.CustomerRate1,
        CustomerRate2: !rateWatch.CheckSingleRate ? (rateWatch.CustomerRate2 === null ? 0 : rateWatch.CustomerRate2) : 0,
        WeAction: rateWatch.WeAction!,
        UpdateToken: rateWatch.UpdateToken!,
        CreatedBy: createdByRateWatch,
        CreatedOn: createdOnRateWatch,
        UpdatedBy: updatedByRateWatch,
        UpdatedOn: updatedOnRateWatch
      }
      props.onSaveClick(order);
    } else {
      showModal(getResource('Js_MessageError_TitleError'), getResource('Js_MessageError_FormValidationNFieldNHighlighted'));
    }
  }

  const validateFields = () => {
    let rateWatchTypeValid: boolean = true;
    let rateWatchStatusValid: boolean = true;
    let goodTillCancelledValid: boolean = true;
    let expirationDateValid: boolean = true;
    let notificationMethodValid: boolean = true;
    let dealTypeValid: boolean = true;
    let forDaysValid: boolean = true;
    let forDateRangeValid: boolean = true;
    let rateTypeValid: boolean = true;
    let buyCurrencyValid: boolean = true;
    let buyAmountValid: boolean = true;
    let sellCurrencyValid: boolean = true;
    let sellAmountValid: boolean = true;
    let firstRateValid: boolean = true;
    let secondRateValid: boolean = true;
    let firstAmountValid: boolean = true;
    let secondAmountValid: boolean = true;

    if (ratewatchtype === '') { rateWatchTypeValid = false; }
    if (status === '') { rateWatchStatusValid = false; }
    if (goodTillCancelled === '') { goodTillCancelledValid = false; }
    if (goodTillCancelled === 'false' && (expiresOn === '' || expiresOn === null)) { expirationDateValid = false; }
    if (notifyMeVia === '') { notificationMethodValid = false; }
    if (dealType === '') { dealTypeValid = false; }
    if (dealType === 'Fordward' && fordwardOption === 'days' && (forDays === '' || forDays === 0 || forDays === null || forDays?.toString() == 'NaN')) { forDaysValid = false; }
    if (dealType === 'Fordward' && fordwardOption === 'daterange' && (forStartDate === '' || forStartDate === null || forEndDate === null || forEndDate === '')) { forDateRangeValid = false; }
    if (rateType === '') { rateTypeValid = false; }
    if (buyCurency === '' || buyCurency === null) { buyCurrencyValid = false; }
    if (buyAmount === null || buyAmount === 0) { buyAmountValid = false; }
    if (sellCurency === '' || sellCurency === null) { sellCurrencyValid = false; }
    if (sellAmount === null || sellAmount === 0) { sellAmountValid = false; }
    if (firstRate === null || firstRate === 0) { firstRateValid = false; }
    if (rateType === 'oco' && (secondRate === null || secondRate === 0)) { secondRateValid = false; }
    if (firstAmount === null || firstAmount === 0) { firstAmountValid = false; }
    if (rateType === 'oco' && (secondAmount === null || secondAmount === 0)) { secondAmountValid = false; }

    return (
      rateWatchTypeValid &&
      rateWatchStatusValid &&
      goodTillCancelledValid &&
      expirationDateValid &&
      notificationMethodValid &&
      dealTypeValid &&
      forDaysValid &&
      forDateRangeValid &&
      rateTypeValid &&
      buyCurrencyValid &&
      buyAmountValid &&
      sellCurrencyValid &&
      sellAmountValid &&
      firstRateValid &&
      secondRateValid &&
      firstAmountValid &&
      secondAmountValid
    )
  }

  const onSaveClick = () => {
    setValidationFieldStep2(validationFieldStep2 + 1);
  }

  const closeModal = () => {
    //Clear
    setRatewatchID(0);
    setUpdateToken('');
    setCreatedOnRateWatch(moment().format('YYYY/MM/DD'));
    setCreatedByRateWatch(JSON.parse(localStorage.getItem('UserSession')!).PersonID);
    setUpdatedOnRateWatch(moment().format('YYYY/MM/DD'));
    setUpdatedByRateWatch(JSON.parse(localStorage.getItem('UserSession')!).PersonID);
    setRateWatchtype('Order');
    setStatus('Outstanding');
    setGoodTillCancelled('true');
    setExpiresOn('');
    setNotifyMeVia('Email');
    setDealType('Spot');
    setFordwardOption('days');
    setForDays(null);
    setForStartDate('');
    setForEndDate('');
    setRateType('single');
    setWeWantTo('buy');
    //setBuyCurency(null);
    setBuyAmount(null);
    //setSellCurency(null);
    setSellAmount(null);
    setFirstRate(null);
    setFirstAmount(null);
    setSecondRate(null);
    setSecondAmount(null);
    setRateTerms('');
    setCustomerRate(0);
    setShowCalculateRate(false);
    props.oncloseAddEddit();
  }

  return (
    <React.Fragment>
      <div className="row"></div>
      <Steps current={currentStep}>
        {steps.map((item) => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>
      <div className="screen-container slide-r-l">{steps[currentStep].content}</div>
      <div >
        <div className="row center-md">
          <div className="col-xs-10 col-md-4">
            <div className="row">
              <Button type="secondary" onClick={() => closeModal()}>
                {getResource('Button.Cancel')}
              </Button>
            </div>
          </div>
          {currentStep > 0 && (
            <div className="col-xs-10 col-md-4">
              <div className="row">
                <Button type="primary" onClick={() => onPreviousClick()}>
                  {getResource('Previous')}
                </Button>
              </div>
            </div>
          )}
          {currentStep < steps.length - 1 && (
            <div className="col-xs-10 col-md-4">
              <div className="row">
                <Button type="primary" onClick={() => onNextClick()}>
                  {getResource('Next')}
                </Button>
              </div>
            </div>
          )}
          {currentStep === steps.length - 1 && status != 'Executed' && (
            <div className="col-xs-10 col-md-4">
              <div className="row">
                <Button type="primary" onClick={() => onSaveClick()}>
                  {getResource('Button.Save')}
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>

    </React.Fragment>
    /*</Modal>*/
  );
}

export default AddEditRateWatch
