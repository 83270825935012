import { useEffect, useState } from 'react';

const useOnlineRights = (onlineRight: string) => {
  const [existRight, setExistRight] = useState(false);

  useEffect(() => {
    let onlineRightsStr = localStorage.getItem('OnlineRights');

    if (onlineRightsStr != null && onlineRightsStr != '') {
      let onlineRights = JSON.parse(onlineRightsStr);

      var right = onlineRights.find((element: any) => element.Right == onlineRight);

      if (right != undefined) {
        setExistRight(true);
      }
    }
  }, [onlineRight]);

  return existRight;
};

export default useOnlineRights;
