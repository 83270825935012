import { genericGetWithParameters } from './Networking';

export async function getInstrumentsByCustomer(customerID: number) {
  try {
    let params = { customerID: customerID };
    let response = await genericGetWithParameters('Instrument/GetInstrumentsByCustomer', params);
    return response;
  } catch (error) {
    console.error(error);
  }
}
