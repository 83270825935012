import { LookUpTableJson } from './hooks/useLookUpTables';
import { genericGetWithParameters } from './Networking';

interface GetLookUpTableResponse {
  lookUpTables: LookUpTableJson[];
}

export const getLookUpTable = async (name: string): Promise<GetLookUpTableResponse | undefined> => {
  try {
    const params = {
      LookUpTablesDescription: name
    };
    const response = await genericGetWithParameters('LookUpTable/Get', params);
    return response;
  } catch (error) {
    console.error(error);
  }
};
