import { genericCallWithBody, genericCallWithBodyAndResponseDecoded, genericGetWithParameters, genericGetWithParametersNoEncrypted } from './Networking';

export async function saveDealHeader(objDealHeader: any) {
  var method = 'POST';
  var apiName = 'Deals/Post';

  let response = await genericCallWithBody(method, apiName, objDealHeader);
  return response;
}

export async function sendDealEmail(dealHeaderIDRequest: any) {
  try {
    let method = 'POST';
    let apiName = 'Deals/SendDealEmail';
    let response = await genericCallWithBodyAndResponseDecoded(method, apiName, dealHeaderIDRequest);
    return response;
  } catch (error) {
    console.error(error);
  }
}
export async function sendDealReportByEmail(sendDealReportByEmailRequest: any) {
  let method = 'POST';
  let apiName = 'Deals/SendDealReportByEmail';
  let response = await genericCallWithBodyAndResponseDecoded(method, apiName, sendDealReportByEmailRequest);
  return response;
}
export async function sendPendingForApprovalEmail(sendMailApprovalRequest: any) {
  try {
    let method = 'POST';
    let apiName = 'Deals/SendPendingForApprovalEmail';
    let response = await genericCallWithBodyAndResponseDecoded(method, apiName, sendMailApprovalRequest);
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function getListOfPendingApprovalDeals(customerID: any, contactID: any, page: any, items: any) {
  try {
    let params = {
      customerID: customerID,
      contactID: contactID,
      page: page,
      items: items
    };
    let response = await genericGetWithParameters('Deals/GetListOfPendingApprovalDeals', params);
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function getPendingDeal(dealHeaderID: any) {
  try {
    let params = {
      dealHeaderID: dealHeaderID
    };
    let response = await genericGetWithParameters('Deals/GetPendingDeal', params);
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function getNumberOfPendingApprovals(customerID: any, contactID: any, userID: any) {
  try {
    let params = {
      customerID: customerID,
      contactID: contactID,
      userID: userID
    };
    let response = await genericGetWithParameters('Deals/GetNumberOfPendingApprovals', params);
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function getDealByID(dealHeaderID: any) {
  try {
    let params = {
      DealID: dealHeaderID
    };
    let response = await genericGetWithParameters('Deals/GetDealByID', params);
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function getDrawdownsByForwardID(forwardID: any) {
  try {
    let params = {
      forwardID: forwardID,
      page: 0,
      items: 0
    };
    let response = await genericGetWithParameters('Deals/GetDrawdownsByForward', params);
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function approveDeal(objApproveDeal: any) {
  var method = 'POST';
  var apiName = 'Deals/ApproveDeal';

  let response = await genericCallWithBody(method, apiName, objApproveDeal);
  return response;
}

export async function rejectDeal(objRejectDeal: any) {
  var method = 'POST';
  var apiName = 'Deals/RejectDeal';

  let response = await genericCallWithBody(method, apiName, objRejectDeal);
  return response;
}

export async function getApprovalHistoryByEntityId(entityType: any, entityID: any) {
  try {
    let params = {
      entityType: entityType,
      entityID: entityID
    };
    let response = await genericGetWithParameters('Deals/GetApprovalHistoryByEntityId', params);
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function getListOfDeals(dealHeaderId: any, dealType: any, status: any, fromDate: any, toDate: any, customerID: any, onlyOutstandingDeals: any, page: any, items: any) {
  try {
    let params = {
      dealHeaderId: dealHeaderId,
      dealType: dealType,
      status: status,
      fromDate: fromDate,
      toDate: toDate,
      customerID: customerID,
      onlyOutstandingDeals: onlyOutstandingDeals,
      dateFormated: true,
      page: page,
      items: items
    };
    let response = await genericGetWithParameters('Deals/GetListOfDeals', params);

    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function getConfirmation(dealHeaderID: any) {
  try {
    let params = {
      dealHeaderID: dealHeaderID
    };
    let response = await genericGetWithParametersNoEncrypted('Deals/GetConfirmation', params);
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function getFullDealHeader(dealHeaderID: any) {
  try {
    let params = {
      dealHeaderID: dealHeaderID,
      'userId': localStorage.getItem('UserID')
    };
    let response = await genericGetWithParameters('Deals/GetFullDealHeader', params);
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function voidDealHeader(dealHeaderID: any, userID: any, updateToken: any) {
  try {
    let params = {
      dealHeaderID: dealHeaderID,
      userID: userID,
      updateToken: updateToken
    };
    let response = await genericGetWithParametersNoEncrypted('Deals/Void', params);
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function getValueDate(customerID: any, currencyPair: any) {
  try {
    let params = {
      customerID: customerID,
      currencyPair: currencyPair
    };
    let response = await genericGetWithParameters('Deals/GetValueDate', params);
    return response;
  } catch (error) {
    console.error(error);
  }
}
export async function generateConfirmationPDF(dealHeaderID: any, docType: any, language: any, reportTemplate: any) {
  try {
    var apiName = '';
    apiName = 'Deals/GenerateConfirmationPDF';

    let model = {
      dealHeaderID: dealHeaderID,
      docType: docType,
      language: language,
      reportTemplate: reportTemplate
    };
    let response = await genericGetWithParametersNoEncrypted(apiName, model);
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function getListOfDealsPendingDisburse(customerID: any) {
  try {
    let params = {
      customerID: customerID
    };
    let response = await genericGetWithParameters('Deals/GetListOfDealsPendingDisburse', params);
    return response;
  } catch (error) {
    console.error(error);
  }
}
