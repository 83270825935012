import { useEffect, useState } from 'react';
import { getStatesByCountryMin } from '../NetworkingStates';

export interface State {
  'State ID': number;
  'State Name': string;
  label: string;
  value: string;
}

const useStates = (countryID: string) => {
  const [states, setStates] = useState<State[]>([]);
  useEffect(() => {
    const loadStatesForCountry = async (countryID: string) => {
      const json = await getStatesByCountryMin(countryID);
      if (json != null && json.states != null) {
        const formattedStates = json.states.map((s: State) => ({ ...s, label: s['State Name'], value: s['State ID'] }));
        formattedStates.push({ label: 'Select a State...', value: 0 });
        setStates(formattedStates);
      } else {
        setStates([]);
      }
    };

    loadStatesForCountry(countryID);

  }, [countryID]);

  return states;
};

export default useStates;
