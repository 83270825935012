import { genericGetWithParameters } from './Networking';

export async function getTradingAndCurrencyHolidays(dateFrom: string, dateTo: string) {
  try {
    let params = { dateFrom: dateFrom, dateTo: dateTo };
    let response = await genericGetWithParameters('Holiday/GetTradingAndCurrencyHolidays', params);
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function getNextBusinessDate(currentDate: any, extraDays: any) {
  try {
    let params = { CurrentDate: currentDate, ExtraDays: extraDays };
    let response = await genericGetWithParameters('Holiday/GetNextBusinessDate', params);
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function IsBusinessDate(sellCurrencyID: string, buyCurrencyID: string, date: any) {
  try {
    let params = {
      sellCurrencyID: sellCurrencyID,
      buyCurrencyID: buyCurrencyID,
      date: date
    };
    let response = await genericGetWithParameters('Holiday/GetIsBusinessDate', params);
    return response;
  } catch (error) {
    console.error('--------------------- ERROR NETWORKING ---------------------');
    console.error(error);
  }
}
