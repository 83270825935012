import React, { useState } from 'react';
import useOnlineRights from '../../api/hooks/useOnlineRights';
import { OnlineRightsNames } from '../../helpers/OnlineRightsHelper';
import { getResource } from '../../helpers/ResourcesHelper';
import MyCredentials from './myCredentials/MyCredentials';
import MyDefaultsScreen from './myDefaults/myDefaultsScreen';
import MyInformation from './myInformation/MyInformation';
import MyMessages from './MyMessages';
import RequiredDocuments from './RequiredDocuments';

const MyAccount = (props:any) => {
    const [showTab, setShowTab] = useState('my-credentials');

  //rights
  let OL_MyAccountInformation = useOnlineRights(OnlineRightsNames.OL_MyAccountInformation);
  let OL_BeneficiaryUser = useOnlineRights(OnlineRightsNames.OL_BeneficiaryUser);


    const onClick = (tab: string) => {
        setShowTab(tab);
    }

    const tabSelected = (tab: string) => {
      return  showTab === tab ? 'col-xs navbar-horizontal-option-selected' : 'col-xs navbar-horizontal-option';
    }

    return (
      <React.Fragment>
        <div className="navbar-horizontal slide-t-b">
          <div className="row">
            <div id="my-credentials" onClick={() => onClick('my-credentials')} className={tabSelected('my-credentials')}>
              <span>{getResource('Tab_MyCredentials')}</span>
            </div>
            {OL_MyAccountInformation && <div id="my-information" onClick={() => onClick('my-information')} className={tabSelected('my-information')}>
              <span>{getResource('Tab_MyInformation')}</span>
            </div>}
            {!OL_BeneficiaryUser && <div id="required-documents" onClick={() => onClick('required-documents')} className={tabSelected('required-documents')}>
              <span>{getResource('Tab_RequiredDocumments')}</span>
            </div>}
            <div id="my-messages" onClick={() => onClick('my-messages')} className={tabSelected('my-messages')}>
              <span>{getResource('Tab_MyMessages')}</span>
            </div>
            {/* {!OL_BeneficiaryUser && <div id="my-balances" onClick={() => onClick('my-balances')} className={tabSelected('my-balances')}>
              <span>My Balances</span>
            </div>} */}
            {!OL_BeneficiaryUser && <div id="my-defaults" onClick={() => onClick('my-defaults')} className={tabSelected('my-defaults')}>
              <span>{getResource('Tab_MyAccountDefaults')}</span>
            </div>}
          </div>
        </div>
        {showTab === 'my-credentials' && <MyCredentials />}
        {showTab === 'my-information' && <MyInformation />}
        {showTab === 'required-documents' && <RequiredDocuments />}
        {showTab === 'my-defaults' && <MyDefaultsScreen />}
        {showTab === 'my-messages' && <MyMessages />}
        {/* {showTab === 'my-balances' && (
          <MyBalances
            convertBalance={(balanceObj: ResponseGetListOfFundsOnHold) => {
              props.onBalanceClick(balanceObj);
            }}
          />
        )} */}
      </React.Fragment>
    );
};

export default MyAccount;
