import { useEffect, useState } from 'react';
import { getInstrumentsByCustomer } from '../NetworkingInstruments';

const useInstruments = () => {
  const [instruments, setInstruments] = useState([]);
  const [instrumentsForNonAccounts, setInstrumentsForNonAccounts] = useState([]);
  const [needsReloading, setNeedsReloading] = useState(true);

  useEffect(() => {
    const loadInstruments = async () => {
      const customerID = JSON.parse(localStorage.getItem('UserSession')!)['CustomerID'];
      const json = await getInstrumentsByCustomer(customerID);
      if (json.instruments !== undefined) {
        let formattedInstruments = [] as any;
        let nonAccounts = [] as any;
        const lastInstruments = json.instruments.map((c: any) => ({ ...c, label: c.Instrument, value: c.InstrumentTranslationLabel }));
        lastInstruments.forEach((element: any) => {
          if (element.InstrumentType === 'Outgoing') {
            formattedInstruments.push(element);
            if (Number(element.AccountsOnly) === 0) {
              nonAccounts.push(element);
            }
          }
        });
        nonAccounts.push({ label: 'Select an Instrument', value: '' });
        formattedInstruments.push({ label: 'Select an Instrument', value: '' });
        setInstruments(formattedInstruments);
        setInstrumentsForNonAccounts(nonAccounts);
      } else {
        setInstruments([]);
      }
      setNeedsReloading(false);
    };
    if (needsReloading) {
      loadInstruments();
    }
  }, [needsReloading]);

  return [instruments, instrumentsForNonAccounts, setNeedsReloading];
};

export default useInstruments;
