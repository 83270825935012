import { genericCallWithBody, genericCallWithOutBody } from './Networking';

export async function saveContact (model:any) {
    let method = 'POST';
    let apiName = 'Contact/SaveContactOnline';

    let response = await genericCallWithBody(method, apiName, model);
    return response;
}

export const disableContact = async (ContactId: any) => {

    let params = {
        ContactId: ContactId
    }
    var apiName = 'Contact/DisableContact';
    let response = await genericCallWithOutBody(apiName, params);
    return response;
}