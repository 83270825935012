import React, { FunctionComponent, useContext, useEffect, useState } from "react";
import { getForgotPasswordQuestions, resetPassword, validateForgotPasswordAnswers } from "../../api/NetworkingUsers";
import AppContext from '../../App.context';
import { getCompanyLogo } from "../../helpers/PackageJsonHelper";
import { getResource } from "../../helpers/ResourcesHelper";
// import logo from '../../images/logo_generic.png';
//import logo from '../../images/Logo_Premier.png';
import Button from "../inputs/Button";

const logo = getCompanyLogo();

export interface Question {
  QuestionID: number;
  QuestionText: string;
  Answer?: string;
}

interface ResetPasswordProps {
  forgotpasswordkey: any;
  onFinishResetPassword: Function;
}

export const ResetPassword: FunctionComponent<ResetPasswordProps> = (props) => {
  const { showModal, showLoadingModal, hideLoadingModal } = useContext(AppContext);
  const [showChangePaswword, setShowResetPassword] = useState(false);
  const [questions, setQuestions] = useState<Question[]>([]);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  useEffect(() => {
    getForgotPasswordQuestions(props.forgotpasswordkey).then(
      (jsonResponse) => {
        if (jsonResponse.Questions !== undefined) {
          if (jsonResponse.Questions.length > 0) {
            var questionsAux = jsonResponse.Questions;//.forEach((deal: any) => {
            /*  deal.Answer = '';
            });
*/
            setQuestions(questionsAux);
          }
          else {
            setShowResetPassword(true);
          }
        }
      }
    );

  }
    , props.forgotpasswordkey);

  const onChangeAnswer = async (event: any, id: number) => {
    const listaux = [...questions];
    let totalAmount = 0;
    listaux.forEach(async (q, _index) => {
      if (q.QuestionID === id) {
        q.Answer = event.target.value;
      }
    });
    setQuestions(listaux);
  }

  const onSaveQuestionsClick = () => {
    var answersAux = [] as any;
    showLoadingModal();
    questions.forEach((q: Question) => {
      var answer = {
        QuestionID: q.QuestionID,
        AnswerText: q.Answer
      };
      answersAux.push(answer);
    });
    var model = {
      forgotPasswordKey: props.forgotpasswordkey,
      answers: answersAux
    }
    validateForgotPasswordAnswers(model).then(
      (jsonResponse) => {
        if (jsonResponse.Result !== undefined) {
          hideLoadingModal();
          if (jsonResponse.Result) {
            setShowResetPassword(true);
          }
          else {
            showModal(getResource('Js_MessageError_TitleError'), getResource('VerificationChallengeAnswers.Error'));
          }
        }
      }
    );
  }

  const onSavePasswordClick = () => {
    var values = validateNewPassword();

    if (values.isValid) {
      showLoadingModal();
      var model = {
        'ForgotPasswordKey': props.forgotpasswordkey,
        'NewPassword': confirmPassword
      }
      resetPassword(model).then(
        (jsonResponse) => {
          if (jsonResponse.httpStatusCode === 200) {
            if (jsonResponse.Message !== undefined && jsonResponse.Message !== '') {
              showModal(getResource('Js_MessageError_TitleSuccess'), getResource('PasswordChangedSuccessfully'));
              hideLoadingModal();
              props.onFinishResetPassword();
            }
          } else {
            var msg = getResource('SomethingWentWrongTryAgain') + ' ' + jsonResponse.httpStatusCode
            if (jsonResponse.Message !== undefined) {
              msg = jsonResponse.Message
            } else {
              if (jsonResponse.ErrorMessage !== undefined) {
                msg = jsonResponse.ErrorMessage
              }
            }
            hideLoadingModal();
            showModal(getResource('Js_MessageError_TitleError'), msg);
          }
        }
      )
    }
    else {
      var msg = ''
      switch (values.errorNumber) {
        default:
        case 0:
          {
            msg = getResource('SomethingWentWrongTryAgain');
            break;
          }
        case 1:
          {
            msg = getResource('Password_Validation');
            break;
          }
        case 2:
          {
            msg = getResource('DataMsgNewConfirmPasswordNoMatch');
            break;
          }
      }
      showModal(getResource('Js_MessageError_TitleError'), msg);
    }
  }

  const validateNewPassword = () => {
    var valid = true
    var errorId = 0
    var regStrength = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&])[0-9a-zA-Z@#$%&]{6,50}$/;

    if (newPassword === undefined || newPassword === '' ||
      newPassword === null || !regStrength.test(newPassword)) {
      valid = false
      errorId = 1
    }

    if (confirmPassword === undefined || confirmPassword === '' ||
      confirmPassword === null) {
      valid = false
      errorId = 1
    }

    if (valid === true) {
      if (newPassword !== confirmPassword) {
        valid = false
        errorId = 2
      }
    }

    return {
      isValid: valid,
      errorNumber: errorId,
    }
  }

  return (
    <React.Fragment>
      <div className="col prime-container login-container">
        <div className="row prime-container">
          <div className="col-md-4 col-xs-12"></div>
          <div className="row col-md-4 col-xs-12 middle-xs">
            <div className="row col-xs-12 center-xs">
              <div className="col-xs-8">
                <div className="row">
                  <img className="col-xs-12 login-image" src={logo} alt="" />
                </div>

                {!showChangePaswword && <div className="row">
                  {
                    questions.map((r, i) => (
                      <>
                        <div className="row">
                          <div className="row">
                            <span className="question-fp">{questions[i].QuestionText}</span>
                          </div>
                          <div className="row">
                            <input type="text" value={questions[i].Answer}
                              onChange={(event) => {
                                onChangeAnswer(event, questions[i].QuestionID);
                              }
                              } ></input>
                          </div>
                        </div>
                      </>
                    ))
                  }
                  <div className="row">
                    <Button id="save-button" type="active" onClick={onSaveQuestionsClick}>
                      {getResource('Button.Save')}
                    </Button>
                  </div>
                </div>}
                {showChangePaswword &&
                  <div className="row">
                    <div className="row">
                      <div className="row">
                        <span className="question-fp">{getResource('Label_NewPassword')}</span>
                      </div>
                      <div className="row">
                        <input type="password" value={newPassword}
                          onChange={(event) => {
                            setNewPassword(event.target.value);
                          }} ></input>
                      </div>
                    </div>
                    <div className="row">
                      <div className="row">
                        <span className="question-fp">{getResource('Label_ConfirmPassword')}</span>
                      </div>
                      <div className="row">
                        <input type="password" value={confirmPassword}
                          onChange={(event) => {
                            setConfirmPassword(event.target.value);
                          }} ></input>
                      </div>
                    </div>
                    <div className="row">
                      <Button id="save-button-password" type="active" onClick={onSavePasswordClick}>
                        {getResource('Button.Save')}
                      </Button>
                    </div>
                  </div>
                }

              </div></div></div></div>
      </div>
    </React.Fragment>
  );
}

export default ResetPassword;

