import { genericCallWithBody, genericGetWithParameters } from './Networking';

export async function saveOutgoingFunds(outgoingFunds: any[]) {
  var method = 'POST';
  var apiName = 'Funds/CreateOutgoingFunds';

  let response = await genericCallWithBody(method, apiName, outgoingFunds);
  return response;
}

export async function getListOfFundsOnHold(customerID: number, currencyID: string) {
  try {
    let params = {
      CustomerID: customerID,
      CurrencyID: currencyID
    };

    let response = await genericGetWithParameters('Funds/GetListOfFundsOnHold', params);
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function getIncomingFunds(dealID: number) {
  try {
    let params = {
      DealID: dealID,
      Page: 1,
      Items: 0
    };

    let response = await genericGetWithParameters('Funds/GetIncomingFundsByDeal', params);
    return response;
  } catch (error) {
    console.error(error);
  }
}
export async function getHistoryAuditFundsOnHoldBalances(params: any) {
  try {
    let response = await genericGetWithParameters('Funds/GetHistoryAuditFundsOnHoldBalances', params);
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function getOutgoingInformationByID(outgoingID: number) {
  try {
    let params = {
      OutgoingFundsID: outgoingID
    }
    let response = await genericGetWithParameters('Funds/GetFullOutgoingFundByID', params);
    return response;
  } catch (error) {
    console.error(error);
  }
}