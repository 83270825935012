import React, { useState } from 'react';
import { getResource } from '../../../helpers/ResourcesHelper';
import ChangePassword from './ChangePassword';
import Questions from './Questions';

const MyCredentials = () => {
  const [showTab, setShowTab] = useState('change-password');

  const onClick = (tab: string) => {
    setShowTab(tab);
  };

  const tabSelected = (tab: string) => {
    return  showTab === tab ? 'col-xs navbar-horizontal-option-selected' : 'col-xs navbar-horizontal-option';
  }

  return (
    <div className="screen-container">
      <div className="row  slide-r-l">
        <div id="my-credentials-change-password" onClick={() => onClick('change-password')} className={tabSelected('change-password')}><span>{getResource('Tab_ChangePassword')}</span></div>
        <div id="my-credentials-questions" onClick={() => onClick('questions')} className={tabSelected('questions')}><span>{getResource('Tab_UpdateChallengerQuestion')}</span></div>
      </div>
      <div className="screen-container slide-r-l">
        {showTab === 'change-password' && <ChangePassword />}
        {showTab === 'questions' && <Questions userSession={JSON.parse(localStorage.getItem('UserSession')!)} />}
      </div>
    </div>
  );
};

export default MyCredentials;
