import { genericCallWithBody, genericCallWithOutBody, genericGetWithParameters } from './Networking';

export interface Message {
  MessageId: number;
  Content: string;
  Receiver: string;
  ReceiverId: string;
  Sender: string;
  SenderId: string;
  SentOn: string;
  Status: string;
  Subject: string;
}

interface JsonMessageResponse {
  messages: Message[];
}

export const getListOfMessages = async (): Promise<JsonMessageResponse | undefined> => {
  const userID = localStorage.getItem('UserID');
  try {
    const params = {
      UserId: userID
    };
    const response = await genericGetWithParameters('Message/Get', params);
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const dismissMessage = async (MessageId: number) => {
  try {
    const params = {
      MessageId: MessageId
    };
    const response = await genericCallWithOutBody('Message/DismissMessage', params);
    return response;
  } catch (error) {
    console.error(error);
  }
};

interface ReplyMessageRequest {
  content: string;
  senderId: string;
  sentOn: string;
  subject: string;
  receiverId: string;
}

export const replyMessage = async (model: ReplyMessageRequest) => {
  try {
    let response = await genericCallWithBody('POST', 'Message/ReplyMessage', model);
    return response;
  } catch (error) {
    console.error(error);
  }
};
