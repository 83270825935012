import { Checkbox } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useContext, useEffect, useState } from 'react';
import { getEmailUserSettings, saveEmailUserSettings } from '../../../api/NetworkingUsers';
import AppContext from '../../../App.context';
import { getResource } from '../../../helpers/ResourcesHelper';
import Button from '../../inputs/Button';
import { EmailUserSetting } from '../../models/EmailUserSetting';
import DataGrid from '../../table/DataGrid';

const EmailTemplateSettings = () => {
  const { showModal, showLoadingModal, hideLoadingModal } = useContext(AppContext);
  const [templates, setTemplates] = useState<EmailUserSetting[]>([]);
  const [templateID, setTemplateID] = useState(0);

  useEffect(() => {
    loadTemplates();
  }
    , []);

  const onChangeRequired = (checked: boolean, templateID: number) => {

    var index = templates.findIndex((t: any) => t.TemplateID == templateID);
    if (index > -1) {

      var templatesAux = templates;
      templatesAux[index].Required = checked;
      setTemplates(templatesAux);
      setTemplateID(templateID);
    }

  }

  const loadTemplates = () => {
    const userID = localStorage.getItem('UserID')!;
    getEmailUserSettings(userID).then(response => {
      if (response != null && response != undefined && response.httpStatusCode == 200) {
        if (response.templates !== undefined) {
          setTemplates(response.templates);
        }
      }
    });
  }

  const columns: ColumnsType<EmailUserSetting> = [
    {
      title: getResource('Email'),
      dataIndex: 'Description',
      key: 'description',
      sorter: (a, b) => a.Description.localeCompare(b.Description)
    },
    {
      title: getResource('Button.Receive'),
      dataIndex: 'Required',
      key: 'currency',
      align: 'center',
      shouldCellUpdate: () => true,
      //@ts-ignore
      render: (text, record) =>
        <Checkbox disabled={record.Mandatory} checked={record.Required}
          onChange={({ target: { checked } }) => {
            onChangeRequired(checked, record.TemplateID);
          }}></Checkbox>

    }
  ]

  const saveEmailTemplates = () => {

    showLoadingModal();
    const userID = localStorage.getItem('UserID')!;

    var model = {
      UserID: userID,
      jsonTemplates: JSON.stringify(templates)
    }

    saveEmailUserSettings(model).then(
      (response) => {
        if (response !== undefined && response !== null) {
          let responseStatus = response.httpStatusCode
          switch (responseStatus) {
            case 200:
              showModal(getResource('Js_MessageError_TitleSubmit'), getResource('Js.Message.DataSaved'));
              break;
            case 409:
              showModal(getResource('Js_MessageError_TitleFormValidation'), response.httpErrorMessage);
              break;
            case (responseStatus >= 401 && responseStatus < 500):
              showModal(getResource('Js_MessageError_TitleSubmit'), response.Message);
              break;
            case (responseStatus >= 500):
              showModal(getResource('Js_MessageError_TitleSubmit'), getResource('InternalServerError') + response.Message);
              break;
            default:
              showModal(getResource('Js_MessageError_TitleError'), getResource('Js_MessageError_FormValidationNFieldNHighlighted'));
              break;
          }
        }
        hideLoadingModal();
      }
    );


  }


  return (
    <div className="screen-container slide-r-l">
      <div className="row center-xs">
        <DataGrid size="large" style={{ width: 600 }} columns={columns} dataSource={templates} />
      </div>
      <div className="row center-xs">
        <div className="col-md-2 col-xs-12">
          <Button type="primary" onClick={saveEmailTemplates}>{getResource('Button.Save')}</Button>
        </div>
      </div>
    </div>
  );

}

export default EmailTemplateSettings;


