import { DollarCircleOutlined, DownloadOutlined, EditOutlined } from '@ant-design/icons';
import { Table } from 'ant-table-extensions';
import { Breadcrumb, Collapse, Divider, Tooltip, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import useLookUpTables from '../../../api/hooks/useLookUpTables';
import useOnlineRights from '../../../api/hooks/useOnlineRights';
import { getListOfOnlineOrders, saveRateWatch } from '../../../api/NetworkingRateWatches';
import AppContext from '../../../App.context';
import { formatToCurrency } from '../../../helpers/FormatHelper';
import { OnlineRightsNames } from '../../../helpers/OnlineRightsHelper';
import { getResource } from '../../../helpers/ResourcesHelper';
import Button from '../../inputs/Button';
import DatePicker from '../../inputs/DatePicker';
import Dropdown from '../../inputs/Dropdown';
import { OnlineOrder } from '../../models/OnlineOrder';
import { RateWatch } from '../../models/RateWatch';
import AddEditRateWatch from './AddEditRateWatch';


const { Panel } = Collapse;

const RateWatchScreen = (props: any) => {

  const { showModal } = useContext(AppContext);

  const [updating, setUpdating] = useState<boolean>(false);
  const [rateWatches, setRateWatches] = useState<OnlineOrder[]>([]);
  const [typeOfRateWatch, setTypeOfRateWatch] = useState('spot')
  const [fromDate, setFromDate] = useState<string>(moment().startOf('year').format('YYYY/MM/DD'));
  const [toDate, setToDate] = useState<string>(moment().endOf('year').format('YYYY/MM/DD'));

  const [showTrackForm, setShowTrackForm] = useState<boolean>(false);
  const [rateWatchIdSelected, setRateWatchIdSelected] = useState<number>(0);
  const [statusFilter, setStatusFilter] = useState('Outstanding')

  const lookupTables = useLookUpTables('Rate Watch Status Names,Rate Watch Type,Notification Method');

  //rights
  let OL_RatesForwardAddNew = useOnlineRights(OnlineRightsNames.OL_RatesForwardAddNew);
  let OL_RatesForwardEdit = useOnlineRights(OnlineRightsNames.OL_RatesForwardEdit);
  let OL_RatesSpotAddNew = useOnlineRights(OnlineRightsNames.OL_RatesSpotAddNew);
  let OL_RatesSpotEdit = useOnlineRights(OnlineRightsNames.OL_RatesSpotEdit);
  let OL_SpotAccess = useOnlineRights(OnlineRightsNames.OL_SpotAccess);
  let OL_ForwardAccess = useOnlineRights(OnlineRightsNames.OL_ForwardAccess);
  let OL_RatesSpotAccess = useOnlineRights(OnlineRightsNames.OL_RatesSpotAccess);
  let OL_RatesForwardAccess = useOnlineRights(OnlineRightsNames.OL_RatesForwardAccess);

  useEffect(() => {
    getListOfOnlineOrders(JSON.parse(localStorage.getItem('UserSession')!).CustomerID, statusFilter, fromDate, toDate, '')
      .then(response => {
        console.log("getListOfOnlineOrders --->", response);
        if (response !== undefined && response !== null && response.onlineOrders != null) {
          response.onlineOrders.forEach((onlineOrder: any) => {
            onlineOrder.key = onlineOrder.OrderID;
          });
          setRateWatches(response.onlineOrders);
        } else {
          setRateWatches([]);
        }
      });
  }, [updating]);

  const columns: ColumnsType<OnlineOrder> = [
    {
      title: getResource('Table_TH_OrderID'),
      dataIndex: 'OrderID',
      key: 'order_id',
      align: 'center',
      sorter: (a, b) => a.OrderID - b.OrderID
    },
    {
      title: getResource('Table_TH_Type'),
      dataIndex: 'DealType',
      key: 'ratewatch_dealtype',
      align: 'center',
    },
    {
      title: getResource('Table_TH_SellBuy'),
      dataIndex: 'BuyCurrency',
      key: 'ratewatch_currency',
      align: 'center',
      render: (value: any, obj: any, index: any) => (
        <div>
          <span>{formatToCurrency(obj.SellAmount1, obj.SellCurrency) + ' ' + obj.SellCurrency + " / " + formatToCurrency(obj.BuyAmount1, obj.BuyCurrency) + '  ' + obj.BuyCurrency}</span>
          {obj.SellAmount2 > 0 && <br></br>}
          {obj.SellAmount2 > 0 && <span>{formatToCurrency(obj.SellAmount2, obj.SellCurrency) + ' ' + obj.SellCurrency + " / " + formatToCurrency(obj.BuyAmount2, obj.BuyCurrency) + '  ' + obj.BuyCurrency}</span>}
        </div>
      ),
    },
    {
      title: getResource('Table_TH_Target'),
      dataIndex: 'CustomerRate1',
      key: 'ratewatch_target',
      render: (value: any, obj: any, index: any) => (
        <div>
          <span>{obj.CustomerRate1 != null ? obj.CustomerRate1 : 0}</span>
          {obj.CustomerRate2 > 0 && <br></br>}
          {obj.CustomerRate2 > 0 && <span>{obj.CustomerRate2}</span>}
        </div>
      ),
    },
    {
      title: getResource('Table_TH_CustomerRate'),
      dataIndex: 'CurrentRate',
      key: 'ratewatch_change',
      render: (value: any, obj: any, index: any) => (
        <span>{obj.CurrentRate != null ? obj.CurrentRate : 0}</span>
      ),
    },
    /*{
      title: 'Amount',
      dataIndex: 'WeAction',
      key: 'ratewatch_amount',
      render: (value: any, obj: any, index: any) => (
        <span>{obj.WeAction === 'We Sell' ? formatToCurrency(obj.BuyAmount1) : formatToCurrency(obj.SellAmount1)}</span>
      ),
    },
    {
      title: 'Notice',
      dataIndex: 'NotificationMethod',
      key: 'ratewatch_notice',
      align: 'center',
      render: (value: any, obj: any, index: any) => (
        <span>{obj.NotificationMethod}</span>
      ),
    },*/
    {
      title: getResource('Table_TH_Status'),
      dataIndex: 'NotificationMethod',
      key: 'ratewatch_status',
      align: 'center',
      render: (value: any, obj: any, index: any) => (
        <span>{obj.Status}</span>
      ),
    },
    {
      title: getResource('Table_TH_DealID'),
      dataIndex: 'DealHeaderID',
      key: 'deal_id',
      align: 'center'
    },
    {
      title: getResource('Table_TH_Expires'),
      key: 'ExpirationDate',
      dataIndex: 'ratewatch_expires',
      align: 'center',
      render: (value: any, obj: any, index: any) => (
        <span>{obj.GoodTilCancelled ? 'GTC' : obj.ExpirationDate}</span>
      ),
    },
    {
      title: getResource('Table_TH_Action'),
      key: 'action',
      align: 'center',
      render: (value: any, obj: any, index: any) => (
        <>
          {
            ((obj.DealType == 'Spot' && OL_RatesSpotEdit) || (obj.DealType == 'Forward' && OL_RatesForwardEdit))
            && <Tooltip title={getResource('Js.Link.Edit')}>
            <Typography.Link
              onClick={() => { editClick(obj) }}
            >
              <EditOutlined style={{ fontSize: 18 }} />
            </Typography.Link>
            </Tooltip>}
          <Divider type="vertical" />
          {((obj.DealType == 'Spot' && OL_SpotAccess) || (obj.DealType == 'Forward' && OL_ForwardAccess))
            && <Tooltip title={getResource('Js.Link.BuyNow')}>
            <Typography.Link
              onClick={() => { props.onBuyNowClick(obj) }}
            >
              <DollarCircleOutlined style={{ fontSize: 18 }} />
            </Typography.Link>
            </Tooltip>}
        </>
      ),
    },
  ];

  const editClick = (onlineOrder: OnlineOrder) => {
    setRateWatchIdSelected(onlineOrder.OrderID);
    setShowTrackForm(true);
  }

  const onChangeFromDate = (date: any, dateString: string) => {
    setFromDate(dateString);
  };

  const onChangeToDate = (date: any, dateString: string) => {
    setToDate(dateString);
  };

  const onTrackNewClick = () => {
    setRateWatchIdSelected(0);
    setShowTrackForm(true);
  }

  const handleCloseModalTrackForm = () => {
    setShowTrackForm(false);
    setRateWatchIdSelected(0);
  }

  const onSaveClick = (onlineOrder: OnlineOrder) => {

    console.log("save OnlineOrder clicked -> ", onlineOrder);

    let userSession = JSON.parse(localStorage.getItem('UserSession')!);

    let saveRateWatchObj: RateWatch = {
      RateWatchID: onlineOrder.OrderID,
      CustomerContactID: userSession.ContactID,
      CustomerID: userSession.CustomerID,
      RateReached: 0,
      RateWatchOrigin: 'Online',
      RateWatchType: onlineOrder.OrderType,
      RateWatchStatus: onlineOrder.Status,
      GoodTillCancelled: onlineOrder.GoodTilCancelled === 0 ? false : true,
      ExpirationDate: onlineOrder.ExpirationDate,
      NotificationMethod: onlineOrder.NotificationMethod,
      DealTypeID: onlineOrder.DealTypeID,
      OpenFor: onlineOrder.OpenFor,
      NearDate: onlineOrder.NearDate,
      FarDate: onlineOrder.FarDate,
      CheckSingleRate: onlineOrder.CheckSingleRate === 0 ? false : true,
      BuyCurrency: onlineOrder.BuyCurrency,
      SellCurrency: onlineOrder.SellCurrency,
      BuyAmount1: onlineOrder.BuyAmount1,
      BuyAmount2: onlineOrder.BuyAmount2,
      SellAmount1: onlineOrder.SellAmount1,
      SellAmount2: onlineOrder.SellAmount2,
      WeAction: onlineOrder.WeAction,
      CustomerRate1: onlineOrder.CustomerRate1,
      CustomerRate2: onlineOrder.CustomerRate2,
      CreatedBy: onlineOrder.CreatedBy,
      CreatedOn: onlineOrder.CreatedOn,
      UpdatedBy: onlineOrder.UpdatedBy,
      UpdatedOn: onlineOrder.UpdatedOn,
      UpdateToken: onlineOrder.UpdateToken
    }

    saveRateWatch(saveRateWatchObj).then(
      (response) => {
        switch (response.httpStatusCode) {
          case 200:
            {
              setShowTrackForm(false);
              showModal(getResource('Js_MessageError_TitleSuccess'), getResource('Js_Message_SaveSuccessfullyRatewatch'));
              setUpdating(!updating);
              break;
            }
          case !200:
            {
              let message = response.Message === undefined ? '' : (response.Message);
              message = message + response.ErrorMessage === undefined ? '' : (response.ErrorMessage);
              showModal(getResource('Js_MessageError_TitleError'), message);
              break;
            }
        }
      }
    )
  }

  const onCancelClick = () => {
    setShowTrackForm(false);
  }

  const dropdownStatusChangeHandler = (item: any, name: string) => {
    setStatusFilter(item.value);
  };

  return (
    <>
      <Breadcrumb separator="">
        <Breadcrumb.Item onClick={onCancelClick}>{getResource('Title.MyRatewatches')}</Breadcrumb.Item>
        {showTrackForm && <Breadcrumb.Separator children=">" />}
        {showTrackForm && <Breadcrumb.Item >{getResource('Title.TrackSpotRate')}</Breadcrumb.Item>}
        {showTrackForm && <Breadcrumb.Item><label className="exit-button" onClick={onCancelClick}>{getResource('Btn_Exit')}</label></Breadcrumb.Item>}

      </Breadcrumb>
      {!showTrackForm && (OL_RatesSpotAccess || OL_RatesForwardAccess) && <>
          <div className="row center-xs">
          {(OL_RatesForwardAddNew || OL_RatesSpotAddNew) && <div className="col-md-4 col-xs-12" style={{ alignSelf: 'center', padding: 10 }}>
            <Button type="primary" onClick={onTrackNewClick}>{getResource('TrackNew')}</Button>
            </div>
          }
          </div>
        <Collapse defaultActiveKey={['1']} style={{ marginBottom: '15px' }}>
            <Panel
              header={"Filter Options"}
              key="1"
            >
              <div className="col-md-12">
                <div className="row">
                <div className="col-md-2 col-xs-12" style={{ alignSelf: 'center' }}>
                    <div className="row">
                    <span className="input-label">{getResource('From')}</span>
                    </div>
                    <div className="row">
                      <DatePicker id="ratewatch-datepicker-fromdate" key="ratewatch-datepicker-fromdate" onChange={onChangeFromDate} value={moment(fromDate)} />
                    </div>
                  </div>
                <div className="col-md-2 col-xs-12" style={{ alignSelf: 'center' }}>
                    <div className="row">
                    <span className="input-label">{getResource('Form.Label.To')}</span>
                    </div>
                    <div className="row">
                      <DatePicker id="ratewatch-datepicker-todate" key="ratewatch-datepicker-todate" onChange={onChangeToDate} value={moment(toDate)} />
                    </div>
                  </div>
                <div className="col-md-2 col-xs-12" style={{ alignSelf: 'center' }}>
                  <div className="row">
                    <span className="input-label">{getResource('Label.Status')}</span>
                  </div>
                  <div className="row">
                    <Dropdown
                      name="status"
                      title={getResource('SelectStatus')}
                      list={lookupTables.RateWatchStatusNames.filter(x => x.value != 'Select Rate Watch Status Names...')}
                      //{[
                      //  { value: 'Outstanding', label: 'Outstanding' },
                      //  { value: 'Voided', label: 'Voided' },
                      //  { value: 'Executed', label: 'Executed' }
                      //]}
                      onChange={dropdownStatusChangeHandler}
                      //submit={true}
                      select={{ value: statusFilter }}
                    />
                  </div>
                </div>
                <div className="col-md-2 col-xs-12" style={{ paddingTop: 30 }}>
                  <Button type="secondary" onClick={() => setUpdating(!updating)}>{getResource('ApplyFilter')}</Button>
                  </div>
                </div>
              </div>
            </Panel>
        </Collapse>
        <Table
          className='slide-l-r'
          key={'ratewatch-table'}
          columns={columns}
          dataSource={rateWatches}
          exportableProps={{
            fileName: 'RateWatches',
            btnProps: {
              type: "default",
              icon: <DownloadOutlined />,
            }
          }}
        />
      </>}
      {showTrackForm && <AddEditRateWatch
        //show            = {showTrackForm}
        rateWatchId={rateWatchIdSelected}
        onSaveClick={onSaveClick}
        oncloseAddEddit={handleCloseModalTrackForm}
        lookupTables={lookupTables}
      />}
    </>
  )
};

export default RateWatchScreen;