import { genericGetWithParameters } from './Networking';

export async function getAll() {
  try {
    let params = {};
    let response = await genericGetWithParameters('CurrencyPair/GetAll', params);
    return response;
  } catch (error) {
    console.error(error);
  }
}
