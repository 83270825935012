import React, { FunctionComponent, useEffect, useState } from 'react';
import useOnlineRights from '../../api/hooks/useOnlineRights';
import { OnlineRightsNames } from '../../helpers/OnlineRightsHelper';
import { getResource } from '../../helpers/ResourcesHelper';
import { Deal } from '../models/Deal';
import { OnlineOrder } from '../models/OnlineOrder';
import { ResponseGetListOfFundsOnHold } from '../myAccount/MyBalances';
import OrdersScreen from '../orders/OrdersScreen';
import ForwardContainer from './forwards/ForwardContainer';
import RegularPayments, { RegularPayment } from './regularPayments/RegularPayments';
import SpotContainer from './spots/SpotContainer';

interface PaymentsProps {
  fromRateWatchObj: OnlineOrder | null;
  fromMyAccountBalance: ResponseGetListOfFundsOnHold | null;
}

const Payments: FunctionComponent<PaymentsProps> = (props) => {
  const [currentScreen, setCurrentScreen] = useState('spot');
  const [updating, setUpdating] = useState(false);

  //FromOtherScreen
  const [fromOtherScreenDealObj, setFromOtherScreenDealObj] = useState<Deal>(new Deal(0, '', '', '', 0, 0, '', '', ''));
  const [fromOtherScreenSpot, setFromOtherScreenSpot] = useState<string>('');
  const [fromOtherScreenForward, setFromOtherScreenForward] = useState<string>('');
  const [regularPayment, setRegularPayment] = useState<RegularPayment | null>(null);
  const [objOrder, setObjOrder] = useState(undefined);
  const [isRegular, setIsRegular] = useState(false);

  let OL_SpotAccess = useOnlineRights(OnlineRightsNames.OL_SpotAccess);
  let OL_ForwardAccess = useOnlineRights(OnlineRightsNames.OL_ForwardAccess);
  let OL_RegularPaymentAccess = useOnlineRights(OnlineRightsNames.OL_RegularPaymentAccess);
  let OL_OrderAccess = useOnlineRights(OnlineRightsNames.OL_OrderAccess);

  useEffect(() => {
    if (props.fromRateWatchObj !== undefined && props.fromRateWatchObj !== null && props.fromRateWatchObj.DealType !== null) {
      switch (props.fromRateWatchObj.DealType) {
        case 'Spot':
          setCurrentScreen('spot');
          break;
        case 'Forward':
          setCurrentScreen('forward');
          break;
        case 'Order':
          setObjOrder({
            BuyCurrencyID: props.fromRateWatchObj.BuyCurrency,
            SellCurrencyID: props.fromRateWatchObj.SellCurrency,
            SettlementMethod: {
              value: props.fromRateWatchObj.SellCurrency + ";0;Client's Vostro Account;0",
              name: props.fromRateWatchObj.SellCurrency + " - Clients's Vostro Account - " + props.fromRateWatchObj.SellCurrency,
              details: [
                { label: "Currency", description: "" + props.fromRateWatchObj.SellCurrency },
                //{ label: "Balance", description: props.fromRateWatchObj.Amount },
                { label: "Instrument", description: "Client's Vostro Account" },
                { label: "Delivery Method", description: 'ACH' }
              ],
              label: props.fromRateWatchObj.SellCurrency + " - Clients's Vostro Account - " + props.fromRateWatchObj.SellCurrency
            }
          } as any);
          setCurrentScreen('orders');
          break;
        default:
          break;
      }
    }

    if (props.fromMyAccountBalance !== null) {
      setFromOtherScreenSpot('balances');

      let auxDeal = new Deal(0, 'spot', props.fromMyAccountBalance.CurrencyID, '', 0, props.fromMyAccountBalance.Amount, '', '', '');

      setFromOtherScreenDealObj(auxDeal);
      setCurrentScreen('spot');
    }
  }, [updating, props.fromMyAccountBalance]);

  const onChangeScreen = (screen: string) => {
    setCurrentScreen(screen);
  };

  const onMakeOrder = (payment: RegularPayment) => {
    setRegularPayment(payment);
    setCurrentScreen('orders');
    setIsRegular(true);
  };
  const onBackOrder = () => {
    setRegularPayment(null);
    if (isRegular) {
      setCurrentScreen('regularPayments');
    }
    setIsRegular(false);
  };

  const renderScreen = () => {
    switch (currentScreen) {
      case 'spot':
        return OL_SpotAccess && <SpotContainer fromOtherScreenDealObj={fromOtherScreenDealObj} fromOtherScreenSpot={fromOtherScreenSpot} />;
      case 'forward':
        return OL_ForwardAccess && <ForwardContainer fromOtherScreenDealObj={fromOtherScreenDealObj} fromOtherScreenForward={fromOtherScreenForward} />;
      case 'regularPayments':
        return OL_RegularPaymentAccess && <RegularPayments onMakeOrder={onMakeOrder} />;
      case 'orders':
        return OL_OrderAccess && <OrdersScreen dealID={0} regularPayment={regularPayment} objOrderScreen={objOrder} onBackOrder={onBackOrder} />;
      default:
        return <div></div>;
    }
  };

  return (
    <React.Fragment>
      <div className="navbar-horizontal slide-t-b">
        <div className="row">
          {OL_SpotAccess
            && <div
            className={currentScreen === 'spot' ? 'col-xs navbar-horizontal-option-selected' : 'col-xs navbar-horizontal-option'}
            onClick={() => {
              onChangeScreen('spot');
            }}
          >
            <span>{getResource('Title.Spot')}</span>
            </div>}

          {OL_ForwardAccess
            && <div
            className={currentScreen === 'forward' ? 'col-xs navbar-horizontal-option-selected' : 'col-xs navbar-horizontal-option'}
            onClick={() => {
              onChangeScreen('forward');
            }}
          >
            <span>{getResource('Title.Forward')}</span>
            </div>}

          {OL_RegularPaymentAccess
            && <div
            className={currentScreen === 'regularPayments' ? 'col-xs navbar-horizontal-option-selected' : 'col-xs navbar-horizontal-option'}
            onClick={() => {
              onChangeScreen('regularPayments');
            }}
          >
            <span>{getResource('Title.RegularPayments')}</span>
            </div>}

          {OL_OrderAccess
            && <div
            className={currentScreen === 'orders' ? 'col-xs navbar-horizontal-option-selected' : 'col-xs navbar-horizontal-option'}
            onClick={() => {
              onChangeScreen('orders');
            }}
          >
            <span>{getResource('Title.Orders')}</span>
            </div>}
        </div>
      </div>
      {renderScreen()}
    </React.Fragment>
  );
};

export default Payments;
