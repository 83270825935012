import _ from "lodash";
import React, { FunctionComponent, useEffect, useState } from 'react';
import GridLayout from 'react-grid-layout';
import '../../../node_modules/react-grid-layout/css/styles.css';
import '../../../node_modules/react-resizable/css/styles.css';
//Network
import { getAvailableWidgets, getDashboard, saveDashboard } from '../../api/NetworkingWidget';
import { getResource } from "../../helpers/ResourcesHelper";
//Widgets
import Last10Transactions from '../widgets/Last10Transactions/Last10Transactions';
import Last12MonthsTurnover from '../widgets/Last12MonthsTurnover/Last12MonthsTurnover';
import RateBoard from '../widgets/RateBoard/RateBoard';
import TopBeneficiaries from '../widgets/TopBeneficiaries/TopBeneficiaries';
import './Dashboard.scss';

interface DashboardScreenProps {
  onRateFromBoardClick: (objRate: any) => void,
  viewWidgetBeneficiary: boolean,
  viewWidgetTransactions: boolean,
  viewWidgetPayments: boolean,
  viewWidgetRate: boolean
}

const DashboardScreen: FunctionComponent<DashboardScreenProps> = (props: any) => {
  const [layout, setLayout] = useState([] as any);
  const [saveLayout, setSaveLayout] = useState([] as any);
  const [addWidgetText, setAddWidgetText] = useState(getResource('AddWidget'));
  const [showToolbox, setShowToolbox] = useState(false);
  const [availableWidgets, setAvailableWidgets] = useState([] as any);
  const [cardArrays, setCardArrays] = useState([] as any);
  const [finalToolbox, setFinalToolbox] = useState([] as any);

  let view_Widget_Beneficiary = true;
  let view_Widget_Transactions = true;
  let view_Widget_Payments = true;
  let view_Widget_Rate = true;

  if (props.viewWidgetBeneficiary !== undefined) { view_Widget_Beneficiary = props.viewWidgetBeneficiary; }
  if (props.viewWidgetTransactions !== undefined) { view_Widget_Transactions = props.viewWidgetTransactions; }
  if (props.viewWidgetPayments !== undefined) { view_Widget_Payments = props.viewWidgetPayments; }
  if (props.viewWidgetRate !== undefined) { view_Widget_Rate = props.viewWidgetRate; }

  const removeStyle: any = {
    position: "absolute",    
    right: "5px",
    paddingRight: "15px",
    fontWeight: 500,
    top: 0,
    paddingTop: '20px',
    cursor: "pointer",
    backgroundColor: "transparent"
  };

  useEffect(() => {
    const customer = JSON.parse(localStorage.getItem('UserSession')!);
    getListOfWidgets(customer.PersonID);
    getDashboard(customer.PersonID).then((dashboardResponse: any) => {
      console.log(dashboardResponse);
      if (dashboardResponse.data) {
        //let prevLayout = JSON.parse(dashboardResponse.data);
        let prevLayout = dashboardResponse.data;
        setLayout(prevLayout);
      }
    });

  }, [])

  useEffect(() => {
    cardsView();
  }, [layout])

  const getListOfWidgets = (userID: any) => {
    getAvailableWidgets(userID).then((response: any) => {
      if (response.ListOfWidgets) {
        setAvailableWidgets(response.ListOfWidgets);
      }
    })
  }

  const onTransactionClick = () => {
    let layoutArray = [...layout];
    let newId = 'transaction-widget';
    let found = layoutArray.some(el => el.i === newId);
    if (!found) layoutArray.push({ i: newId, x: 0, y: 0, w: 4, h: 8 });
    setLayout(layoutArray);
  }

  const onBeneficiariesClick = () => {
    let layoutArray = [...layout];
    let newId = 'beneficiaries-widget';
    let found = layoutArray.some(el => el.i === newId);
    if (!found) layoutArray.push({ i: newId, x: 0, y: 0, w: 6, h: 8 });
    setLayout(layoutArray);
  }

  const onPaymentsClick = () => {
    let layoutArray = [...layout];
    let newId = 'payments-widget';
    let found = layoutArray.some(el => el.i === newId);
    if (!found) layoutArray.push({ i: newId, x: 0, y: 0, w: 6, h: 8 });
    setLayout(layoutArray);
  }

  const onRatesClick = () => {
    let layoutArray = [...layout];
    let newId = 'rates-widget';
    let found = layoutArray.some(el => el.i === newId);
    if (!found) layoutArray.push({ i: newId, x: 0, y: 0, w: 6, h: 8 });
    setLayout(layoutArray);
  }

  const onLayoutChange = (layout: any) => {
    const personID = JSON.parse(localStorage.getItem('UserSession')!)['PersonID'];
    setSaveLayout(layout);
    console.log(layout);
    saveDashboard(personID, JSON.stringify(layout));
  }

  const onRemoveItem = (key: any) => {
    setLayout(_.reject(layout, { i: key }));
  }

  const onAddWidgetClick = () => {
    if (!showToolbox) {
      setAddWidgetText(getResource('Button.Close'))
    } else {
      setAddWidgetText(getResource('AddWidget'))
    }
    cardsView();
    setShowToolbox(!showToolbox);
  }

  const onRowRateClick = (objRate: any) => {
    if (props.onRateFromBoardClick) {
      props.onRateFromBoardClick(objRate);
    }
  }

  // layout is an array of objects, see the demo for more complete usage
  /*const layout = [
      //{i: 'a', x: 0, y: 0, w: 4, h: 8},
      //{i: 'b', x: 0, y: 0, w: 6, h: 8},
      //{i: 'c', x: 0, y: 0, w: 10, h: 4},
      //{i: 'd', x: 0, y: 0, w: 6, h: 8},
      //{i: 'e', x: 0, y: 0, w: 6, h: 8},
      //{i: 'f', x: 0, y: 0, w: 6, h: 8},
      //{i: 'g', x: 0, y: 0, w: 6, h: 8},
  ];*/
  let originalToolBoxArray = [
    {
      id: "transaction-widget", comp: view_Widget_Transactions && <div className="col-lg-3 col-md-3 col-xs-6">
          <button id="add-transaction" className="secondary-button" onClick={onTransactionClick}>Transactions Widget</button>
        </div>
    },
    {
      id: "beneficiaries-widget", comp: view_Widget_Beneficiary && <div className="col-lg-3 col-md-3 col-xs-6">
        <button id="add-beneficiaries" className="secondary-button" onClick={onBeneficiariesClick}>Beneficiaries Widget</button>
      </div>
    },
    {
      id: "payments-widget", comp: view_Widget_Payments && <div className="col-lg-3 col-md-3 col-xs-6">
        <button id="add-payments" className="secondary-button" onClick={onPaymentsClick}>Payments Widget</button>
      </div>
    },
    {
      id: "rates-widget", comp: view_Widget_Rate && <div className="col-lg-3 col-md-3 col-xs-6">
        <button id="add-rates" className="secondary-button" onClick={onRatesClick}>Rates Widget</button>
      </div>
    }
  ];

  const cardsView = () => {
    let cardArraysHelper = [] as any;    
    layout.forEach((element: any) => {
      switch (element.i) {
        case 'transaction-widget':
          if (view_Widget_Transactions) {
            cardArraysHelper.push(
              <div className="dashboard-card" key="transaction-widget" data-grid={{ x: element.x, y: element.y, w: element.w, h: element.h }}>
                <div className="dashboard-card-frame">
                  <Last10Transactions
                    width={'auto'}
                    height={'auto'}
                  />
                </div>
                <span
                  className="remove"
                  style={removeStyle}
                  onClick={onRemoveItem.bind(this, "transaction-widget")}
                >
                  x
                </span>
              </div>
            );
            originalToolBoxArray = originalToolBoxArray.filter(x => {
              return x.id !== 'transaction-widget';
            });
          }
          break;
        case 'beneficiaries-widget':           
          if (view_Widget_Beneficiary) {
          cardArraysHelper.push(
            <div className="dashboard-card" key="beneficiaries-widget" data-grid={{ x: element.x, y: element.y, w: element.w, h: element.h }}>
              <div className="dashboard-card-frame">
                <TopBeneficiaries
                  width={'auto'}
                  height={'auto'}
                />
              </div>
              <span
                className="remove"
                style={removeStyle}
                onClick={onRemoveItem.bind(this, "beneficiaries-widget")}
              >
                x
              </span>
            </div>
          );

          originalToolBoxArray = originalToolBoxArray.filter(x => {
            return x.id !== 'beneficiaries-widget';
          });   
          }
          break;
        case 'payments-widget':
          if (view_Widget_Payments) {
          cardArraysHelper.push(
            <div className="dashboard-card" key="payments-widget" data-grid={{ x: element.x, y: element.y, w: element.w, h: element.h }}>
              <div className="dashboard-card-frame-NoOverflow">
                <Last12MonthsTurnover
                  customerID={JSON.parse(localStorage.getItem('UserSession')!).CustomerID}
                  width={'auto'}
                  height={'95%'}
                />
              </div>
              <span
                className="remove"
                style={removeStyle}
                onClick={onRemoveItem.bind(this, "payments-widget")}
              >
                x
              </span>
            </div>
          );
          originalToolBoxArray = originalToolBoxArray.filter(x => {
            return x.id !== 'payments-widget';
          });
          }
          break;
        case 'rates-widget':
          if (view_Widget_Rate) {
          cardArraysHelper.push(
            <div className="dashboard-card" key="rates-widget" data-grid={{ x: element.x, y: element.y, w: element.w, h: element.h }}>
              <div className="dashboard-card-frame">
                <RateBoard
                  width={'auto'}
                  height={'auto'}
                  onRowRateClick={onRowRateClick}
                />
              </div>
              <span
                className="remove"
                style={removeStyle}
                onClick={onRemoveItem.bind(this, "rates-widget")}
              >
                x
              </span>
            </div>
          );
          originalToolBoxArray = originalToolBoxArray.filter(x => {
            return x.id !== 'rates-widget';
          });
          }
          break;
        default:
          break;
      }
    });

    let finalToolboxHelper = [] as any;


    for (var i = 0; i < availableWidgets.length; i++) {
      for (var j = 0; j < originalToolBoxArray.length; j++) {
        if (availableWidgets[i].internalname === originalToolBoxArray[j].id) {
          finalToolboxHelper.push(originalToolBoxArray[j].comp);
        }
      }
    }
    setFinalToolbox(finalToolboxHelper);
    setCardArrays(cardArraysHelper);
  }

  return (

    <div className="main-container">
      <div className="main-container">
        <main className="wrapper">
          <header className="clearfix">
            <h3> {getResource('Title.Dashboard')}</h3>
            <h3 id="mainmessages-pending-aproval">&nbsp;</h3>
          </header>
          <section className="row" id="add-box" style={{ display: "flex" }}>
            <div className="col-lg-9 col-md-9 col-xs-6"></div>
            <div className="col-lg-3 col-md-3 col-xs-6"><button className="secondary-button" onClick={onAddWidgetClick}>{addWidgetText}</button></div>
          </section>
          {showToolbox &&
            <div className="showcase row" id="toolbox">
              {finalToolbox}
            </div>}
          <section className="tab-option" id="grid" style={{ display: "block" }}>
            <GridLayout className="layout" cols={10} rowHeight={30} width={1200} onLayoutChange={onLayoutChange}>
              {cardArrays}
            </GridLayout>
          </section>
        </main>
      </div>
    </div>
  )
}
export default DashboardScreen;