const en_resources = require('../assets/en/resources.json');
const ch_resources = require('../assets/ch/resources.json');

export function getResource(resourceName:string){
    let language = localStorage.getItem('OnlineLanguage');
    switch (language) {
        case 'English':
            return en_resources[resourceName];
        case 'Chinese':
            return ch_resources[resourceName];
        default:
            return en_resources[resourceName];
    }
}