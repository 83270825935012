import { Breadcrumb } from 'antd';
import React, { useContext, useState } from 'react';
import AppContext from '../../../App.context';
import { getResource } from '../../../helpers/ResourcesHelper';
import OrdersDisbursement from '../../orders/ordersDisbursement/OrdersDisbursement';
import PaymentDisburse from '../disburse/PaymentDisburse';
import DrawdownMultipleBeneficiaries from '../forwards/Drawdown.tsx/DrawdownMultipleBeneficiaries';
import Summary from '../summary/Summary';
import TransactionsTable from './TransactionsTable';

const TransactionsContainer = () => {
  const { showModal } = useContext(AppContext);
  const [selectedDealID, setSelectedDealID] = useState(0);
  const [dealObject, setDealObject] = useState({} as any);
  const [screen, setScreen] = useState('table');

  const [drawdownDealID, setDrawdownDealID] = useState(0);

  const onCancelClick = () => {
    setScreen('table');
    setDealObject({});
    setSelectedDealID(0);
    setDrawdownDealID(0);
  };

  const selectRow = (dealID: number) => {
    setSelectedDealID(dealID);
    setScreen('info');
  };

  const selectToDisburse = (deal: any) => {
    setSelectedDealID(deal.DealID);
    setDealObject(deal);
    setScreen('disburse');
  };
  const selectToDrawdown = (deal: any) => {
    setSelectedDealID(deal.DealID);
    setDealObject(deal);
    setScreen('drawdown');
  };
  const selectToOrder = (deal: any) => {
    setSelectedDealID(deal.DealID);
    setDealObject(deal);
    setScreen('orders');
  };
  const onFinishDisbursement = (dealId: number) => {
    if (dealId > 0) {
      setSelectedDealID(dealId);
      setScreen('table');
    }
  };

  const onFinishDrawdownMultipleBeneficiaries = (dealId: number) => {
    setDrawdownDealID(dealId);
  };

  const onError = (errorMessage: string) => {
    onCancelClick();
    showModal('Error', errorMessage);
  };

  return (
    <React.Fragment>
      <div className="breadcrumb-container">
        <Breadcrumb separator="">
          <Breadcrumb.Item onClick={onCancelClick}>{getResource('Title.Transactions')}</Breadcrumb.Item>
          {screen === 'info' && selectedDealID !== 0 && <Breadcrumb.Separator children=">" />}
          {screen === 'info' && selectedDealID !== 0 && <Breadcrumb.Item>Deal {selectedDealID}</Breadcrumb.Item>}
          {(screen === 'info' && selectedDealID !== 0) ||
            (screen === 'drawdown' && drawdownDealID !== 0 && (
              <Breadcrumb.Item>
                <label className="exit-button" onClick={onCancelClick}>
                {getResource('Btn_Exit')}
                </label>
              </Breadcrumb.Item>
            ))}
        </Breadcrumb>
      </div>
      {screen === 'table' && <TransactionsTable selectRow={selectRow} selectToDisburse={selectToDisburse} selectToDrawdown={selectToDrawdown} selectToOrder={selectToOrder} />}
      {screen === 'info' && selectedDealID !== 0 && <Summary dealHeaderID={selectedDealID} useConfirmationButton={true} />}
      {screen === 'disburse' && selectedDealID !== 0 && (
        <PaymentDisburse
          dealObject={dealObject}
          sellCurrency={dealObject.SellCurrency}
          buyCurrency={dealObject.BuyCurrency}
          //amount={dealObject.OutstandingBalance}
          amount={dealObject.SellAmount}
          dealHeaderID={dealObject.DealID}
          valueDate={dealObject.ValueDate}
          customerRate={dealObject.CustomerRate}
          settlementInstrument=""
          deliveryMethod=""
          onSubmit={() => {
            setScreen('info');
          }}
          onError={onError}
        />
      )}
      {screen === 'orders' && selectedDealID !== 0 && (
        <OrdersDisbursement
          buyCurrencyID={dealObject.BuyCurrency}
          buyCurrencyItem={{ value: dealObject.BuyCurrency, baseCurrencyDirectRate: dealObject.BaseCurrencyDirectRate }}
          sellCurrencyID={dealObject.SellCurrency}
          valueDate={dealObject.ValueDate}
          settlementMehotd={''}
          onFinish={onFinishDisbursement}
          onBack={() => {
            setScreen('table');
          }}
          dealHeaderID={dealObject.DealID}
        />
      )}
      {screen === 'drawdown' && selectedDealID !== 0 && (
        <>
          {drawdownDealID === 0 && (
            <DrawdownMultipleBeneficiaries
              dealHeaderID={selectedDealID}
              onExit={() => {
                setScreen('table');
              }}
              onFinish={onFinishDrawdownMultipleBeneficiaries}
            />
          )}
          {drawdownDealID !== 0 && <Summary dealHeaderID={drawdownDealID} useNewTradeButton={false} useDepositRequired={false} useConfirmationButton={true} />}
        </>
      )}
    </React.Fragment>
  );
};

export default TransactionsContainer;
