import React, { useState } from 'react';
import useOnlineRights from '../../../api/hooks/useOnlineRights';
import { OnlineRightsNames } from '../../../helpers/OnlineRightsHelper';
import { getResource } from '../../../helpers/ResourcesHelper';
import Addresses from './Addresses';
import Contacts from './contacts/Contacts';
import DetailsIndividual from './details/DetailsIndividual';
import DetailsCorporate from './DetailsCorporate';
import MyBankAccounts from './MyBankAccounts';

const MyInformation = () => {
  const [showTab, setShowTab] = useState('details');
  const customerType = JSON.parse(localStorage.getItem('UserSession')!).CustomerType;

  //rights
  let OL_MyAccountInformation_Details = useOnlineRights(OnlineRightsNames.OL_MyAccountInformation_Details);
  let OL_MyAccountInformation_Addresses = useOnlineRights(OnlineRightsNames.OL_MyAccountInformation_Addresses);
  let OL_MyAccountInformation_MyBankAccounts = useOnlineRights(OnlineRightsNames.OL_MyAccountInformation_MyBankAccounts);
  let OL_MyAccountInformation_Contacts = useOnlineRights(OnlineRightsNames.OL_MyAccountInformation_Contacts);
  debugger;
  const onClick = (tab: string) => {
    setShowTab(tab);
  };

  const tabSelected = (tab: string) => {
    return showTab === tab ? 'col-xs navbar-horizontal-option-selected' : 'col-xs navbar-horizontal-option';
  };

  return (
    <React.Fragment>
      <div className="navbar-horizontal slide-r-l">
        <div className="row">
          {OL_MyAccountInformation_Details
            && <div id="my-information-details" onClick={() => onClick('details')} className={tabSelected('details')}>
            <span>{getResource('Details')}</span>
            </div>}
          {OL_MyAccountInformation_Addresses
            && <div id="my-information-addresses" onClick={() => onClick('addresses')} className={tabSelected('addresses')}>
              <span>{getResource('Label.Addresses')}</span>
            </div>}
          {OL_MyAccountInformation_MyBankAccounts
            && <div id="my-information-my-bank-accounts" onClick={() => onClick('my-bank-accounts')} className={tabSelected('my-bank-accounts')}>
            <span>{getResource('MyBankAccounts')}</span>
            </div>}
          {OL_MyAccountInformation_Contacts
            && <div id="my-information-contacts" onClick={() => onClick('contacts')} className={tabSelected('contacts')}>
            <span>{getResource('Contacts')}</span>
            </div>}
        </div>
      </div>
      {showTab === 'details' && OL_MyAccountInformation_Details && (customerType === 'Corporate' ? <DetailsCorporate /> : <DetailsIndividual />)}
      {showTab === 'addresses' && OL_MyAccountInformation_Addresses && <Addresses />}
      {showTab === 'my-bank-accounts' && OL_MyAccountInformation_MyBankAccounts && <MyBankAccounts />}
      {showTab === 'contacts' && OL_MyAccountInformation_Contacts && <Contacts />}
    </React.Fragment>
  );
};

export default MyInformation;
