import { genericGetWithParameters } from './Networking';

export async function getStatesByCountryMin(countryID: string) {
  try {
    let params = {
      CountryID: countryID
    };
    let response = await genericGetWithParameters('States/GetStatesByCountry_min', params);
    return response;
  } catch (error) {
    console.error(error);
  }
}
