import { genericCallWithBody, genericDeleteWithParameters, genericGetWithParameters } from './Networking';

export async function getListOfDealTemplates() {
  try {
    const contactID = localStorage.getItem('ContactID');
    let params = {
      "contactID": contactID,
    };

    let responseJWT = await genericGetWithParameters('DealTemplate/GetListofDealTemplates', params);
    return responseJWT
  } catch (error) {
    console.error(error);
  }
}

export async function saveDealTemplate(objDealTemplate: any) {
  var method = 'POST';
  var apiName = 'DealTemplate/Post';

  let response = await genericCallWithBody(method, apiName, objDealTemplate);
  return response;
}

export async function deleteTemplateByIdAndContactId(templateId: string) {
  const contactID = localStorage.getItem('ContactID');
  let params = {
    'TemplateID': templateId,
    'ContactId': contactID
  };
  let response = await genericDeleteWithParameters('DealTemplate/DeleteTemplateByIdAndContactId', params);
  return response;
}