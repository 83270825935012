import {genericGetWithParameters} from './Networking';

export async function getParametersByName(parameterNames: string){
    try{
        let params = {
            "parameterNames":parameterNames,
        };

        let response = await genericGetWithParameters('GlobalSystemParameter/GetParametersByName', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}