import { genericGetWithParameters, genericGetWithParametersNoEncrypted } from './Networking';

export const getAllCountries = async () => {
    try {
        const params = {
            'Page': 0,
            'Items': 0
        }
        const response = await genericGetWithParameters('Country/Get', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}
export const getCountryByName = async (country: string) => {
    try {
        const params = {
            'country': country
        }
        const response = await genericGetWithParameters('Country/GetCountryName', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}
export async function needsIban(countryId: string) {
    try {
        let params = {
            CountryId: countryId
        }
        let response = await genericGetWithParametersNoEncrypted('Country/NeedsIban', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}