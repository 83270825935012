import { FunctionComponent } from 'react';

interface ButtonProps {
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  type?: 'primary' | 'active' | 'disable' | 'secondary' | 'search';
  id?: string;
  disabled?: boolean;
}

const Button: FunctionComponent<ButtonProps> = (props) => {
  switch (props.type) {
    case 'primary':
      return (
        <button id={props.id} className="primary-button" onClick={props.onClick} disabled={props.disabled}>
          {props.children}
        </button>
      );
    case 'active':
      return (
        <button id={props.id} className="active-button" onClick={props.onClick} disabled={props.disabled}>
          {props.children}
        </button>
      );
    case 'disable':
      return <button id={props.id} className="disable-button" disabled={props.disabled}>{props.children}</button>;
    case 'secondary':
      return (
        <button id={props.id} className="secondary-button" onClick={props.onClick} disabled={props.disabled}>
          {props.children}
        </button>
      );
    case 'search':
      return (
        <button id={props.id} className="search-button" onClick={props.onClick} disabled={props.disabled}>
          {props.children}
        </button>
      );
    default:
      return (
        <button id={props.id} className="primary-button" onClick={props.onClick} disabled={props.disabled}>
          {props.children}
        </button>
      );
  }
};

export default Button;
