import { DownloadOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import { useEffect, useState } from 'react';
import { getApprovalHistoryByEntityId } from '../../api/NetworkingApprovals';
import DataGrid from '../../components/table/DataGrid';
import { getResource } from '../../helpers/ResourcesHelper';

const ApprovalsHistoryByEntityForm = (props: any) => {
  const [approvalsHistory, setApprovalsHistory] = useState([]);

  const columnsApprovalsHistory = [
    {
      title: getResource('RequestedBy'),
      dataIndex: '',
      key: '',
      //@ts-ignore
      render: (record) => <p>{record.RequestedBy + ' on ' + record.RequestedOn}</p>
    },
    {
      title: getResource('ApprovedBy'),
      dataIndex: '',
      key: '',
      //@ts-ignore
      render: (record) => (record.ApprovedBy != null ? <p>{record.ApprovedBy + ' on ' + record.ApprovedOn}</p> : '')
    },
    {
      title: getResource('RejectedBy'),
      dataIndex: '',
      key: '',
      //@ts-ignore
      render: (record) => (record.RejectedBy != null ? <p>{record.RejectedBy + ' on ' + record.RejectedOn}</p> : '')
    }
  ];

  useEffect(() => {
    if (props.entityID > 0 && props.entityType != '') {
      getApprovalHistoryByEntityId(props.entityType, props.entityID).then((response) => {
        console.log(response);
        if (response !== undefined && response !== null && response.ApprovalHistory !== undefined && response.ApprovalHistory !== null) {
          if (response.httpStatusCode === 200) {
            setApprovalsHistory(response.ApprovalHistory);
          }
        }
      });
    }
  }, [props.entityID, props.entityType]);

  const onClose = () => {
    props.onClose();
  };

  return (
    <div>
      <Modal
        title={<h3 className="component-title">{getResource('ApprovalHistory')}</h3>}
        centered
        visible={props.showApprovalsHistory}
        footer={null}
        onCancel={onClose}
        maskClosable={false}
        width={'60%'}
        zIndex={1021}
        /*bodyStyle= {{
            paddingTop: 15,
            paddingBottom: 15,
            marginTop: 0
        }}*/
      >
        <DataGrid key={'approvals-modal-table-' + props.type} columns={columnsApprovalsHistory} dataSource={approvalsHistory}
          exportableProps={{
            fileName: 'ApprovalsHistory',
            btnProps: {
              type: "default",
              icon: <DownloadOutlined />,
            }
          }} />
      </Modal>
    </div>
  );
};

export default ApprovalsHistoryByEntityForm;
