import { DollarCircleOutlined, DownloadOutlined, ZoomInOutlined } from "@ant-design/icons";
import { Table } from "ant-table-extensions";
import { Collapse, Tooltip, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment, { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import { getOnlineBeneficiaryPayments } from '../../../api/NetworkingBeneficiaries';
import { formatToCurrency } from "../../../helpers/FormatHelper";
import { getResource } from "../../../helpers/ResourcesHelper";
import Button from '../../inputs/Button';
import DatePicker from '../../inputs/DatePicker';
import { BeneficiaryPayment } from '../../models/BeneficiaryPayment';

const { Panel } = Collapse;

const PaymentsTable = (props: any) => {

  const [beneficiaryID, setBeneficiaryID] = useState(props.beneficiaryID);
  const [payments, setPayments] = useState([]);
  const userSession = JSON.parse(localStorage.getItem('UserSession')!);
  const [updating, setUpdating] = useState<boolean>(false);
  const [fromDate, setFromDate] = useState<string>(moment().subtract(30, 'days').format('YYYY/MM/DD'));
  const [momentFromDate, setMomentFromDate] = useState<Moment>(moment().subtract(30, 'days'));
  const [toDate, setToDate] = useState<string>(moment().format('YYYY/MM/DD'));
  const [momentToDate, setMomentToDate] = useState<Moment>(moment());

  const selectRow = (record: any) => {
    props.selectRow(record.BeneficiaryPaymentID);
  };

  const selectDealRow = (record: any) => {
    props.selectDealRow(record.DealID);
  };

  const columns: ColumnsType<BeneficiaryPayment> = [
    {
      title: getResource('Label.PaymentID'),
      dataIndex: 'BeneficiaryPaymentID',
      key: 'BeneficiaryPaymentID',
      align: 'center',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.BeneficiaryPaymentID - b.BeneficiaryPaymentID
    },
    {
      title: getResource('Table_TH_DealID'),
      dataIndex: 'DealID',
      key: 'DealID',
      align: 'center',
      sorter: (a, b) => a.DealID - b.DealID
    },
    {
      title: getResource('Table_TH_DealValueDate'),
      dataIndex: 'DealValueDate',
      key: 'DealValueDate',
      align: 'center',
      sorter: (a, b) => new Date(a.DealValueDate).getTime() - new Date(b.DealValueDate).getTime()
    },
    {
      title: getResource('Table_TH_ValueDate'),
      dataIndex: 'ValueDate',
      key: 'ValueDate',
      align: 'center',
      sorter: (a, b) => new Date(a.ValueDate).getTime() - new Date(b.ValueDate).getTime()
    },
    {
      title: getResource('Table_TH_Amount'),
      dataIndex: 'Amount',
      key: 'Amount',
      align: 'center',
      sorter: (a, b) => a.Amount - b.Amount,
      render: (value: any, obj: any, index: any) => (
        <span>
          {formatToCurrency(obj.Amount, obj.Currency)} {obj.Currency}
        </span>
      )
    },
    {
      title: getResource('Table_TH_Instrument'),
      dataIndex: 'Instrument',
      key: 'Instrument',
      align: 'center',
      sorter: (a, b) => a.Instrument.localeCompare(b.Instrument)
    },
    {
      title: getResource('Table_TH_Status'),
      dataIndex: 'Status',
      key: 'Status',
      align: 'center',
      sorter: (a, b) => a.Status.localeCompare(b.Status)
    },
    {
      title: getResource('Table_TH_Actions'),
      dataIndex: 'actions',
      key: 'actions',
      align: 'left',
      render: (value: any, obj: any, index: any) => (
        <>
          <Tooltip title={getResource('Js_Link_ViewPayment')}>
            <Typography.Link
              onClick={() => {
                selectRow(obj);
              }}
            >
              <ZoomInOutlined />
            </Typography.Link>
          </Tooltip>
          <Tooltip title={getResource('Js_Link_ViewDeal')}>
            <Typography.Link
              onClick={() => {
                selectDealRow(obj);
              }}
            >
              <DollarCircleOutlined />
            </Typography.Link>
          </Tooltip>
        </>
      )
    }
  ];

  const onResetFilters = () => {
    setFromDate(moment().subtract(30, 'days').format('YYYY/MM/DD'));
    setMomentFromDate(moment().subtract(30, 'days'));
    setToDate(moment().format('YYYY/MM/DD'));
    setMomentToDate(moment());
    setUpdating(!updating);
  };

  const onChangeFromDate = (date: Moment | null, dateString: string) => {
    setFromDate(dateString);
    setMomentFromDate(date as Moment);
  };

  const onChangeToDate = (date: Moment | null, dateString: string) => {
    setToDate(dateString);
    setMomentToDate(date as Moment);
  };

  const loadPayments = () => {
    if (userSession != null) {
      getOnlineBeneficiaryPayments(beneficiaryID, fromDate, toDate).then((response) => {
        setPayments(response.BeneficiaryPayments);
      });
    }
  }

  useEffect(() => {
    loadPayments();
  }, [updating]);

  useEffect(() => {
    setBeneficiaryID(props.beneficiaryID);
    loadPayments();
  }, [props.beneficiaryID]);

  return (
    <React.Fragment>
      <Collapse defaultActiveKey={['1']} style={{ marginBottom: '15px' }}>
        <Panel header={'Filter Options'} key="1">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-3 col-xs-12">
                <div className="row">
                  <span className="input-label">{getResource('FromIssueDate')}:</span>
                </div>
                <div className="row">
                  <DatePicker
                    id="ratewatch-datepicker-fromdate"
                    key="ratewatch-datepicker-fromdate"
                    onChange={onChangeFromDate}
                    value={momentFromDate}
                    submit={true}
                    errorMessage={"Required"}
                    valueValidation={fromDate}
                  />
                </div>
              </div>
              <div className="col-md-3 col-xs-12">
                <div className="row">
                  <span className="input-label">{getResource('ToIssueDate')}:</span>
                </div>
                <div className="row">
                  <DatePicker
                    id="ratewatch-datepicker-todate"
                    key="ratewatch-datepicker-todate"
                    onChange={onChangeToDate}
                    value={momentToDate}
                    submit={true}
                    errorMessage={"Required"}
                    valueValidation={toDate}
                  />
                </div>
              </div>
              <div className="col-md-2 col-xs-12" style={{ paddingTop: 30 }}>
                <Button type="secondary" onClick={() => setUpdating(!updating)}>
                  {getResource('ApplyFilter')}
                </Button>
              </div>
              <div className="col-md-2 col-xs-12" style={{ paddingTop: 30 }}>
                <Button type="primary" onClick={onResetFilters}>
                  {getResource('Reset')}
                </Button>
              </div>
            </div>
          </div>
        </Panel>
      </Collapse>
      <Table
        className="slide-l-r"
        key={'transactions-table'}
        columns={columns}
        dataSource={payments}
      /*onRow={(record, rowIndex) => ({
        onClick: () => {
          selectRow(record);
        }
      })}*/
        exportableProps={{
          fileName: 'Payments',
          btnProps: {
            type: "default",
            icon: <DownloadOutlined />,
          }
        }}
      />
    </React.Fragment>
  );
}

export default PaymentsTable;