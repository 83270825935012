import { Breadcrumb } from 'antd';
import React, { useState } from 'react';
import { getResource } from '../../../helpers/ResourcesHelper';
import Summary from '../summary/Summary';
import BeneficiaryPaymentsTable from './BeneficiaryPaymentsTable';
import PaymentDetail from './PaymentDetail';
import PaymentsTable from './PaymentsTable';

const BeneficiaryPaymentsContainer = () => {
  const [selectedBeneficiaryID, setSelectedBeneficiaryID] = useState(0)
  const [selectedPaymentID, setSelectedPaymentID] = useState(0)
  const [selectedDealID, setSelectedDealID] = useState(0)
  const [screen, setScreen] = useState('table');

  const onCancelClick = () => {
    setScreen('table');
  };

  const onCancelDetailClick = () => {
    setScreen('payments');
  };

  const selectRow = (beneficiaryID: number) => {
    setSelectedBeneficiaryID(beneficiaryID);
    setScreen('payments');
  };

  const selectPaymentRow = (paymentID: number) => {
    setSelectedPaymentID(paymentID);
    setScreen('paymentDetail');
  };

  const selectDealRow = (dealID: number) => {
    setSelectedDealID(dealID);
    setScreen('dealDetail');
  };


  return (
    <React.Fragment>
      <div className="breadcrumb-container">
        <Breadcrumb separator="">
          <Breadcrumb.Item onClick={onCancelClick}>{getResource('Title.BeneficiaryPayments')}</Breadcrumb.Item>
          {screen != 'table' && selectedBeneficiaryID !== 0 && <Breadcrumb.Separator children=">" />}
          {screen != 'table' && selectedBeneficiaryID !== 0 && <Breadcrumb.Item onClick={onCancelDetailClick}>{getResource('Js_TH_Beneficiary')} {selectedBeneficiaryID}</Breadcrumb.Item>}
          {screen === 'payments' && selectedBeneficiaryID !== 0 && <Breadcrumb.Item><label className="exit-button" onClick={onCancelClick}>{getResource('Btn_Exit')}</label></Breadcrumb.Item>}

          {screen === 'paymentDetail' && selectedPaymentID !== 0 && <Breadcrumb.Separator children=">" />}
          {screen === 'paymentDetail' && selectedPaymentID !== 0 && <Breadcrumb.Item>{getResource('Js_Action_Payment')} {selectedPaymentID}</Breadcrumb.Item>}
          {screen === 'paymentDetail' && selectedPaymentID !== 0 && <Breadcrumb.Item><label className="exit-button" onClick={onCancelDetailClick}>{getResource('Btn_Exit')}</label></Breadcrumb.Item>}

          {screen === 'dealDetail' && selectedDealID !== 0 && <Breadcrumb.Separator children=">" />}
          {screen === 'dealDetail' && selectedDealID !== 0 && <Breadcrumb.Item>{getResource('Deal')} {selectedDealID}</Breadcrumb.Item>}
          {screen === 'dealDetail' && selectedDealID !== 0 && <Breadcrumb.Item><label className="exit-button" onClick={onCancelDetailClick}>{getResource('Btn_Exit')}</label></Breadcrumb.Item>}
        </Breadcrumb>
      </div>
      {screen === 'table' && <BeneficiaryPaymentsTable selectRow={selectRow} />}
      {screen === 'payments' && selectedBeneficiaryID !== 0 && <PaymentsTable beneficiaryID={selectedBeneficiaryID} selectRow={selectPaymentRow} selectDealRow={selectDealRow} />}
      {screen === 'paymentDetail' && selectedPaymentID !== 0 && <PaymentDetail paymentID={selectedPaymentID} />}
      {screen === 'dealDetail' && selectedDealID !== 0 && <Summary dealHeaderID={selectedDealID} useConfirmationButton={true} />}
    </React.Fragment>
  );
};

export default BeneficiaryPaymentsContainer;
