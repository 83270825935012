import { genericCallWithBodyAndResponseDecoded, genericGetWithParameters } from './Networking';

export async function getApprovalHistoryByEntityId(entityType: string, entityID: number) {
  try {
    let params = {
      entityType: entityType,
      entityID: entityID
    };
    let response = await genericGetWithParameters('Deals/GetApprovalHistoryByEntityId', params);
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function sendPendingForApprovalEmail(sendMailApprovalRequest: any){
  try {
      let method = 'POST';
      let apiName = 'Deals/SendPendingForApprovalEmail';
      let response = await genericCallWithBodyAndResponseDecoded(method, apiName, sendMailApprovalRequest);
      return response;
  } catch (error) {
      console.error(error);
  }
}