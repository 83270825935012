import { Breadcrumb } from 'antd';
import React, { useContext, useState } from 'react';
import AppContext from '../../../App.context';
import { getResource } from '../../../helpers/ResourcesHelper';
import PendingDealDetail from './PendingDealDetail';
import PendingDealsTable from './PendingDealsTable';

const PendingDealList = (props: any) => {
  const [currentScreen, setCurrentScreen] = useState('deals');
  const { showModal } = useContext(AppContext);
  const [selectedDealID, setSelectedDealID] = useState(0);
  const [dealObject, setDealObject] = useState({} as any);
  const [screen, setScreen] = useState('table');

  const [drawdownDealID, setDrawdownDealID] = useState(0);

  const onChangeScreen = (screen: string) => {
    setCurrentScreen(screen);
  };

  const onCancelClick = () => {
    setScreen('table');
    setDealObject({});
    setSelectedDealID(0);
    setDrawdownDealID(0);
  };

  const selectRow = (dealID: number) => {
    setSelectedDealID(dealID);
    setScreen('info');
  };

  return (
    <React.Fragment>
      <div className="breadcrumb-container">
        <Breadcrumb separator="">
          <Breadcrumb.Item onClick={onCancelClick}>{getResource('PendingDeals')} </Breadcrumb.Item>
          {screen === 'info' && selectedDealID !== 0 && <Breadcrumb.Separator children=">" />}
          {screen === 'info' && selectedDealID !== 0 && <Breadcrumb.Item>{getResource('Deal')} {selectedDealID}</Breadcrumb.Item>}
          {screen === 'info' && selectedDealID !== 0 && <Breadcrumb.Item><label className="exit-button" onClick={onCancelClick}>{getResource('Btn_Exit')}</label></Breadcrumb.Item>}
        </Breadcrumb>
      </div>
      {screen === 'table' && <PendingDealsTable selectRow={selectRow} />}
      {screen === 'info' && selectedDealID !== 0 && <PendingDealDetail dealHeaderID={selectedDealID} onExit={onCancelClick} />}
    </React.Fragment>
  );
};

export default PendingDealList;
