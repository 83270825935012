import moment, { Moment } from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import useLookUpTables, { LookUpTableDetail } from '../../../../api/hooks/useLookUpTables';
import { getCustomerInformation, updateCustomerInformation } from '../../../../api/NetworkingCustomers';
import AppContext from '../../../../App.context';
import { getResource } from '../../../../helpers/ResourcesHelper';
import Button from '../../../inputs/Button';
import DatePicker, { dateFormat } from '../../../inputs/DatePicker';
import Dropdown from '../../../inputs/Dropdown';

const DetailsIndividual = () => {
  const { showModal } = useContext(AppContext);
  const lookUpTables = useLookUpTables('Salutation,Gender');
  const [customerName, setCustomerName] = useState('');
  const [customerID, setCustomerID] = useState(0);
  const [customerType, setCustomerType] = useState('');
  const [salutation, setSalutation] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [mobilePhone, setMobilePhone] = useState('');
  const [email, setEmail] = useState('');
  const [updateToken, setUpdateToken] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [gender, setGender] = useState('');

  useEffect(() => {
    const loadCustomerDetails = async () => {
      const _customerID = JSON.parse(localStorage.getItem('UserSession')!).CustomerID;
      const json = await getCustomerInformation(_customerID);
      if (json != null) {
        console.log(json);
        setCustomerName(json.CustomerName);
        setCustomerID(_customerID);
        setCustomerType(json.CustomerType);
        setSalutation(json.Salutation);
        setFirstName(json.FirstName);
        setLastName(json.LastName);
        setEmail(json.Email != null ? json.Email : '');
        setPhoneNumber(json.PhoneNumber);
        setMobilePhone(json.MobileNumber);
        setUpdateToken(json.UpdateToken);
        setGender(json.Gender);
        setDateOfBirth(json.DateOfBirth);
      }
    };
    loadCustomerDetails();
  }, []);

  const saveDetailsIndividual = async () => {
    const model = {
      CustomerID: customerID,
      CustomerType: customerType,
      Salutation: salutation,
      FirstName: firstName,
      LastName: lastName,
      Name: customerName,
      PhoneNumber: phoneNumber,
      MobilePhone: mobilePhone !== null ? mobilePhone : '',
      Email: email,
      UpdateToken: updateToken,
      entityproperties: [
        { PropertyDescription: 'Date Of Birth', PropertyValue: dateOfBirth },
        { PropertyDescription: 'Gender', PropertyValue: gender }
      ]
    };
    const json = await updateCustomerInformation(model);
    if (json != null) {
      if (json.httpStatusCode !== 200) {
        showModal(getResource('Js_MessageError_TitleError'), json.httpErrorMessage);
      } else {
        showModal(getResource('Js_MessageError_TitleSuccess'), getResource('Js_Message_SaveSuccessfully'));
      }
    }
  };

  const onChangeSalutation = (detail: LookUpTableDetail) => {
    setSalutation(detail.value);
  };

  const onChangeFirstName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFirstName(event.target.value);
  };

  const onChangeLastName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(event.target.value);
  };

  const onChangeGender = (detail: LookUpTableDetail) => {
    setGender(detail.value);
  };

  const onChangeDateOfBirth = (date: Moment | null, dateString: string) => {
    setDateOfBirth(dateString);
  };

  const onChangePrimaryPhone = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPhoneNumber(event.target.value);
  };

  const onChangeMobilePhone = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMobilePhone(event.target.value);
  };

  const onChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  return (
    <div className="screen-container">
      <div className="row center-xs">
        <div className="col-md-5 col-xs-12">
          <div className="row">
            <span className="input-label">{getResource('Label.Salutation')}</span>
          </div>
          <div className="row">
            <Dropdown name="salutation" title={getResource('SelectItem')} select={{ value: salutation }} list={lookUpTables.Salutation} onChange={onChangeSalutation} />
          </div>
        </div>
        <div className="col-md-5 col-xs-12"></div>
      </div>
      <div className="row center-xs">
        <div className="col-md-5 col-xs-12">
          <div className="row">
            <span className="input-label">{getResource('Label.FirstName')}</span>
          </div>
          <div className="row">
            <input id="customerName" value={firstName} onChange={onChangeFirstName} type="text" />
          </div>
        </div>
        <div className="col-md-5 col-xs-12">
          <div className="row">
            <span className="input-label">{getResource('Label.LastName')}</span>
          </div>
          <div className="row">
            <input id="lastName" value={lastName} onChange={onChangeLastName} type="text" />
          </div>
        </div>
      </div>
      <div className="row center-xs">
        <div className="col-md-5 col-xs-12">
          <div className="row">
            <span className="input-label">{getResource('Label.Gender')}</span>
          </div>
          <div className="row">
            <Dropdown name="gender" title={getResource('SelectGender')} select={{ value: gender }} list={lookUpTables.Gender} onChange={onChangeGender} />
          </div>
        </div>
        <div className="col-md-5 col-xs-12">
          <div className="row">
            <span className="input-label">{getResource('Label.DateBirth')}</span>
          </div>
          <div className="row">
            <DatePicker id="dateOfBirth" value={moment(dateOfBirth, dateFormat)} onChange={onChangeDateOfBirth} />
          </div>
        </div>
      </div>
      <div className="row center-xs">
        <div className="col-md-5 col-xs-12">
          <div className="row">
            <span className="input-label">{getResource('Label.PrimaryPhone')}</span>
          </div>
          <div className="row">
            <input id="primaryPhone" value={phoneNumber} onChange={onChangePrimaryPhone} type="text" />
          </div>
        </div>
        <div className="col-md-5 col-xs-12">
          <div className="row">
            <span className="input-label">{getResource('Label.SecondaryPhone')}</span>
          </div>
          <div className="row">
            <input id="secondaryPhone" value={mobilePhone} onChange={onChangeMobilePhone} type="text" />
          </div>
        </div>
      </div>
      <div className="row center-xs">
        <div className="col-md-5 col-xs-12">
          <div className="row">
            <span className="input-label">{getResource('Label.Email')}</span>
          </div>
          <div className="row">
            <input id="email" value={email} onChange={onChangeEmail} type="text" />
          </div>
        </div>
        <div className="col-md-5 col-xs-12"></div>
      </div>
      <div className="row center-xs">
        <div className="col-md-5 col-xs-12">
          <div className="row center-xs">
            <Button key="save-individual" onClick={saveDetailsIndividual}>{getResource('Button.Save')}</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailsIndividual;
