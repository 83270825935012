import { genericCallWithBodyAndResponseDecoded, genericGetWithParameters } from './Networking';

export async function getOnlineBeneficiaries(customerId: number) {
    try {
        let params = {
            CustomerID: customerId,
            CurrencyID: '',
            BankName: '',
            SearchText: '',
            Status: '',
            Approver: '',
            CountryName: '',
            CreatedOnFrom: '',
            CreatedOnTo: ''
        }

        let response = await genericGetWithParameters('Beneficiary/GetOnlineBeneficiaries', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getOnlineBeneficiary(beneficiaryId: number) {
    try {
        let params = {
            beneficiaryID: beneficiaryId
        }
        let response = await genericGetWithParameters('Beneficiary/GetOnlineBeneficiary', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function saveOnlineBeneficiary(beneficiary: any) {
    try {
        let method = 'POST';
        let apiName = 'Beneficiary/SaveOnlineBeneficiary';
        let response = await genericCallWithBodyAndResponseDecoded(method, apiName, beneficiary);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getBeneficiaryForDisbursementsScreen(customerID: number, currencyID: string) {
  try {
    let params = {
      customerID: customerID,
      currencyID: currencyID
    };
    let response = await genericGetWithParameters('Beneficiary/GetBeneficiaryForDisbursementsScreen', params);
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function importQuickBooksEntities(fileContent: string, userId: string, fileName: string, customerId: number, entityType: string) {
    try {
        let method = 'POST';
        let apiName = 'Beneficiary/ImportQuickBooksEntities';
        let model = {
            FileContent: fileContent,
            UserID: userId,
            FileName:fileName ,
            CustomerID: customerId,
            EntityType: entityType
          }
        let response = await genericCallWithBodyAndResponseDecoded(method, apiName, model);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getQuickBooksDataForExport(customerID: number, entityType: string) {
    try {
        let params = {
            customerID: customerID,
            entityType: entityType,
            fromDate: null,
            toDate: null
        };
        let response = await genericGetWithParameters('Beneficiary/GetQuickBooksDataForExport', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getOnlineBeneficiaryPayments(beneficiaryID: number, fromDate: any, toDate: any) {
    try {
        let params = {
            BeneficiaryID: beneficiaryID,
            FromDate: fromDate,
            ToDate: toDate
        }
        let response = await genericGetWithParameters('Beneficiary/GetOnlineBeneficiaryPayments', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getListOfPendingApprovalBeneficiaries(params: any) {
    try {
        let response = await genericGetWithParameters('Beneficiary/GetListOfPendingApprovalBeneficiaries', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function approveBeneficiary(approvalRequest: any) {
    try {
        let method = 'POST';
        let apiName = 'Beneficiary/ApproveBeneficiary';
        let response = await genericCallWithBodyAndResponseDecoded(method, apiName, approvalRequest);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getBeneficiariesByCustomerMin(customerID: any, status: string) {
    try {
        let params = {
            "CustomerID": customerID,
            "Status": status
        };

        let response = await genericGetWithParameters('Beneficiary/getBeneficiariesByCustomerMin', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const uploadBeneficiaryTemplate = async (customerId: any, fileContent: any, fileName: any) => {
    try {
        const model = {
            'CustomerId': customerId,
            'UploadedBy': localStorage.getItem('UserID'),
            'FileContent': fileContent,
            'FileName': fileName
        };

        const response = await genericCallWithBodyAndResponseDecoded('POST', 'Beneficiary/UploadBeneficiaryTemplate', model);
        return response;
    }
    catch (error) {
        console.error(error);
    }
}