import { DeleteOutlined } from "@ant-design/icons";
import { Divider } from "antd";
import Modal from "antd/lib/modal/Modal";
import Lottie from "lottie-react";
import moment from "moment";
import React, { FunctionComponent, useContext, useEffect, useRef, useState } from "react";
import uploadAnimation from '../../animations/new-upload.json';
import { uploadAttachment, uploadAttachmentWithoutLink } from "../../api/NetworkingAttachments";
import AppContext from "../../App.context";
import { formatValidInputClass, validInputClass } from "../../helpers/FormatHelper";
import { getResource } from "../../helpers/ResourcesHelper";
import Button from "../inputs/Button";


interface AttachmentsFormProps {
  id: string;
  show: { id: string, show: boolean };
  onClose: () => void;
  useLinkID: boolean;
  onUploadWithoutLink?: (attachmentID: string, fileName: string, description: string, fileContent: any) => void;
  linkType?: string;
  linkID?: string;
  showList?: boolean;
  attachmentsList?: any[];
  onClickDeleteAttachment?: (index: any) => void;
}

const AttachmentsForm: FunctionComponent<AttachmentsFormProps> = (props) => {

  const { showModal } = useContext(AppContext);
  const [description, setDescription] = useState('');
  const [fileName, setFileName] = useState('');
  const fileInput = useRef<HTMLInputElement>(null);

  const [validations, setValidations] = useState({
    description: true
  });

  const validationsFields = () => {
    setValidations({
      description: validInputClass(description)
    });
    var isValid = validInputClass(description);
    if (!isValid) {
      showModal(getResource('Js_MessageError_TitleError'), getResource('Js_MessageError_FormValidationNFieldNHighlighted'));
    }
    return isValid;
  }


  useEffect(() => {
    if (props.show)
      if (!props.show.show) {
        setFileName('');
        setDescription('');
      }
  }, [props.show.show])


  const onChangeDescription = (event: any) => {
    setDescription(event.target.value);
    setValidations({ description: true });
  }

  const onChangeFile = (event: any) => {
    //@ts-ignore
    var file = fileInput.current.files[0];
    if (file) {
      setFileName(file.name);
    }
  }

  const readFileAsBase64 = (file: File) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event: any) => {
        resolve(event.target.result);
      };

      reader.onerror = (err) => {
        reject(err);
      };

      reader.readAsDataURL(file);
    });
  }

  const handleUploadFile = (event: any) => {
    event.preventDefault();
    //@ts-ignore
    const file = fileInput.current.files[0];
    if (validationsFields()) {
    if (file) {
      //@ts-ignore
      readFileAsBase64(file).then((resolve, _) => {
        var fileContent = resolve.replace(/^data:.+;base64,/, '');
        //using linkID
        if (props.useLinkID) {
          uploadAttachment(description, props.linkType, props.linkID, file.name, fileContent).then((value) => { });
        }
        else {
          uploadAttachmentWithoutLink(file.name, description, fileContent, localStorage.getItem('UserID'), moment().format('YYYY/MM/DD')).then((attachmentResponse) => {
            props.onUploadWithoutLink!(attachmentResponse.id, file.name, description, fileContent);
            props.onClose();
          });
        }

      });
    } else {
      showModal(getResource('Js_MessageError_TitleError'), getResource('Js_MessageError_FormValidationNFieldNHighlighted'));
    }
    }
  }

  return (
    <>
      <Modal
        key={`${props.show.id}-attachment-form`}
        //title={<h3 className="component-title">{`Upload - ${props.show.id}`}</h3>}
        title={<h3 className="component-title">{getResource('Label.UploadAttachment')}</h3>}
        centered
        visible={props.show.show}
        footer={null}
        onCancel={props.onClose}
        maskClosable={false}
        width={'30%'}
        zIndex={1021}
        //forceRender={true}
        //destroyOnClose={true}
      >
        <div className="row file-box">
          <div className="col-xs-12">
            <Lottie animationData={uploadAnimation} style={{ height: '200px' }} />
          </div>
          <div className="col-xs-12 center-xs">
            <h5>{getResource('ClickOrDrag')}</h5>
          </div>
          <div className="row col-xs-12">
            <input key={`${props.show.id}-file-input`} id={`${props.show.id}-file-input`} type="file" ref={fileInput} className="file_upload" onChange={onChangeFile} />
          </div>
          <div className="row col-xs-12">
            <input type="text" placeholder="Select a file..." value={
              fileName
            } disabled />
          </div>
        </div>

        <div className="row">
          <input id={`${props.show.id}-description-input`} key={`${props.show.id}-description-input`} type="text" className={formatValidInputClass(validations.description)} placeholder="Description" value={description} onChange={onChangeDescription} />
        </div>

        <div className="row around-xs">
          <div className="col-xs-10 col-md-4">
            <Button type="secondary" onClick={props.onClose}>{getResource('Button.Cancel')}</Button>
          </div>
          <div className="col-xs-10 col-md-4">
            <Button type="primary" onClick={handleUploadFile}>{getResource('Upload')}</Button>
          </div>
        </div>

        {props.showList && props.attachmentsList && <>
          {props.attachmentsList!.length > 0 && <Divider />}
          <div className="row">
            {props.attachmentsList.length > 0 && <div className="col-xs-12 row around-xs">
              <div className="col-xs-5 bold-text">{getResource('FileName')}</div>
              <div className="col-xs-5 bold-text">{getResource('Description')}</div>
              <div className="col-xs-2 bold-text center-xs">{getResource('Delete')}</div>
            </div>}
            {props.attachmentsList.length > 0 && props.attachmentsList!.map((attachment, index) => {
              return (
                <div className="col-xs-12 row around-xs">
                  <div className="col-xs-5">{attachment.FileName}</div>
                  <div className="col-xs-5">{attachment.Description}</div>
                  <div className="col-xs-2 center-xs"><DeleteOutlined className='icon-delete-primary-color' onClick={() => {
                    //@ts-ignore
                    props.onClickDeleteAttachment(index)
                  }} /></div>
                </div>
              );
            })}
          </div></>}
      </Modal>
    </>
  );
}

export default AttachmentsForm;