import { genericCallWithBodyAndResponseDecoded, genericGetWithParameters } from './Networking';

export interface Address {
  'Address ID': string;
  'Address Type': string;
  Address: string;
  Country: string;
  State: string;
  SinceWhen$: string;
  Address2$: string;
  ZipCode$: string;
  UpdateToken$: string;
  key: string;
  order: string;
}

interface AddressResponse {
  addresses: Address[];
}

export const getAddressByPersonID = async (personID: string): Promise<AddressResponse | undefined> => {
  try {
    const params = {
      PersonID: personID,
      Page: 0,
      Items: 0
    };
    const response = await genericCallWithBodyAndResponseDecoded('POST', 'Address/GetAddressByPersonID', params);
    return response;
  } catch (error) {
    console.error(error);
  }
};

export interface GetAddress {
  Address2: string;
  AddressId: string;
  AddressType: string;
  AddressValue: string;
  City: string;
  CountryId: string;
  CountryName: string;
  CreatedBy: string;
  CreatedOn: string;
  DefaultAddress: boolean;
  District: string;
  OtherState: string;
  POBox: string;
  SinceWhen: string;
  StateId: number;
  StateName: string;
  Status: string;
  UpdateToken: string;
  UpdatedBy: string;
  UpdatedOn: string;
  ZipCode: string;
}
export const getAddressByID = async (addressID: string): Promise<GetAddress | undefined> => {
  try {
    const params = {
      AddressID: addressID
    };
    const response = await genericGetWithParameters('Address/GetAddressByID', params);
    return response;
  } catch (error) {
    console.error(error);
  }
};

interface SaveAddressRequest {
  PersonID: string;
  AddressID: string;
  AddressType: string;
  AddressValue: string;
  CountryID: string;
  StateID: number;
  City: string;
  Address2: string;
  ZipCode: string;
  OtherState: string;
  DefaultAddress: boolean;
  SinceWhen: string;
  Status: string;
  District: string;
  POBox: string;
  UpdatedBy: string;
  UpdateToken: string;
}

export const saveAddress = async (model: SaveAddressRequest) => {
  try {
    const response = await genericCallWithBodyAndResponseDecoded('POST', 'Address/SaveAddress', model);
    return response;
  } catch (error) {
    console.error(error);
  }
};
