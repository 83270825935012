import Modal from 'antd/lib/modal/Modal';
import { FunctionComponent, useContext, useEffect, useState } from 'react';
import { sendPendingForApprovalEmail } from '../../../api/NetworkingApprovals';
import AppContext from '../../../App.context';
import Button from '../../../components/inputs/Button';
import { getResource } from '../../../helpers/ResourcesHelper';
import Dropdown from '../../inputs/Dropdown';
interface SpotProps {
  dealHeaderID: number;
  approvals: string;
  onClose: Function;
  show: boolean
}

const SendApprovals: FunctionComponent<SpotProps> = (props) => {
  const { showModal } = useContext(AppContext);
  const [dealTitle, setDealTitle] = useState('');
  const [approvals, setApprovals] = useState([]);
  const [customer, setCustomer] = useState('');
  const [customerName, setCustomerName] = useState('');
  const [visibleMail, setVisibleMail] = useState(false);
  const [titleString, setTitleString] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (props.dealHeaderID !== 0 && props.approvals !== '') {
      debugger;
      var title = getResource('Js_Message_DealNumberApproved').replace('DealHeaderID', props.dealHeaderID)
      var json = JSON.parse(props.approvals);
      if (json !== undefined && json.ContactAbleToApprove !== undefined) {
        var listMails = [] as any;
        listMails.push({ value: '-1', label: getResource('SendToAll') });
        for (var i = 0; i < json.ContactAbleToApprove.length; i++) {
          listMails.push({
            value: json.ContactAbleToApprove[i].Email,
            label: json.ContactAbleToApprove[i].Name
          });
        }
        setApprovals(listMails);;
      }
      setCustomer('-1');
      setCustomerName(getResource('SendToAll'));
      setDealTitle(title);
    }
  }, [props.dealHeaderID, props.approvals])

  const onCancelClick = () => {
    props.onClose();
  };

  const dropdownApprovalChange = (value: any) => {
    if (value.value !== undefined) {
      setCustomer(value.value)
      setCustomerName(value.label)
    }
  };

  const sendClick = () => {
    if (customer != null && customer !== '') {
      var messageConfirm = '';
      if (customer === '-1') {
        messageConfirm = getResource('Js_Message_ConfirmSendMailApprovalAll');
      }
      else {
        messageConfirm = getResource('Js_Message_ConfirmSendMailApproval');
        messageConfirm = messageConfirm.replace('#name', customerName);
        messageConfirm = messageConfirm.replace('#email', customer);
      }
      popupSend(getResource('Js_Message_TittleConfirmSendMailApprovalAll'), messageConfirm);
    }
  }
  const yesConfirmMail = () => {
    let to = [] as any;
    if (customer === '-1') {
      var emails: any[] = approvals;
      emails.forEach((element) => {
        if (element.value != '-1') {
          to.push({ Email: element.value, Name: element.label });
        }
      })
    }
    else {
      to.push({ Email: customer, Name: customerName });
    }

    var userSession = JSON.parse(localStorage.getItem('UserSession')!);

    let sendMailApprovalRequest = {
      InstallationID: userSession.InstallationID,
      FromEmail: userSession.UserName,
      FromName: userSession.ContactName,
      To: to,
      EntityType: 'Deal',
      EntityName: props.dealHeaderID,
      Approvals: approvals
    }
    sendPendingForApprovalEmail(sendMailApprovalRequest).then(
      (json) => {
        if (json != null && json.error === "") {
          setVisibleMail(false);
          showModal(getResource('Js_MessageError_TitleSuccess'), getResource('Js_Message_MailSentSuccessfully'))
          onCancelClick();
        }
        else {
          if (json.errorMessage != null && json.error !== "") {
            setVisibleMail(false);
            showModal(getResource('Js_MessageError_TitleError'), json.errorMessage)
          }
        }
      }
    )
  }
  const cancelConfirmMail = () => { setVisibleMail(false) }
  const popupSend = (title: string, message: string) => {
    setTitleString(title);
    setMessage(message);
    setVisibleMail(true);
  }
  const modalSend = (
    <Modal
      title={<h3 className="component-title">{titleString}</h3>}
      centered
      visible={visibleMail}
      footer={null}
      onCancel={cancelConfirmMail}
      maskClosable={false}
      width={'40%'}
      zIndex={1010}
    >
      <div>
        <div>
          <h4>{message}</h4>
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <div className="col">
              <Button id="confirmation-afex-OK" type="primary" onClick={yesConfirmMail}>
                {getResource('Yes')}
              </Button>
            </div>
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="col">
              <Button id="confirmation-afex-cancel" type="primary" onClick={cancelConfirmMail}>
                {getResource('No')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );

  return (
    <div>
      {visibleMail && modalSend}
      <Modal
        title={
          <div className="row">
            <div className="col-xs-10 subtitle-text-primary">{dealTitle}</div>
          </div>
        }
        centered
        visible={props.show}
        footer={null}
        width={'40%'}
        zIndex={1000}
        closable={false}
        maskClosable={false}
      >
        <div className='row quote-box' style={{ marginBottom: 15 }}>
          <div className="row" style={{ justifyContent: 'left', paddingLeft: 10 }}>
            <label className="rate-pair">{getResource('PlaceHolder.SelectMail')}:</label>
          </div>
          <div className="row center-xs middle-xs">
            <div className="col-xs-9">
              <Dropdown
                name="customer"
                title={getResource('SendToAll')}
                list={approvals}
                onChange={dropdownApprovalChange}
                select={{ value: customer }}
              />
            </div>
            <div className="col-xs-3">
              <Button type="secondary" onClick={sendClick}>
                {getResource('Button.Send')}
              </Button>
            </div>
          </div>
        </div>
        <div className="row end-xs">
          <div className="col-xs-6 no-pad">
            <Button type="primary" onClick={onCancelClick}>
              {getResource('Button.Ok')}
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default SendApprovals;
