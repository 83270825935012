import { useEffect, useState } from 'react';
import { getAll } from '../NetworkingCurrencyPairs';

interface Currencies {}

const useCurrencyPairs = () => {
  const [currencyPairs, setCurrencyPairs] = useState({});
  const [needsReloading, setNeedsReloading] = useState(true);

  useEffect(() => {
    const loadCurrencyPairs = async () => {
      const json = await getAll();
      if (json.currencyPairs !== undefined) {
        let basicCurrencyPairs = json.currencyPairs;
        let helper = {};
        for (var j = 0; j < basicCurrencyPairs.length; j++) {
          //@ts-ignore
          helper[basicCurrencyPairs[j].CurrencyCode + basicCurrencyPairs[j].OutCurrencyCode] = {};
          //@ts-ignore
          helper[basicCurrencyPairs[j].CurrencyCode + basicCurrencyPairs[j].OutCurrencyCode].SpotDays = basicCurrencyPairs[j].SpotDays;
        }
        setCurrencyPairs(helper);
      } else {
        setCurrencyPairs({});
      }
      setNeedsReloading(false);
    };
    if (needsReloading) {
      loadCurrencyPairs();
    }
  }, [needsReloading]);

  return [currencyPairs, setNeedsReloading];
};

export default useCurrencyPairs;
