import { CheckCircleOutlined, CloseCircleOutlined, DownloadOutlined, ZoomInOutlined } from '@ant-design/icons';
import { Table } from "ant-table-extensions";
import { Tooltip, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React from 'react';
import { getResource } from '../../../helpers/ResourcesHelper';
import { Beneficiary } from '../../models/Beneficiary';

const PendingBeneficiariesTable = (props: any) => {

  const columns: ColumnsType<Beneficiary> = [
    {
      title: getResource('Js_TH_Name'),
      dataIndex: 'BeneficiaryName',
      key: 'bene_BeneficiaryName',
      align: 'center',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.BeneficiaryName.localeCompare(b.BeneficiaryName)
    },
    {
      title: getResource('Js_TH_Country'),
      dataIndex: 'CountryName',
      key: 'bene_CountryName',
      align: 'center',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.CountryName.localeCompare(b.CountryName)
    },
    {
      title: getResource('Table_TH_RegisterBy'),
      dataIndex: 'RegisterByInfo',
      key: 'bene_RegisterByInfo',
      align: 'center',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.RegisterByInfo.localeCompare(b.RegisterByInfo)
    },
    {
      title: getResource('Table_TH_Actions'),
      dataIndex: 'actions',
      key: 'actions',
      align: 'left',
      render: (value: any, obj: any, index: any) => (
        <>
          <Tooltip title={getResource('Js_Link_ViewBeneficiary')}>
            <Typography.Link
              onClick={() => {
                props.onShowBeneinfo(obj)
              }}
            >
              <ZoomInOutlined />
            </Typography.Link>
          </Tooltip>
          <Tooltip title={getResource('Js_Message_Approve')}>
            <Typography.Link
              onClick={() => {
                props.onShowApprovalAlert(obj)
              }}
            >
              <CheckCircleOutlined />
            </Typography.Link>
          </Tooltip>
          <Tooltip title={getResource('Js_Message_Reject')}>
            <Typography.Link
              onClick={() => {
                props.onShowRejectlAlert(obj);
              }}
            >
              <CloseCircleOutlined />
            </Typography.Link>
          </Tooltip>
        </>
      )
    }
  ];

  return (
    <React.Fragment>
      <Table
        className="slide-l-r"
        key={'pending-benes-table'}
        columns={columns}
        dataSource={props.benes}
        onRow={(record, rowIndex) => ({
          onClick: () => {
          }
        })}
        exportableProps={{
          fileName: 'PendingBeneficiaries',
          btnProps: {
            type: "default",
            icon: <DownloadOutlined />,
          }
        }}
      />
    </React.Fragment>
  );

}

export default PendingBeneficiariesTable;