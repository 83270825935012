import { DollarCircleOutlined, DownloadOutlined, HistoryOutlined, SyncOutlined } from '@ant-design/icons';
import { Table } from "ant-table-extensions";
import { Breadcrumb, Collapse, Divider, Modal, Tooltip, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment, { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import useOnlineRights from '../../api/hooks/useOnlineRights';
import { getHistoryAuditFundsOnHoldBalances, getListOfFundsOnHold } from '../../api/NetworkingFunds';
import { formatDateString, formatToCurrency } from '../../helpers/FormatHelper';
import { OnlineRightsNames } from '../../helpers/OnlineRightsHelper';
import { getResource } from '../../helpers/ResourcesHelper';
import Button from '../inputs/Button';
import DatePicker from '../inputs/DatePicker';
import OrdersScreen from '../orders/OrdersScreen';

const { Panel } = Collapse;
export class ResponseGetListOfFundsOnHold {
  Amount                  : number;
  CurrencyID              : string;
  PendingWithdrawalAmount : number;

  constructor(amount:number, currencyID:string, pendingWithdrawalAmount:number)
  {
    this.Amount = amount;
    this.CurrencyID = currencyID;
    this.PendingWithdrawalAmount = pendingWithdrawalAmount;
  }
}
export class ResponseGetHistoryOfFundsOnHold {
  AuditFundsOnHOldBalancesID: number;
  CurrencyID: string;
  'User Name': string;
  Balance: number;
  'Beginning Balance': number;
  TransactionAmount: number;
  Source: string;
  Destination: string;
  UpdatedOn: string;

  constructor(currencyID: string, auditFundsOnHOldBalancesID: number, userName: string, balance: number, beginningBalance: number, transactionAmount: number, source: string, destination: string, updatedOn: string) {
    this.CurrencyID = currencyID;
    this.AuditFundsOnHOldBalancesID = auditFundsOnHOldBalancesID;
    this['User Name'] = userName;
    this.Balance = balance;
    this['Beginning Balance'] = beginningBalance;
    this.TransactionAmount = transactionAmount;
    this.Source = source;
    this.Destination = destination;
    this.UpdatedOn = updatedOn;
  }
}
const MyBalances = (props:any) => {
  const [updating, setUpdating] = useState<boolean>(true);

  const [fundsOnHoldList, setFundsOnHoldList] = useState([]);
  const [historyFundsOnHoldList, setHistoryFundsOnHoldList] = useState([]);
  const [isHistory, setHistory] = useState(false);
  const [currencyID, setCurrencyID] = useState('');
  const [beginningBalance, setBeginningBalance] = useState('');
  const [currentBalance, setCurrentBalance] = useState('');
  // History
  const [momentFromDate, setMomentFromDate] = useState<Moment>(moment().subtract(6, 'months'));
  const [momentToDate, setMomentToDate] = useState<Moment>(moment());
  const [fromDate, setFromDate] = useState<string>(moment().subtract(6, 'months').format('YYYY/MM/DD'));
  const [toDate, setToDate] = useState<string>(moment().format('YYYY/MM/DD'));
  const [currentScreen, setCurrentScreen] = useState(0);
  const [regularPayment, setRegularPayment] = useState({});

  //rights
  let OL_SpotAccess = useOnlineRights(OnlineRightsNames.OL_SpotAccess);
  let OL_MyAccountInformation_BalancesHistory = useOnlineRights(OnlineRightsNames.OL_MyAccountInformation_BalancesHistory);
  let OL_OrderAccess = useOnlineRights(OnlineRightsNames.OL_OrderAccess);

  useEffect(() => {
    let userSession = JSON.parse(localStorage.getItem('UserSession')!);
    
    getListOfFundsOnHold(userSession.CustomerID,'').then((response) => {
        if (typeof response !== undefined && response.httpStatusCode === 200 && response.data !== undefined) 
        {
          setFundsOnHoldList(response.data);
        }
        else {
          setFundsOnHoldList([]);
        }
    });
    /*
      response:
        data: Array(1)
        0:
          Amount: 200
          CurrencyID: "AUD"
          PendingWithdrawalAmount: 0
    */
  }, [updating]);

  const historyFunds = (element: any) => {
    let userSession = JSON.parse(localStorage.getItem('UserSession')!);
    let obj = {
      CustomerID: userSession.CustomerID,
      CurrencyID: element != null ? element.CurrencyID : currencyID,
      FromDate: fromDate,
      ToDate: toDate,
    }
    setCurrencyID(obj.CurrencyID)
    setCurrentBalance(element != null ? element.Amount : currentBalance);
    getHistoryAuditFundsOnHoldBalances(obj).then((response) => {
      if (response != null && response.httpStatusCode === 200 && response.historyFunds != null) {
        //let begBalance = response.historyFunds[response.historyFunds.length - 1]['Balance'] + Math.abs(response.historyFunds[response.historyFunds.length - 1]['TransactionAmount']);
        let begBalance = response.historyFunds[0]['Balance'] - response.historyFunds[0]['TransactionAmount'];
        setBeginningBalance(begBalance.toString());
        setHistoryFundsOnHoldList(response.historyFunds);
        setHistory(true);
      }
      else {
        setHistoryFundsOnHoldList([]);
        setBeginningBalance('0')
      }
    });
  }
  const onUseNowATemplate = (obj: any) => {
    setRegularPayment({
      BuyCurrencyID: obj.CurrencyID,
      SellCurrencyID: obj.CurrencyID,
      SettlementMethod: {
        value: obj.CurrencyID + ";0;Client's Vostro Account;0",
        name: obj.CurrencyID + " - Clients's Vostro Account - " + obj.CurrencyID,
        details: [
          { label: "Currency", description: "" + obj.CurrencyID },
          { label: "Balance", description: obj.Amount },
          { label: "Instrument", description: "Client's Vostro Account" },
          { label: "Delivery Method", description: 'ACH' }
        ],
        label: obj.CurrencyID + " - Clients's Vostro Account - " + obj.CurrencyID
      }
    })
    setCurrentScreen(1)
  }
  const columns: ColumnsType<ResponseGetListOfFundsOnHold> = [
    {
      title: getResource('Js_TH_Currency'),
      dataIndex : 'CurrencyID',
      key       : 'responsegetlistoffundsonhold_currency',
      align     : 'center',
    },
    {
      title: getResource('Js_TH_Amount'),
      dataIndex : 'Amount',
      key       : 'responsegetlistoffundsonhold_amount',
      align     : 'right',
      render    : (value: any, obj: ResponseGetListOfFundsOnHold, index: any) =>(
        <span>{formatToCurrency(value, obj.CurrencyID)}</span>
      ),
    },
    {
      title: getResource('Js_TH_Action'),
      key    : 'action',
      align  : 'center',
      render : (value: any, obj: ResponseGetListOfFundsOnHold, index: any) => (
        obj.Amount > 0 && <div>
          {OL_SpotAccess && <Tooltip title={getResource('Js_TH_Convert')}>
            <Typography.Link
              onClick={()=>{
                props.convertBalance(obj);
              }}
            >
              <SyncOutlined spin  style={{fontSize: 18}}/>
            </Typography.Link>
          </Tooltip>}
          <Divider type="vertical" />
          {OL_OrderAccess && <Tooltip title={getResource('Js_TH_Pay')}>
            <Typography.Link onClick={() => onUseNowATemplate(value)}>
              <DollarCircleOutlined style={{ fontSize: 18 }} />
            </Typography.Link>
          </Tooltip>}
          <Divider type="vertical" />
          {OL_MyAccountInformation_BalancesHistory && <Tooltip title={getResource('Js_TH_History')}>
            <Typography.Link onClick={() => historyFunds(value)}>
              <HistoryOutlined style={{ fontSize: 18 }} />
            </Typography.Link>
          </Tooltip>}
        </div>
      ),
    },
  ];
  
  const historyColumns: ColumnsType<ResponseGetHistoryOfFundsOnHold> = [
    /*
        "AuditFundsOnHOldBalancesID": 1,
        "User Name": "Willy Oshiro",
        "CurrencyID": "CAD",
        "Source": "Outgoing funds",
        "Destination": "Funds on hold",
        "Amount": 100,
        "TransactionAmount": 100,
        "UpdatedOn": "2021-01-21T18:23:54.793"
         */
    {
      title: getResource('Table_TH_Date'),
      dataIndex: 'UpdatedOn',
      key: 'history_updatedOn',
      align: 'center',
      render: (value: any, obj: ResponseGetHistoryOfFundsOnHold, index: any) => (
        <span>{formatDateString(value)}</span>
      ),
      defaultSortOrder: 'ascend',
      sorter: (a, b) => new Date(a.UpdatedOn).getTime() - new Date(b.UpdatedOn).getTime()
    },
    {
      title: getResource('Table_TH_TransactionAmount'),
      dataIndex: 'TransactionAmount',
      key: 'history_transactionAmount',
      align: 'right',
      render: (value: any, obj: ResponseGetHistoryOfFundsOnHold, index: any) => (
        <span>{formatToCurrency(value, obj.CurrencyID)}</span>
      ),
      sorter: (a, b) => a.TransactionAmount - b.TransactionAmount
    },
    {
      title: getResource('Table_TH_Balance'),
      dataIndex: 'Balance',
      key: 'history_balance',
      align: 'right',
      render: (value: any, obj: ResponseGetHistoryOfFundsOnHold, index: any) => (
        <span>{formatToCurrency(value, obj.CurrencyID)}</span>
      ),
      sorter: (a, b) => a.Balance - b.Balance
    },
    {
      title: getResource('Table_TH_Source'),
      dataIndex: 'Source',
      key: 'history_source',
      align: 'left',
      sorter: (a, b) => a.Source.localeCompare(b.Source)
    },
    {
      title: getResource('Table_TH_Destination'),
      dataIndex: 'Destination',
      key: 'history_destination',
      align: 'left',
      sorter: (a, b) => a.Destination.localeCompare(b.Destination)
    },
    {
      title: getResource('Table_TH_TransactionID'),
      dataIndex: 'AuditFundsOnHOldBalancesID',
      key: 'history_auditFundsOnHOldBalancesID',
      align: 'left',
      render: (value: any, obj: ResponseGetHistoryOfFundsOnHold, index: any) => (
        <span>{value}</span>
      ),
      sorter: (a, b) => a.AuditFundsOnHOldBalancesID - b.AuditFundsOnHOldBalancesID
    },
    {
      title: getResource('Table_TH_UserName'),
      dataIndex: 'User Name',
      key: 'history_userName',
      sorter: (a, b) => a['User Name'].localeCompare(b['User Name']),
      align: 'left',
    },
  ];

  //---------Filter History
  const onChangeFromDate = (date: Moment | null, dateString: string) => {
    setFromDate(dateString);
    setMomentFromDate(date as Moment);
  };
  const onChangeToDate = (date: Moment | null, dateString: string) => {
    setToDate(dateString);
    setMomentToDate(date as Moment);
  };
  const onResetFilters = () => {
    setFromDate(moment().subtract(6, 'months').format('YYYY/MM/DD'));
    setMomentFromDate(moment().subtract(6, 'months'));
    setToDate(moment().format('YYYY/MM/DD'));
    setMomentToDate(moment());
    setHistory(true);
  };
  //-----------------------

  return (
    <React.Fragment>
      <div className="breadcrumb-container">
        <Breadcrumb separator="">
          <Breadcrumb.Item onClick={() => setCurrentScreen(0)}>{getResource('Tab_MyBalances')}</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      {isHistory &&
        <Modal
        title={<h3 className="component-title">{currencyID + ' ' + getResource('Label.HistoryBalance')}</h3>}
          centered
          visible={isHistory}
          footer={null}
          onCancel={() => setHistory(false)}
          maskClosable={false}
        width={'70%'}
          zIndex={1021}
        >
        <Collapse defaultActiveKey={['1']} style={{ marginBottom: '15px' }}>
          <Panel header={'Filter Options'} key="1">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-4 col-xs-12">
                  <div className="row">
                    <span className="input-label">{getResource('DateFrom')}:</span>
                  </div>
                  <div className="row">
                    <DatePicker
                      id="ratewatch-datepicker-fromdate"
                      key="ratewatch-datepicker-fromdate"
                      onChange={onChangeFromDate}
                      value={momentFromDate}
                      submit={true}
                      errorMessage={getResource('DataMsgRequired')}
                      valueValidation={fromDate}
                    />
                  </div>
                </div>
                <div className="col-md-4 col-xs-12">
                  <div className="row">
                    <span className="input-label">{getResource('DateTo')}:</span>
                  </div>
                  <div className="row">
                    <DatePicker
                      id="ratewatch-datepicker-todate"
                      key="ratewatch-datepicker-todate"
                      onChange={onChangeToDate}
                      value={momentToDate}
                      submit={true}
                      errorMessage={getResource('DataMsgRequired')}
                      valueValidation={toDate}
                    />
                  </div>
                </div>
                <div className="col-md-2 col-xs-12" style={{ paddingTop: 30 }}>
                  <Button type="secondary" onClick={() => historyFunds(null)}>
                    {getResource('ApplyFilter')}
                  </Button>
                </div>
                <div className="col-md-2 col-xs-12" style={{ paddingTop: 30 }}>
                  <Button type="primary" onClick={onResetFilters}>
                    {getResource('Reset')}
                  </Button>
                </div>
              </div>
            </div>
          </Panel>
        </Collapse>
        <div style={{ float: 'right', marginRight: '50px' }}>
          <label className='bold-text-big' style={{ marginRight: '50px' }}>{'Initial Balance ' + formatToCurrency(beginningBalance, currencyID) + currencyID}</label>
          <label className='bold-text-big'>{'Current Balance ' + formatToCurrency(currentBalance, currencyID) + currencyID}</label>
        </div>
        <Table
          className='col-md-12'
          key={'historyFundsOnHoldList-table'}
          columns={historyColumns}
          dataSource={historyFundsOnHoldList}
          exportableProps={{
            fileName: 'HistoryBalance',
            btnProps: {
              type: "default",
              icon: <DownloadOutlined />,
            }
          }}
          />
        </Modal>
      }
      {currentScreen === 0 &&
      <div className="row center-xs">
      <Table
        className='col-md-12 slide-r-l'
        key        = {'fundsOnHoldList-table'}
        columns    = {columns} 
        dataSource = {fundsOnHoldList} 
          exportableProps={{
            fileName: 'MyBalances',
            btnProps: {
              type: "default",
              icon: <DownloadOutlined />,
            }
          }}
      />
      </div>
      }
      {currentScreen === 1 &&
        <OrdersScreen dealID={0} objOrderScreen={regularPayment} fromBalance={true} />
      }
    </React.Fragment>
  );
};

export default MyBalances;