import { useEffect, useState } from 'react';
import { getServerVersion } from '../Networking';

const useServerVersion = () => {
  const [serverVersion, setServerVersion] = useState('');
  const [needsReloading, setNeedsReloading] = useState(true);

  useEffect(() => {
    const loadVersion = async () => {
      const json = await getServerVersion();
      let version = json.version;
      if (version) {
        setServerVersion(version);
      } else {
        setServerVersion('0.0.0');
      }
      setNeedsReloading(false);
    };
    if (needsReloading) {
      loadVersion();
    }
  }, [needsReloading]);

  return [serverVersion, setNeedsReloading];
};

export default useServerVersion;
