import { genericGetWithParameters } from './Networking';

export async function getUserDefinedPropertiesBySubEntityType(subEntityType: string, page: number, items: number) {
  try {
    let params = {
      subEntityType: subEntityType,
      page: page,
      items: items,
    }

    let response = await genericGetWithParameters('UserDefinedProperties/Get', params);
    return response;
  } catch (error) {
    console.error(error);
  }
}