import { genericGetWithParameters } from './Networking';

export async function calculateWireItemFee(customerID: number, settlementCurrency: string, foreignCurrency: string, amount: number, customerRate: number, instrument: string, deliveryMethod: string) {
  try {
    let params = {
      customerID: customerID,
      settlementCurrency: settlementCurrency,
      foreignCurrency: foreignCurrency,
      amount: amount,
      customerRate: customerRate,
      instrument: instrument,
      deliveryMethod: deliveryMethod,
    }

    let response = await genericGetWithParameters('Fees/CalculateWireItemFee', params);
    return response;
  } catch (error) {
    console.error(error);
  }
}