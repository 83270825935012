import { Breadcrumb, Checkbox, Input, Modal } from 'antd';
import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import useOnlineRights from '../../../api/hooks/useOnlineRights';
import { approveDeal, getDrawdownsByForwardID, getFullDealHeader, getPendingDeal, rejectDeal } from '../../../api/NetworkingDeals';
import AppContext from '../../../App.context';
import { formatToCurrency } from '../../../helpers/FormatHelper';
import { OnlineRightsNames } from '../../../helpers/OnlineRightsHelper';
import { getResource } from '../../../helpers/ResourcesHelper';
import Button from '../../inputs/Button';
import QuoteBox from '../../rates/quoteBox/QuoteBox';

interface PendignDealDetailProps {
  dealHeaderID: number;
  onExit: Function;
}

const PendignDealDetail: FunctionComponent<PendignDealDetailProps> = (props) => {
  const { showModal, showLoadingModal, hideLoadingModal } = useContext(AppContext);
  const [deal, setDeal] = useState({} as any);
  const [buyAmount, setBuyAmount] = useState('');
  const [sellAmount, setSellAmount] = useState('');
  const [anotherDeal, setAnotherDeal] = useState({} as any);
  const [dealIncomingFunds, setDealIncomingFunds] = useState([] as any);
  const [dealDrawdowns, setDealDrawdowns] = useState<any[]>([]);
  const [rateQuoteID, setRateQuoteID] = useState('');
  const [externalInterface, setExternalInterface] = useState('');
  const [externalInterfaceType, setExternalInterfaceType] = useState('');
  const [customerRate, setCustomerRate] = useState(0);
  const [coverCustomerID, setCoverCustomerID] = useState(0);
  //confirmation
  const [confirmationTitle, setConfirmationTitle] = useState('Approve');
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [isApprove, setIsApprove] = useState(true);
  const [rejectReason, setRejectReason] = useState('');

  //rights
  const OL_SpotApprove = useOnlineRights(OnlineRightsNames.OL_SpotApprove);
  const OL_ForwardApprove = useOnlineRights(OnlineRightsNames.OL_ForwardApprove);
  const OL_DrawdownApprove = useOnlineRights(OnlineRightsNames.OL_DrawdownApprove);

  useEffect(() => {
    if (props.dealHeaderID !== undefined && props.dealHeaderID > 0) {
      getPendingDeal(props.dealHeaderID).then((dealResponse: any) => {
        console.log(dealResponse);
        if (dealResponse !== undefined) {
          setDeal(dealResponse);
          setBuyAmount(dealResponse.BuyAmount);
          setSellAmount(dealResponse.SellAmount);
          if (dealResponse.DealType === 4) {
            getDrawdownsByForwardID(props.dealHeaderID).then((drawdownsResponse: any) => {
              console.log(drawdownsResponse);
              if (drawdownsResponse.totalCount > 0) {
                setDealDrawdowns(drawdownsResponse.deals);
              }
            });
          }
        }
      });
      getFullDealHeader(props.dealHeaderID).then((dealResponse: any) => {
        setAnotherDeal(dealResponse);
      });
    }
  }, [props.dealHeaderID]);

  let outgoingFunds: any[] = [];

  if (deal.DealType === 'Spot') {
    if (deal.BeneficiaryPayments) {
      outgoingFunds = JSON.parse(deal.BeneficiaryPayments).map((outgoingFund: any) => {
        return (
          <React.Fragment>
            <div className="row slide-r-l">
              <div className="col-xs-2">{outgoingFund.BeneficiaryName}</div>
              <div className="col-xs-1">{outgoingFund.CountryName}</div>
              <div className="col-xs-2">
                {formatToCurrency(outgoingFund.Amount, outgoingFund.CurrencyID)} {outgoingFund.CurrencyID}
              </div>
              <div className="col-xs-2">{outgoingFund.Instrument}</div>
              <div className="col-xs-4">{outgoingFund.BankName} - {outgoingFund.AccountNo}</div>
              <div className="col-xs-1">{outgoingFund.Status}</div>
            </div>
          </React.Fragment>
        );
      });
    } else {
      if (anotherDeal.OutgoingFunds) {
        outgoingFunds = anotherDeal.OutgoingFunds.map((outgoingFund: any) => {
          return (
            <React.Fragment>
              <div className="row slide-r-l">
                <div className="col-xs-2">{outgoingFund.Name}</div>
                <div className="col-xs-1">{outgoingFund.CountryName}</div>
                <div className="col-xs-2">
                  {formatToCurrency(outgoingFund.PaymentAmount, anotherDeal.SellCurrency)} {anotherDeal.SellCurrency}
                </div>
                <div className="col-xs-2">{outgoingFund.PaymentInstrument}</div>
                <div className="col-xs-4"></div>
                <div className="col-xs-1">{outgoingFund.Status}</div>
              </div>
            </React.Fragment>
          );
        });
      }
    }
  } else if (deal.DealType === 'Forward') {
    if (dealDrawdowns.length > 0) {
      outgoingFunds = dealDrawdowns.map((outgoingFund: any) => {
        return (
          <React.Fragment>
            <div className="row slide-r-l middle-xs">
              <div className="col-xs-1°5">{outgoingFund['Deal Header ID']}</div>
              <div className="col-xs-2">
                {formatToCurrency(outgoingFund['Buy Amount'], outgoingFund['Buy Currency'])} {outgoingFund['Buy Currency']}
              </div>
              <div className="col-xs-2">
                {formatToCurrency(outgoingFund['Sell Amount'], outgoingFund['Sell Currency'])} {outgoingFund['Sell Currency']}
              </div>
              <div className="col-xs-2">{outgoingFund['Value Date']}</div>
              <div className="col-xs-1°5">{outgoingFund['Status']}</div>
            </div>
          </React.Fragment>
        );
      });
    } else if (anotherDeal.OutgoingFunds) {
      outgoingFunds = anotherDeal.OutgoingFunds.map((outgoingFund: any) => {
        return (
          <React.Fragment>
            <div className="row slide-r-l">
              <div className="col-xs-3">{outgoingFund.Name}</div>
              <div className="col-xs-2">{outgoingFund.CountryName}</div>
              <div className="col-xs-2">
                {formatToCurrency(outgoingFund.PaymentAmount, anotherDeal.SellCurrency)} {anotherDeal.SellCurrency}
              </div>
              <div className="col-xs-2">{outgoingFund.PaymentInstrument}</div>
              <div className="col-xs-1">{outgoingFund.Status}</div>
            </div>
          </React.Fragment>
        );
      });
    }
  }

  let incomingFunds: any[] = [];
  if (anotherDeal.IncomingFunds) {
    incomingFunds = anotherDeal.IncomingFunds.map((incomingFund: any) => {
      return (
        <React.Fragment>
          <div className="row slide-r-l">
            <div className="col-xs-3">
              {formatToCurrency(incomingFund.Amount, anotherDeal.BuyCurrency)} {anotherDeal.BuyCurrency}
            </div>
            <div className="col-xs-2">{incomingFund.SettlementDeliveryMethod}</div>
            <div className="col-xs-4">{incomingFund.SettlementInstrument}</div>
            <div className="col-xs-3">{incomingFund.Status}</div>
          </div>
        </React.Fragment>
      );
    });
  }

  const onAcceptClick = (rateQuoteID: string, customerRate: number, marketRate: number, profit: number, coverCustomerID: number, externalInterface: string, externalInterfaceType: string) => {
    setConfirmationMessage(getResource('Js_Message_ConfirmApproveDeal'));
    setRateQuoteID(rateQuoteID);
    setCustomerRate(customerRate);
    setCoverCustomerID(coverCustomerID);
    setExternalInterface(externalInterface);
    setExternalInterfaceType(externalInterfaceType);
    setIsApprove(true);
    setShowConfirmationModal(true);
  };

  const onCancelQuoteClick = () => {
    setSellAmount(deal.SellAmount);
    setBuyAmount(deal.BuyAmount);
  };

  const validateFields = () => {
    return true;
  };

  const amountCalc = (amount: number) => {
    console.log(amount);
    if (deal.LockedSide === 'Buy') {
      setSellAmount(amount.toFixed(2));
    } else {
      setBuyAmount(amount.toFixed(2));
    }
  };

  const onRejectClick = () => {
    setIsApprove(false);
    setRejectReason('');
    setConfirmationMessage(getResource('Js_Message_ConfirmRejectDeal').replace('#dealHeaderID', deal.DealHeaderID));
    setShowConfirmationModal(true);
  }

  const acceptClick = () => {
    setShowConfirmationModal(false);

    let userSession = JSON.parse(localStorage.getItem('UserSession')!);

    if (isApprove) {
      showLoadingModal();
    let dealToApprove = {
      dealToApprove: {
        BuyAmount: deal.SellAmount,
        BuyCurrency: deal.SellCurrency,
        CoverCustomerID: coverCustomerID,
        ContactID: localStorage.getItem('ContactID'),
        CustomerID: userSession.CustomerID,
        CustomerRate: customerRate,
        DealHeaderID: deal.DealHeaderID,
        DealType: deal.DealType,
        ExternalInterface: externalInterface,
        ExternalInterfaceType: externalInterfaceType,
        LockedSide: deal.LockedSide,
        NearDate: deal.NearDate,
        RateQuoteID: rateQuoteID,
        SellAmount: deal.BuyAmount,
        SellCurrency: deal.BuyCurrency,
        ValueDate: deal.ValueDate,
      },
      rateQuoteID: rateQuoteID,
      userID: localStorage.getItem('UserID'),
      contactID: localStorage.getItem('ContactID'),
      InstallationID: userSession.InstallationID
    }

    approveDeal(dealToApprove).then(
      (json) => {
        if (json !== undefined) {
          var alertTitle = '', alertMessage = '';
          hideLoadingModal();
          if (json.saveApprovalMessage !== undefined
            && json.saveApprovalMessage !== '') {
            alertTitle = getResource('Js_MessageError_TitleSuccess');
            alertMessage = json.saveApprovalMessage;
          }
          else {
            switch (Number(json.httpStatusCode)) {
              case 200:
                alertTitle = getResource('Js_MessageError_TitleSuccess');
                alertMessage = getResource('Js_Message_AprovedDeal');
                break;
              default:
                alertTitle = getResource('Js_MessageError_TitleError');
                alertMessage = getResource('Js_Message_ApproveDealError');
                break;
            }
          }
          showModal(alertTitle, alertMessage);
          props.onExit();
        }
      }
    );
    }
    else {
      //reject
      setShowRejectModal(true);
    }

  }

  const onChangeRejectReason = (e: any) => {
    setRejectReason(e.target.value);
  }

  const acceptRejectClick = () => {
    showLoadingModal();
    setShowRejectModal(false);

    let userSession = JSON.parse(localStorage.getItem('UserSession')!);

    let objRejectDeal = {
      dealHeaderID: deal.DealHeaderID,
      customerID: userSession.CustomerID,
      contactID: localStorage.getItem('ContactID'),
      entityType: 'Deal',
      userID: localStorage.getItem('UserID'),
      rejectReason: rejectReason
    };
    rejectDeal(objRejectDeal).then(
      (json) => {
        if (json !== undefined) {
          hideLoadingModal();
          var alertTitle = '', alertMessage = '';

          if (json.errorMessage === '') {
            alertTitle = getResource('Js_MessageError_TitleSuccess');
            alertMessage = getResource('Js_Message_RejectedDealMessage');
          }
          else {
            alertTitle = getResource('Js_MessageError_TitleError');
            alertMessage = json.errorMessage;
          }
          showModal(alertTitle, alertMessage);
          props.onExit();
        }
      }
    )
  }

  return (
    <React.Fragment>
      {' '}
      {deal.DealHeaderID && (
        <div className="screen-container">
          <div className="row slide-r-l start-xs middle-xs">
            {deal.BeneficiaryPayments == null && <div className="row bold-text-big label-error">
              {getResource('Label.DealCannotApprove')}
            </div>}
            <div className="col-xs-4 subtitle-text-primary">{getResource('Title.DealInformation')}</div>
            <div className="col-xs-8 row end-xs middle-xs" style={{ margin: 0, padding: 0 }}>
              {((deal.BeneficiaryPayments != null &&
                ((deal.DealType === 'Spot' && OL_SpotApprove) || (deal.DealType === 'Forward' && OL_ForwardApprove)
                )) || (deal.DealType === 'DrawDown' && OL_DrawdownApprove))
                && <div className="col-md-4 col-xs-12">
                <QuoteBox
                  mode='modal'
                  settlementMethod={''}
                  dealType={deal.DealType === 'Forward' ? 4 : 1}
                  buyCurrency={deal.SellCurrency}
                  sellCurrency={deal.BuyCurrency}
                  customerID={Number.parseInt(JSON.parse(localStorage.getItem('UserSession')!).CustomerID)}
                  contactID={Number.parseInt(JSON.parse(localStorage.getItem('UserSession')!).ContactID)}
                  valueDate={deal.ValueDate}
                  forwardNearDate={deal.NearDate}
                  amount={deal.LockedSide === 'Buy' ? deal.BuyAmount : deal.SellAmount}
                  lockedSide={deal.LockedSide === 'Buy' ? 'buy' : 'sell'}
                  totalFeesAmount={anotherDeal.IncomingFunds ? formatToCurrency((anotherDeal.IncomingFunds[0].Amount - deal['Buy Amount']).toString(), deal.BuyCurrency) : 0}
                  amountCalc={amountCalc}
                  onNewTradeClick={onAcceptClick}
                  onAcceptClick={onAcceptClick}
                  onCancelQuote={onCancelQuoteClick}
                  validateFields={validateFields}
                    type='approval'
                />
              </div>}
              {((deal.DealType === 'Spot' && OL_SpotApprove) || (deal.DealType === 'Forward' && OL_ForwardApprove)
                || (deal.DealType === 'DrawDown' && OL_DrawdownApprove))
                && <div className="col-md-5 col-xs-12">
                <Button type="secondary" onClick={onRejectClick}>
                  Reject
                </Button>
                </div>}
            </div>
          </div>
          <div className="row slide-r-l start-xs">
            <div className="col-xs-3 bold-text">{getResource('Table_TH_DealNumber')}</div>
            <div className="col-xs-2 bold-text-big">{deal.DealHeaderID}</div>
          </div>
          <div className="row slide-r-l start-xs">
            <div className="col-xs-3 bold-text">{getResource('Table_TH_DealType')}</div>
            <div className="col-xs-2">{deal.DealType}</div>
          </div>
          <div className="row slide-r-l start-xs">
            <div className="col-xs-3 bold-text">{getResource('Table_TH_BuyAmount')}</div>
            <div className="col-xs-3">
              {formatToCurrency(sellAmount, deal.SellCurrency)} {deal.SellCurrency + ' '}
              {deal.LockedSide === 'Sell' && <Checkbox checked={true} className="bold-text" disabled={true}>{getResource('Locked Side')} </Checkbox>}
            </div>
          </div>
          <div className="row slide-r-l start-xs">
            <div className="col-xs-3 bold-text">{getResource('Table_TH_SellAmount')}</div>
            <div className="col-xs-3">
              {formatToCurrency(buyAmount, deal.BuyCurrency)} {deal.BuyCurrency + ' '}
              {deal.LockedSide === 'Buy' && <Checkbox checked={true} className="bold-text" disabled={true}>{getResource('Locked Side')} </Checkbox>}

            </div>
          </div>
          <div className="row slide-r-l start-xs">
            <div className="col-xs-3 bold-text">{getResource('Table_TH_FeeAmount')}</div>
            <div className="col-xs-2">
              {anotherDeal.DealFees != null ? anotherDeal.DealFees.reduce((accumulator: number, currentValue: any) => accumulator + currentValue.FeeAmount, 0) : '0.00'} {deal.BuyCurrency}
            </div>
          </div>
          <div className="row slide-r-l start-xs">
            <div className="col-xs-3 bold-text">{getResource('Table_TH_Rate')}</div>
            <div className="col-xs-2">{deal.CustomerRate}</div>
          </div>
          {deal.DealType === 'Spot' && (
            <div className="row slide-r-l start-xs">
              <div className="col-xs-3 bold-text">{getResource('Table_TH_ValueDate')}</div>
              <div className="col-xs-2">{deal.ValueDate}</div>
            </div>
          )}
          {deal.DealType === 'Forward' && (
            <div className="row slide-r-l start-xs">
              <div className="col-xs-3 bold-text">{getResource('Table_TH_NearDate')}</div>
              <div className="col-xs-2">{deal.NearDate}</div>
            </div>
          )}
          {deal.DealType === 'Forward' && (
            <div className="row slide-r-l start-xs">
              <div className="col-xs-3 bold-text">{getResource('Label.FarDate')}</div>
              <div className="col-xs-2">{deal.ValueDate}</div>
            </div>
          )}
          {deal.DealType === 'Forward' && (
            <div className="row slide-r-l start-xs">
              <div className="col-xs-3 bold-text">{getResource('Form.Label.ForwardType')}</div>
              <div className="col-xs-2">{deal.ForwardType}</div>
            </div>
          )}
          <div className="row slide-r-l start-xs">
            <div className="col-xs-3 bold-text">{getResource('Label.CreatedBy')}</div>
            <div className="col-xs-2">{deal.Name}</div>
          </div>
          <div className="row slide-r-l start-xs">
            <div className="col-xs-3 bold-text">{getResource('Label.CreatedOn')}</div>
            <div className="col-xs-2">{deal.CreatedOn}</div>
          </div>
          {/*<Divider />*/}
          {incomingFunds !== undefined && incomingFunds.length > 0 && <div className="row slide-r-l start-xs">
            <div className="col-xs-4 subtitle-text-primary">{getResource('Title.SettlementInformation')}</div>
          </div>}
          {incomingFunds !== undefined && incomingFunds.length > 0 && <div className="row slide-r-l">
            <div className="col-xs-3 bold-text">{getResource('Amount')}</div>
            <div className="col-xs-2 bold-text">{getResource('Label.DeliveryMethod')}</div>
            <div className="col-xs-4 bold-text">{getResource('Label.Instrument')}</div>
            <div className="col-xs-3 bold-text">{getResource('Js_TH_Status')}</div>
          </div>}
          {incomingFunds}
          {/*<Divider />*/}
          {outgoingFunds.length > 0 && (
            <>
              <div className="row slide-r-l start-xs">
                {(deal.DealType === 'Spot' || (deal.DealType === 'Forward' && (anotherDeal.OutgoingFunds !== undefined && dealDrawdowns.length === 0))) && <div className="col-xs-4 subtitle-text-primary">{getResource('DisbursementsInformation')}</div>}
                {deal.DealType === 'Forward' && dealDrawdowns.length > 0 && <div className="col-xs-4 subtitle-text-primary">{getResource('DrawdownsInformation')}</div>}
              </div>
              {(deal.DealType === 'Spot' || (deal.DealType === 'Forward' && anotherDeal.OutgoingFunds)) && (
                <div className="row slide-r-l">
                  <div className="col-xs-2 bold-text">{getResource('Js_TH_Beneficiary')}</div>
                  <div className="col-xs-1 bold-text">{getResource('Js_TH_Country')}</div>
                  <div className="col-xs-2 bold-text">{getResource('SendAmount')}</div>
                  <div className="col-xs-2 bold-text">{getResource('Table_TH_Instrument')}</div>
                  <div className="col-xs-4 bold-text">{getResource('BankAccountInformation')}</div>
                  <div className="col-xs-1 bold-text">{getResource('Js_TH_Status')}</div>
                </div>
              )}
              {deal.DealType === 'Forward' && dealDrawdowns.length > 0 && (
                <div className="row slide-r-l ">
                  <div className="col-xs-1°5 bold-text">{getResource('Table_TH_DealNumber')}</div>

                  <div className="col-xs-2 bold-text">{getResource('Table_TH_SellAmount')}</div>
                  <div className="col-xs-2 bold-text">{getResource('Table_TH_BuyAmount')}</div>
                  <div className="col-xs-2 bold-text">{getResource('Table_TH_ValueDate')}</div>
                  <div className="col-xs-1°5 bold-text">{getResource('Js_TH_Status')}</div>
                </div>
              )}
              {outgoingFunds}
            </>
          )}
          <Modal
            title={
              <div className="breadcrumb-container">
                <Breadcrumb separator="">
                  <Breadcrumb.Item >{confirmationTitle}</Breadcrumb.Item>
                </Breadcrumb>
              </div>
            }
            centered
            visible={showConfirmationModal}
            footer={null}
            onCancel={() => setShowConfirmationModal(false)}
            maskClosable={false}
            width={'25%'}
            zIndex={1021}
            closable={false}
          >
            <div>
              <div>
                <h4>{confirmationMessage}</h4>
              </div>
              <div className="row">
                <div className="col-xs-12 col-md-6">
                  <div className="col">
                    <Button id="confirmation-afex-OK" type="primary" onClick={() => { acceptClick() }}>
                      {getResource('Accept')}
                    </Button>
                  </div>
                </div>
                <div className="col-xs-12 col-md-6">
                  <div className="col">
                    <Button id="confirmation-afex-cancel" type="primary" onClick={() => setShowConfirmationModal(false)}>
                      {getResource('Button.Cancel')}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
          <Modal
            title={
              <div className="breadcrumb-container">
                <Breadcrumb separator="">
                  <Breadcrumb.Item >{confirmationTitle}</Breadcrumb.Item>
                </Breadcrumb>
              </div>
            }
            centered
            visible={showRejectModal}
            footer={null}
            onCancel={() => setShowConfirmationModal(false)}
            maskClosable={false}
            width={'25%'}
            zIndex={1021}
            closable={false}
          >
            <div>
              <div className="row">
                <span className="fnt-size-08">{getResource('Label.RejectReason')}</span>
              </div>
              <div className="row">
                <Input type="text" onChange={onChangeRejectReason} value={rejectReason}></Input>
              </div>
              <div className="row">
                <div className="col-xs-12 col-md-6">
                  <div className="col">
                    <Button id="confirmation-afex-OK" type="primary" onClick={() => { acceptRejectClick() }}>
                      {getResource('Accept')}
                    </Button>
                  </div>
                </div>
                <div className="col-xs-12 col-md-6">
                  <div className="col">
                    <Button id="confirmation-afex-cancel" type="primary" onClick={() => setShowRejectModal(false)}>
                      {getResource('Button.Cancel')}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      )}
    </React.Fragment>
  );
};

export default PendignDealDetail;